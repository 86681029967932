import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import {
    arrayOfNotNull,
    boolOrDefault,
    isString,
    notEmpty,
    nullNumOrDefault,
    numOrDefault,
    safeParseAndRoundFloat,
    safeParseFloat,
    safeParseInt,
    stringOrDefault
} from "@lib/TypeUtil"
import { Contact } from "@model/contacts/Contact"
import {
    booleanFilterValue,
    Filter,
    isEmptyFilterValue,
    numberArrayFilterValue,
    numberFilterValue,
    textFilterValue
} from "@model/filters/Filter"
import { HistoryItem } from "@model/HistoryItem"
import { PagedResponse, Pagination } from "@model/Pagination"
import { ServiceRecordForm, ServiceRequestForm } from "@model/serviceRequests/ServiceForm"
import { ServiceSchedule } from "@model/ServiceSchedule"
import { Tag, TagType } from "@model/Tag"
import {
    completableTaskValueForValue,
    inspectionTaskValueForValue,
    Task,
    taskValueFromApi,
    taskValueToApi
} from "@model/Task"
import { ThresholdType, thresholdUnitForValue } from "@model/Threshold"
import { ordinalForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import {
    UserWorkRequestsTableColumn,
    UserWorkRequestsTableRow,
    WorkRequest,
    WorkRequestFilter,
    WorkRequestQuickFilterCount,
    WorkRequestsTableRow,
    WorkRequestStatus,
    WorkRequestTableColumn,
    WorkRequestTableRowPriority,
    WorkRequestType,
    workRequestTypeForValue
} from "@model/workRequests/WorkRequest"
import {
    ApiService,
    HttpMethod,
    notifiedContactsFromApi,
    notifiedContactsToApi,
    nullableDateFromApi,
    paginationFromApi,
    paginationToApi,
    sortToApi,
    tagFromApi,
    tagTypeToApi,
    thresholdUnitToApi
} from "@service/ApiService"
import { CompanyFieldIdMapper } from "@service/company/CompanyService"
import { Attachment, AttachmentType } from "../../model/Attachment"
import { WorkRequestService } from "./WorkRequestService"

type WorkRequestApi = {
    workRequestId: number | null
    assetId: number | null
    workOrderId: number | null
    serviceTypeId: number
    serviceScheduleStepId: number | null
    serviceSubTypeId: number | null
    urgencyId: number
    urgencyName: number | null
    targetDueDate: string | null
    dueHourMeter: number | null
    dueOdometer: number | null
    estimatedLaborHours: number | null
    estimatedLaborCost: number | null
    estimatedPartsCost: number | null
    actualLaborHours: number | null
    actualLaborCost: number | null
    actualPartsCost: number | null
    description: string | null
    specialNotes: string | null
    note: string | null
    serviceCodeId: number | null
    requestedBy: number | null
    billingCodeId: number | null
    status: number
    statusFlagReason: string
    tag: Tag | null
    notifiedContacts: string | null // {"UserId": [1,2,3,4]}
    fileId: string | null
    tasks: string | null
}

type CreateServiceRequestApi = {
    assetId: number
    serviceTypeId: number
    urgencyId: number
    serviceScheduleStepId: number | null
    targetDueDate: string | null
    dueHourMeter: number | null
    dueOdometer: number | null
    serviceSubTypeId: number | null
    estimatedLaborHours: number | null
    estimatedLaborCost: number | null
    estimatedPartsCost: number | null
    actualLaborHours: number | null
    actualLaborCost: number | null
    actualPartsCost: number | null
    description: string | null
    specialNotes: string | null
    serviceCodeId: number | null
    requestedBy: number | null
    note: string | null
    status: number
    tagStatus: string | null
    reasonTagged: string | null
    workOrderStatusId: number
    specialInstructions: string | null
    notifiedContacts: string | null // {"UserId": [1,2,3,4]}
    assignedMechanic: number[] | null
    externalWorkOrder: string | null
    externalVendor: string | null
    externalInvoiceNumber: string | null
    externalNote: string | null
    serviceRecord: boolean
    siteId: number | null
    WorkCompletedDate: Date | null
    CompletedHourMeter: number | null
    CompletedOdometer: number | null
    createManualEntry: boolean
}

export class MainWorkRequestService implements WorkRequestService {
    readonly fileUploadContainerName: string = "workrequest"
    workRequestService: any
    assetService: any
    constructor(private apiService: ApiService, private idMapper: CompanyFieldIdMapper) {}

    async fetchWorkRequestsByAssets(
        filters?: Filter<WorkRequestFilter>[],
        sort?: { column: WorkRequestTableColumn; order: SortOrder },
        pagination?: Pagination
    ): Promise<
        Outcome<PagedResponse<WorkRequestsTableRow[]> & { counts: Record<WorkRequestQuickFilterCount, number> }>
    > {
        const body = {
            ...paginationToApi(pagination),
            ...this.createWorkRequestListFilterBody(filters),
            ...this.sortWorkRequestListItemToApi(sort),
        }
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkRequestList",
            body,
        })
        if (!ApiService.isValidPagedResponse(response)) return Outcome.error(response)

        return Outcome.ok({
            data: response.value.results.map((it) => workRequestTableRowFromApi(it)),
            pagination: paginationFromApi(response.value),
            counts: workRequestCountsFromApi(response.value.summary),
        })
    }

    async fetchWorkRequestsByUser(
        filters?: Filter<WorkRequestFilter>[],
        sort?: { column: UserWorkRequestsTableColumn; order: SortOrder },
        pagination?: Pagination
    ): Promise<Outcome<PagedResponse<UserWorkRequestsTableRow[]>>> {
        const body = {
            ...paginationToApi(pagination),
            ...this.createWorkRequestListFilterBody(filters),
            ...this.sortWorkRequestListItemToApi(sort),
        }
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkRequestMobileViewList",
            body,
        })
        if (!ApiService.isValidPagedResponse(response)) return Outcome.error(response)
        var results = response.value.results.map((it) => userWorkRequestTableRowFromApi(it))

        return Outcome.ok({
            data: results,
            pagination: paginationFromApi(response.value),
        })
    }

    async fetchWorkRequestXls(filters?: Filter<WorkRequestFilter>[]): Promise<Outcome<Blob>> {
        const body = {
            ...this.createWorkRequestListFilterBody(filters),
        }
        const response = await this.apiService.makeDownloadRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkRequestList/AsXLS",
            body,
        })

        if (!ApiService.isValidObjectResponse(response) || response.value == undefined) return Outcome.error(response)

        return Outcome.ok(response.value)
    }

    private sortWorkRequestListItemToApi = (sort?: any): Record<string, any> | void => {
        if (sort)
            return sortToApi({
                column: WorkRequestTableColumnToWorkRequestListItemField(sort.column),
                order: sort.order,
            })
    }

    private createWorkRequestListFilterBody(filters?: Filter<WorkRequestFilter>[]): any {
        const filterBody: Record<string, any> = {}

        filters?.forEach((filter) => {
            if (isEmptyFilterValue(filter)) return

            switch (filter.definition.type) {
                // Asset Assignment
                case WorkRequestFilter.SubCompany:
                    filterBody.companyId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.District:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.SubDistrict:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Unit:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Group:
                    filterBody.groupId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Site:
                    filterBody.siteId = numberArrayFilterValue(filter)
                    break

                // Asset Properties
                case WorkRequestFilter.AssetType:
                    filterBody.typeId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Category:
                    filterBody.categoryId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Class:
                    filterBody.classId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Make:
                    filterBody.assetMakeId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.Model:
                    filterBody.assetModelId = numberArrayFilterValue(filter)
                    break

                // Work Request
                case WorkRequestFilter.WorkType:
                    filterBody.serviceTypeId = filter.values
                        .map((it) => it.value)
                        .flatMap((it) => {
                            if (!isString(it)) return []
                            return this.idMapper.fromWorkRequestType(workRequestTypeForValue(it))
                        })
                    break
                case WorkRequestFilter.Urgency:
                    filterBody.urgencyRanks = filter.values
                        .map((it) => it.value)
                        .flatMap((it) => {
                            if (!isString(it)) return []
                            return ordinalForUrgency(urgencyForValue(it))
                        })
                    break
                case WorkRequestFilter.Schedule:
                    filterBody.serviceScheduleId = numberArrayFilterValue(filter)
                    break
                case WorkRequestFilter.HoursUntil:
                    filterBody.hoursUntil = safeParseInt(textFilterValue(filter))
                    break
                case WorkRequestFilter.HoursUntilUnit:
                    filterBody.hoursUntilUnit = thresholdUnitToApi(
                        ThresholdType.Hours,
                        thresholdUnitForValue(textFilterValue(filter))
                    )
                    break
                case WorkRequestFilter.MilesUntil:
                    filterBody.milesUntil = safeParseInt(textFilterValue(filter))
                    break
                case WorkRequestFilter.MilesUntilUnit:
                    filterBody.milesUntilUnit = thresholdUnitToApi(
                        ThresholdType.Miles,
                        thresholdUnitForValue(textFilterValue(filter))
                    )
                    break
                case WorkRequestFilter.DaysUntil:
                    filterBody.daysUntil = safeParseInt(textFilterValue(filter))
                    break
                case WorkRequestFilter.DaysUntilUnit:
                    filterBody.daysUntilUnit = thresholdUnitToApi(
                        ThresholdType.Days,
                        thresholdUnitForValue(textFilterValue(filter))
                    )
                    break
                case WorkRequestFilter.ServiceCode:
                    filterBody.serviceCodeId = numberArrayFilterValue(filter)
                    break

                // Other
                case WorkRequestFilter.RedTag:
                    filterBody.tagStatus = Array.isArray(filterBody.tagStatus) ? filterBody.tagStatus : []
                    if (booleanFilterValue(filter)) filterBody.tagStatus.push(tagTypeToApi(TagType.Red))
                    break
                case WorkRequestFilter.YellowTag:
                    filterBody.tagStatus = Array.isArray(filterBody.tagStatus) ? filterBody.tagStatus : []
                    if (booleanFilterValue(filter)) filterBody.tagStatus.push(tagTypeToApi(TagType.Yellow))
                    break
                case WorkRequestFilter.Overdue:
                    filterBody.overdue = booleanFilterValue(filter)
                    break
                case WorkRequestFilter.Upcoming:
                    filterBody.upcoming = booleanFilterValue(filter)
                    break
                case WorkRequestFilter.Dismissed:
                    filterBody.dismissed = booleanFilterValue(filter)
                    break
                case WorkRequestFilter.RequestedBy:
                    filterBody.RequestedBy = numberFilterValue(filter)
                    break
                // Search
                case WorkRequestFilter.Search:
                    filterBody.globalSearchQuery = textFilterValue(filter)
                    break
            }
        })
        //this parameter is hardcoded here because
        //even adding this as a default parameter
        //is filtered as an empty value by line 84 (in isEmptyFilterValue) in
        //SortableTableBlocs.ts in the fetchData method
        filterBody.dismissed = false

        return filterBody
    }

    async fetchWorkRequestsForAsset(assetId: number): Promise<Outcome<WorkRequest[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkRequest/GetWorkRequests",
            body: { assetId: [assetId] },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map((it) => workRequestFromApi(it, this.idMapper)))
    }

    async fetchServiceScheduleStepTasks(
        serviceScheduleItemId: number,
        workRequestType: WorkRequestType
    ): Promise<Task[]> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Maintenance/GetServiceScheduleTasks",
            query: { serviceScheduleItemId: serviceScheduleItemId },
        })
        if (!ApiService.isValidArrayResponse(response)) return []

        return tasksFromApi({ tasksView: response.value }, workRequestType)
    }

    async fetchUnassignedWorkRequestsForAsset(assetId: number): Promise<Outcome<WorkRequest[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkRequest/GetWorkRequests",
            body: { assetId: [assetId], workOrderId: [null], Dismissed: false },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map((it) => workRequestFromApi(it, this.idMapper)))
    }

    async fetchNotDismissedWorkRequestsForAsset(assetId: number): Promise<Outcome<WorkRequest[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkRequest/GetWorkRequests",
            body: { assetId: [assetId], Dismissed: false },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map((it) => workRequestFromApi(it, this.idMapper)))
    }

    async createServiceRequest(form: ServiceRequestForm, userId: number): Promise<Outcome<void>> {
        if (form.assetId === null) return Outcome.error("No asset Id provided")

        const body = serviceRequestFormToCreateServiceRequestApi(form, userId, this.idMapper)
        if (body.isError()) return body

        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkRequest/CreateServiceRequest",
            body: body.value,
        })
        if (response.isError()) return response

        return Outcome.ok(undefined)
    }

    async createServiceRecord(form: ServiceRecordForm, userId: number): Promise<Outcome<void>> {
        if (form.assetId === null) return Outcome.error("No asset Id provided")

        const body = serviceRecordFormToCreateWorkRequestApi(form, userId, this.idMapper)
        if (body.isError()) return body

        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkRequest/CreateServiceRequest",
            body: body.value,
        })
        if (response.isError()) return response

        return Outcome.ok(undefined)
    }

    async fetchWorkRequestCounts(
        filters?: Filter<WorkRequestFilter>[]
    ): Promise<Outcome<Record<WorkRequestQuickFilterCount, number>>> {
        const body = { ...this.createWorkRequestListFilterBody(filters) }
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkRequestListSummary",
            body: body,
        })

        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(workRequestCountsFromApi(response.value))
    }

    async updateWorkRequest(workRequest: WorkRequest): Promise<Outcome<WorkRequest>> {
        const body = updateWorkRequestToApi(workRequest, this.idMapper)
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Patch,
            path: `/WorkOrder/WorkRequest/${workRequest.id}`,
            body,
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(workRequestFromApi(response.value, this.idMapper))
    }

    async fetchWorkRequest(id: number): Promise<Outcome<WorkRequest>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/WorkRequest",
            query: { Id: id },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        if (response.value.length !== 1) return Outcome.error("Service Request Not Found")
        const workRequest = workRequestFromApi(response.value[0], this.idMapper)

        if (notEmpty(workRequest.filesId)) {
            const atachmentsResponse = await this.fetchAttachments(workRequest.filesId)
            if (atachmentsResponse.isError()) return Outcome.error(atachmentsResponse)
            workRequest.attachments = atachmentsResponse.value
        }

        // When WR is not added to a WO, it should get the tasks from the service schedule step
        if (!workRequest.workOrderId && workRequest.step) {
            workRequest.tasks = await this.fetchServiceScheduleStepTasks(
                workRequest.step.id,
                workRequest.workRequestType
            )
        }

        return Outcome.ok(workRequest)
    }
    async fetchWorkRequestByAssetAndSchedule(assetId: number, scheduleIds: number[]): Promise<Outcome<WorkRequest[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/WorkRequest",
            query: { assetId: [assetId], serviceScheduleId: scheduleIds },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        if (response.value.length === 0) return Outcome.error("Service Request Not Found")
        const workRequests = response.value.map((it) => workRequestFromApi(it, this.idMapper))

        return Outcome.ok(workRequests)
    }
    async fetchWorkRequestHistory(id: number): Promise<Outcome<HistoryItem<WorkRequestStatus>[]>> {
        const historyResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/WorkRequestHistoryView",
            query: { WorkRequestId: [id] },
        })

        if (!ApiService.isValidArrayResponse(historyResponse)) return Outcome.error(historyResponse)

        return Outcome.ok(historyListFromApi(historyResponse.value, this.idMapper))
    }

    async fetchWorkRequestsHistory(ids: number[]): Promise<Outcome<HistoryItem<WorkRequestStatus>[]>> {
        const historyResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/WorkRequestHistoryView",
            query: { WorkRequestId: ids },
        })

        if (!ApiService.isValidArrayResponse(historyResponse)) return Outcome.error(historyResponse)

        return Outcome.ok(historyListFromApi(historyResponse.value, this.idMapper))
    }

    async fetchWorkRequestsForWorkOrder(workOrderId: number): Promise<Outcome<WorkRequest[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkRequest/GetWorkRequests",
            body: { WorkOrderId: [workOrderId], Dismissed: false },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        var workRequests = response.value.map((it) => workRequestFromApi(it, this.idMapper))

        return Outcome.ok(workRequests)
    }

    async dismissWorkRequest(id: number, reason: string, userId: number, tag?: Tag | null): Promise<Outcome<void>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Put,
            path: `/WorkOrder/WorkRequest/Dismiss/${id}`,
            body: { note: reason, tag: tag, userId: userId },
        })

        if (response.isError()) return response
        return Outcome.ok(undefined)
    }

    async advanceWorkRequestToNextScheduleStep(ids: number[]): Promise<Outcome<void>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: `/WorkOrder/WorkRequest/AdvanceWorkRequestsToNextScheduleStep`,
            body: { WorkRequestIds: ids },
        })

        if (response.isError()) return response
        return Outcome.ok(undefined)
    }

    async uploadAttachments(
        id: string,
        files: File[],
        userId: number,
        companyId: number
    ): Promise<Outcome<Attachment[]>> {
        if (files === null || files.length == 0) return Outcome.error("No files provided")

        const formData = new FormData()
        formData.append("id", id)
        formData.append("containerName", this.fileUploadContainerName)
        formData.append("userId", userId.toString())
        formData.append("companyId", companyId.toString())
        for (let file of files) {
            formData.append("Files[]", file, file.name)
        }
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/FileManagement/UploadFile",
            body: formData,
        })

        if (response.isError()) return response
        const result: Attachment[] = attachmentsFromApi(response)
        return Outcome.ok(result)
    }

    async deleteAttachments(id: string, fileNames: string[]): Promise<Outcome<void>> {
        if (fileNames === null || fileNames.length == 0) return Outcome.error("No file names provided")

        const formData = new FormData()
        formData.append("id", id)
        formData.append("containerName", this.fileUploadContainerName)
        formData.append("fileName", JSON.stringify(fileNames))

        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/FileManagement/RemoveFile",
            body: formData,
        })

        if (response.isError()) return response
        return Outcome.ok(undefined)
    }

    async rotateImage(id: string, fileName: string, angle: number): Promise<Outcome<void>> {
        if (fileName === null) return Outcome.error("No file names provided")

        const formData = new FormData()
        formData.append("id", id)
        formData.append("containerName", this.fileUploadContainerName)
        formData.append("fileName", fileName)
        formData.append("angle", angle.toString())

        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/FileManagement/RotateImage",
            body: formData,
        })

        if (response.isError()) return response
        return Outcome.ok(undefined)
    }

    async fetchAttachments(id: string): Promise<Outcome<Attachment[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/FileManagement/GetFileUris",
            query: { id: id, containerName: this.fileUploadContainerName },
        })

        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        if (response.isError()) return response
        const result: Attachment[] = attachmentsFromApi(response)
        return Outcome.ok(result)
    }
}

function workRequestTableRowFromApi(obj: any): WorkRequestsTableRow {
    const workRequestInfo = {
        [WorkRequestType.Inspection]: {
            count: 0,
            highestPriority: workRequestTableRowPriorityFromApi(obj.highestInspectionWorkRequestPriority),
        },
        [WorkRequestType.Preventative]: {
            count: 0,
            highestPriority: workRequestTableRowPriorityFromApi(obj.highestPreventativeMaintenanceWorkRequestPriority),
        },
        [WorkRequestType.Service]: {
            count: 0,
            highestPriority: workRequestTableRowPriorityFromApi(obj.highestServiceWorkRequestPriority),
        },
    }

    ;(obj.workRequests ?? []).map((it: any) => {
        switch (numOrDefault(it.serviceTypeId, -1)) {
            case 1:
                workRequestInfo[WorkRequestType.Preventative].count++
                break
            case 2:
                workRequestInfo[WorkRequestType.Inspection].count++
                break
            case 3:
                workRequestInfo[WorkRequestType.Service].count++
                break
        }
    })

    return {
        assetId: numOrDefault(obj.assetID, 0),
        assetName: stringOrDefault(obj.assetLabel, ""),
        location: stringOrDefault(obj.location, ""),
        site: (() => {
            if (!obj.siteID || !obj.site) return null
            return {
                id: numOrDefault(obj.siteID, 0),
                name: stringOrDefault(obj.site, ""),
            }
        })(),
        hourMeter: numOrDefault(obj.engine1Hours, 0),
        odometer: numOrDefault(obj.odometer, 0),
        daysInactive: numOrDefault(obj.daysInactive, 0),
        class: obj.assetClassID
            ? {
                  id: numOrDefault(obj.assetClassID, 0),
                  name: stringOrDefault(obj.assetClass, ""),
              }
            : null,
        subCompany: obj.companyID
            ? {
                  id: numOrDefault(obj.companyID, 0),
                  name: stringOrDefault(obj.subCompanyName, ""),
              }
            : null,
        company: obj.companyID
            ? {
                  id: numOrDefault(obj.companyID, 0),
                  name: stringOrDefault(obj.companyName, ""),
              }
            : null,
        district: obj.district1
            ? {
                  id: numOrDefault(obj.districtID, 0),
                  name: stringOrDefault(obj.district1, ""),
              }
            : null,
        subDistrict: obj.district2
            ? {
                  id: numOrDefault(obj.subDistrictID, 0),
                  name: stringOrDefault(obj.district2, ""),
              }
            : null,
        unit: obj.district3
            ? {
                  id: numOrDefault(obj.unitID, 0),
                  name: stringOrDefault(obj.district3, ""),
              }
            : null,
        group: obj.groupID
            ? {
                  id: numOrDefault(obj.groupID, 0),
                  name: stringOrDefault(obj.group, ""),
              }
            : null,
        tag: tagFromApi(obj.tag),
        workOrders: WorkOrderListFromApi(obj.workOrders),
        workRequestTypeInfo: workRequestInfo,
        estimatedLaborHours: obj.estimatedLaborHours,
        estimatedLaborCost: obj.estimatedLaborCost,
        estimatedPartsCost: obj.estimatedPartsCost,
        estimatedTotalCost: obj.estimatedTotalCost,
        overdue: boolOrDefault(obj.overdue, false),
        upcoming: boolOrDefault(obj.upcoming, false),
        city: stringOrDefault(obj.city, ""),
        state: stringOrDefault(obj.state, ""),
    }
}

function userWorkRequestTableRowFromApi(workRequest: any): UserWorkRequestsTableRow {
    return {
        assetId: numOrDefault(workRequest.assetId, 0),
        assetName: stringOrDefault(workRequest.assetLabel, ""),
        id: numOrDefault(workRequest.workRequestId, 0),
        name: stringOrDefault(workRequest.description, ""),
    }
}

function workRequestTableRowPriorityFromApi(value: any): WorkRequestTableRowPriority {
    switch (stringOrDefault(value, "")) {
        case "Immediate":
            return WorkRequestTableRowPriority.Immediate
        case "High":
            return WorkRequestTableRowPriority.High
        case "Medium":
            return WorkRequestTableRowPriority.Medium
        case "Low":
            return WorkRequestTableRowPriority.Low
        default:
            return WorkRequestTableRowPriority.Medium
    }
}

function serviceRequestFormToCreateServiceRequestApi(
    form: ServiceRequestForm,
    userId: number,
    idMapper: CompanyFieldIdMapper
): Outcome<CreateServiceRequestApi> {
    if (form.assetId === null) return Outcome.error("No asset id provided")

    return Outcome.ok({
        assetId: form.assetId,
        serviceTypeId: idMapper.fromWorkRequestType(WorkRequestType.Service),
        urgencyId: idMapper.fromParentUrgency(form.urgency),
        serviceScheduleStepId: null,
        targetDueDate: form.dueDate?.toISOString() ?? null,
        dueHourMeter: form.dueHourMeter !== null ? safeParseFloat(form.dueHourMeter) : null,
        dueOdometer: form.dueOdometer !== null ? safeParseInt(form.dueOdometer) : null,
        serviceSubTypeId: idMapper.fromServiceRequestType(form.type),
        estimatedLaborHours:
            form.estimatedLaborHours !== null ? safeParseAndRoundFloat(form.estimatedLaborHours, 2) : null,
        estimatedLaborCost: form.estimatedLaborCost !== null ? safeParseFloat(form.estimatedLaborCost) : null,
        estimatedPartsCost: form.estimatedPartsCost !== null ? safeParseFloat(form.estimatedPartsCost) : null,
        actualLaborHours: null,
        actualLaborCost: null,
        actualPartsCost: null,
        description: form.workToBePerformed,
        specialNotes: form.specialInstructions,
        serviceCodeId: form.serviceCode?.id ?? null,
        requestedBy: userId,
        note: form.notes,
        status: workRequestStatusToApi(WorkRequestStatus.ToDo),
        tagStatus: tagTypeToApi(form.tag?.type ?? null),
        reasonTagged: form.tag?.reason ?? null,
        workOrderStatusId: idMapper.fromWorkOrderStatus(form.workOrderStatus ?? WorkOrderStatus.Pending),
        specialInstructions: form.workOrderSpecialInstructions,
        notifiedContacts: JSON.stringify({ UserId: form.notifyContacts }),
        assignedMechanic: form.workOrderAssignedTo !== null ? [form.workOrderAssignedTo] : null,
        externalWorkOrder: null,
        externalVendor: null,
        externalInvoiceNumber: null,
        externalNote: null,
        serviceRecord: false,
        createWorkOrder: form.createWorkOrder,
        siteId: null,
        workOrderId: form.workOrderId,
        fileId: form.filesId,
        followUpWorkRequestId: form.followUpWorkRequestId,
        CompletedHourMeter: null,
        CompletedOdometer: null,
        WorkCompletedDate: null,
        dismissed: false,
        createManualEntry: false,
        isUnplanned: form.isUnplanned,
    })
}

function serviceRecordFormToCreateWorkRequestApi(
    form: ServiceRecordForm,
    userId: number,
    idMapper: CompanyFieldIdMapper
): Outcome<CreateServiceRequestApi> {
    if (form.assetId === null) return Outcome.error("No asset id provided")
    return Outcome.ok({
        assetId: form.assetId,
        serviceTypeId: idMapper.fromWorkRequestType(WorkRequestType.Service),
        urgencyId: idMapper.fromParentUrgency(Urgency.Medium),
        serviceScheduleStepId: null,
        targetDueDate: form.serviceDate?.toISOString() ?? null,
        dueHourMeter: null,
        dueOdometer: null,
        serviceSubTypeId: null,
        estimatedLaborHours: null,
        estimatedLaborCost: null,
        estimatedPartsCost: null,
        actualLaborHours: form.laborHours !== null ? safeParseAndRoundFloat(form.laborHours, 2) : null,
        actualLaborCost: form.laborCost !== null ? safeParseFloat(form.laborCost) : null,
        actualPartsCost: form.partsCost !== null ? safeParseFloat(form.partsCost) : null,
        description: form.workPerformed,
        specialNotes: null,
        serviceCodeId: form.serviceCode?.id ?? null,
        requestedBy: userId,
        note: form.mechanicsNotes,
        status: workRequestStatusToApi(WorkRequestStatus.Done),
        tagStatus: tagTypeToApi(null),
        reasonTagged: null,
        workOrderStatusId: idMapper.fromWorkOrderStatus(WorkOrderStatus.Closed),
        specialInstructions: null,
        notifiedContacts: null,
        assignedMechanic: form.workPerformedBy,
        externalWorkOrder: form.externalWorkOrder,
        externalVendor: form.externalVendor,
        externalInvoiceNumber: form.externalInvoice,
        externalNote: form.externalNote,
        serviceRecord: true,
        createWorkOrder: true,
        siteId: form.site?.id ?? null,
        fileId: form.filesId,
        CompletedHourMeter: form.hourMeter ? safeParseFloat(form.hourMeter) : null,
        CompletedOdometer: form.odometer ? safeParseInt(form.odometer) : null,
        WorkCompletedDate: form.serviceDate ?? null,
        createManualEntry: form.createManualEntry,
        isUnplanned: form.isUnplanned,
    })
}

const GetAssignedMechanicsFromWorkPerformedBy = (contact: Contact | null) => (contact !== null ? [contact.id] : null)

function updateWorkRequestToApi(
    request: WorkRequest,
    idMapper: CompanyFieldIdMapper
): Omit<WorkRequestApi, "workRequestId" | "urgencyName" | "billingCodeId"> {
    return {
        assetId: request.assetId,
        workOrderId: request.workOrderId,
        serviceTypeId: idMapper.fromWorkRequestType(request.workRequestType),
        serviceScheduleStepId: request.step ? request.step.id : null,
        serviceSubTypeId: request.serviceSubTypeId ?? idMapper.fromServiceRequestType(request.serviceType),
        urgencyId: idMapper.fromParentUrgency(request.urgency),
        // urgencyName: null,
        targetDueDate: request.dueDate?.toISOString() ?? null,
        dueHourMeter: nullGreaterThanZeroOrNull(request.dueHourMeter),
        dueOdometer: nullGreaterThanZeroOrNull(request.dueOdometer),
        estimatedLaborHours: greaterThanZeroOrNull(request.estimatedLaborHours),
        estimatedLaborCost: greaterThanZeroOrNull(request.estimatedLaborCost),
        estimatedPartsCost: greaterThanZeroOrNull(request.estimatedPartsCost),
        actualLaborHours: greaterThanZeroOrNull(request.actualLaborHours),
        actualLaborCost: greaterThanZeroOrNull(request.actualLaborCost),
        actualPartsCost: greaterThanZeroOrNull(request.actualPartsCost),
        description: request.workToBePerformed,
        specialNotes: request.specialInstructions,
        note: request.notes,
        serviceCodeId: request.serviceCodeId,
        requestedBy: request.createdBy,
        status: workRequestStatusToApi(request.status),
        statusFlagReason: request.statusFlagReason,
        tag: request.tag,
        notifiedContacts: notifiedContactsToApi(request.notifyContacts),
        fileId: request.filesId,
        tasks: request.tasks ? tasksToApi(request.tasks) : null,
        // billingCodeId: request.billingCodeId,
    }
}

function workRequestFromApi(obj: any, idMapper: CompanyFieldIdMapper): WorkRequest {
    let workRequestType = idMapper.toWorkRequestType(numOrDefault(obj.serviceTypeId, 1))
    return {
        id: numOrDefault(obj.workRequestId, 0),
        assetId: numOrDefault(obj.assetId, 0),
        billingCodeId: nullNumOrDefault(obj.billingCodeId, null),
        workRequestType: workRequestType,
        estimatedLaborHours: numOrDefault(obj.estimatedLaborHours, 0),
        estimatedLaborCost: numOrDefault(obj.estimatedLaborCost, 0),
        estimatedPartsCost: numOrDefault(obj.estimatedPartsCost, 0),
        actualLaborHours: numOrDefault(obj.actualLaborHours, 0),
        actualLaborCost: numOrDefault(obj.actualLaborCost, 0),
        actualPartsCost: numOrDefault(obj.actualPartsCost, 0),
        urgency: idMapper.toUrgency(numOrDefault(obj.urgencyId, 0)),
        dueDate: nullableDateFromApi(obj.targetDueDate),
        dueOdometer: nullNumOrDefault(obj.dueOdometer, null),
        dueHourMeter: nullNumOrDefault(obj.dueHourMeter, null),
        specialInstructions: stringOrDefault(obj.specialNotes, ""),
        notes: stringOrDefault(obj.note, ""),
        workToBePerformed: stringOrDefault(obj.description, ""),
        workRequestStatusFlags: obj.workRequestStatusFlagsView,
        workOrderId: nullNumOrDefault(obj.workOrderId, null),
        serviceCodeId: nullNumOrDefault(obj.serviceCodeId, null),
        serviceType: idMapper.toServiceRequestType(numOrDefault(obj.serviceSubTypeId, 0)),
        createdBy: nullNumOrDefault(obj.requestedBy, null),
        createdByName: stringOrDefault(obj.requestedByName, ""),
        status: workRequestStatusFromApi(obj.status),
        tag: tagFromApi(obj.tag),

        step: stepFromApi(obj.serviceScheduleStep),
        schedule: scheduleFromApi(obj.serviceScheduleStep),

        dateCreated: nullableDateFromApi(obj.createDateTime) ?? new Date(0),
        hoursUntil: nullNumOrDefault(obj.hoursUntil, null), // TODO: API needs to implement this
        milesUntil: nullNumOrDefault(obj.milesUntil, null), // TODO: API needs to implement this
        daysUntil: nullNumOrDefault(obj.daysUntil, null), // TODO: API needs to implement this
        isUpcoming: false, // TODO: API needs to implement this
        isOverdue: false, // TODO: API needs to implement this
        attachments: [],
        history: [],
        tasks: tasksFromApi(obj, workRequestType),
        notifyContacts: notifiedContactsFromApi(obj.notifiedContacts),
        statusFlagReason: stringOrDefault(obj.statusFlagReason, ""),
        filesId: obj.fileId,
        followUpWorkRequestId: obj.followUpWorkRequestId,
        serviceSubTypeId: obj.serviceSubTypeId,
        dismissed: obj.dismissed,
        isUnplanned: obj.isUnplanned,
    }
}

function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min
}

function tasksFromApi(obj: any, workRequestType: WorkRequestType): Task[] {
    return obj.tasksView.map((tv: any): Task => {
        return {
            id: tv.serviceTaskID ? tv.serviceTaskID : tv.serviceTaskId,
            label: tv.name,
            measurement: tv.measurement ? tv.measurement : "",
            description: tv.description,
            value:
                workRequestType == WorkRequestType.Inspection
                    ? inspectionTaskValueForValue(taskValueFromApi(tv.taskStatus))
                    : completableTaskValueForValue(taskValueFromApi(tv.taskStatus)),
        }
    })
}
function tasksToApi(tasks: Task[]): string {
    return JSON.stringify(
        tasks.map((task: Task) => {
            return {
                serviceTaskID: task.id,
                name: task.label,
                measurement: task.measurement,
                description: task.description,
                taskStatus: taskValueToApi(task.value),
            }
        })
    )
}
function workRequestStatusFromApi(obj: any): WorkRequestStatus {
    switch (obj) {
        case 0:
            return WorkRequestStatus.ToDo
        case 1:
            return WorkRequestStatus.WorkNotPerformed
        case 2:
            return WorkRequestStatus.Done
    }
    throw new Error(`Invalid WorkRequestStatus ${obj}`)
}

function workRequestStatusToApi(status: WorkRequestStatus): number {
    switch (status) {
        case WorkRequestStatus.ToDo:
            return 0
        case WorkRequestStatus.WorkNotPerformed:
            return 1
        case WorkRequestStatus.Done:
            return 2
    }
}

function historyListFromApi(obj: any[], idMapper: CompanyFieldIdMapper): HistoryItem<WorkRequestStatus>[] {
    const result: HistoryItem<WorkRequestStatus>[] = Array(obj.length)
    let lastStatus: string | null = null

    for (let i = 0, l = result.length; i < l; i++) {
        var res = historyItemFromApi(obj[i], lastStatus, i === 0, idMapper)
        if (res.description.length > 0) {
            result[i] = res
            lastStatus = result[i].status
        }
    }

    return result.reverse()
}

function historyItemFromApi(
    obj: any,
    lastStatus: string | null,
    isCreatedEntry: boolean,
    idMapper: CompanyFieldIdMapper
): HistoryItem<WorkRequestStatus> {
    return {
        id: obj.id,
        primaryKey: obj.primaryKey,
        dateString: obj.dateTimeChangedString,
        date: obj.dateTimeChanged,
        description: obj.description,
        status: obj.status == null || obj.status == "" ? lastStatus : obj.status,
        userName: obj.user,
    }
}

function historyItemDescriptionFromApi(obj: any): string {
    if (!obj.propertiesChanged || !Array.isArray(obj.propertiesChanged)) return ""
    return arrayOfNotNull(
        obj.propertiesChanged.map((it: any) => {
            const field = it.field
            if (!field || !isString(field)) return null
            return `${field}`
        })
    ).join(`\n`)
}

function workRequestCountsFromApi(obj: any): Record<WorkRequestQuickFilterCount, number> {
    return {
        [WorkRequestQuickFilterCount.Assets]: numOrDefault(obj.quantityOfAssets, 0),
        [WorkRequestQuickFilterCount.Preventative]: numOrDefault(obj.quantityOfPreventatives, 0),
        [WorkRequestQuickFilterCount.Inspection]: numOrDefault(obj.quantityOfInspections, 0),
        [WorkRequestQuickFilterCount.Service]: numOrDefault(obj.quantityOfServices, 0),
        [WorkRequestQuickFilterCount.Overdue]: numOrDefault(obj.quantityOfOverdues, 0),
        [WorkRequestQuickFilterCount.RedTag]: numOrDefault(obj.quantityOfRedTags, 0),
        [WorkRequestQuickFilterCount.YellowTag]: numOrDefault(obj.quantityOfYellowTags, 0),
        [WorkRequestQuickFilterCount.Immediate]: numOrDefault(obj.quantityOfImmediates, 0),
        [WorkRequestQuickFilterCount.Upcoming]: 0,
    }
}

function greaterThanZeroOrNull(value: number): number | null {
    return value > 0 ? value : null
}

function nullGreaterThanZeroOrNull(value: number | null): number | null {
    return value !== null ? greaterThanZeroOrNull(value) : null
}

function WorkOrderListFromApi(workOrders: any[]) {
    if (!workOrders || !Array.isArray(workOrders)) return []

    return arrayOfNotNull(
        workOrders.map((it: any) => {
            if (it.number == null) return

            return {
                id: it.workOrderId != null ? it.workOrderId.toString() : "",
                label: it.number != null ? it.number.toString() : "",
                status: workOrderStatusFromApi(it.workOrderStatusId),
                creationDate: new Date(), //Don't have CreationDate field
            }
        })
    )
}

function workOrderStatusFromApi(obj: any): WorkOrderStatus {
    switch (obj) {
        case 0:
            return WorkOrderStatus.Deleted
        case 1:
            return WorkOrderStatus.Pending
        case 2:
            return WorkOrderStatus.Open
        case 3:
            return WorkOrderStatus.InProgress
        case 4:
            return WorkOrderStatus.Closed
        case 5:
            return WorkOrderStatus.WorkCompleted
    }
    throw new Error(`Invalid WorkRequestStatus ${obj}`)
}
function stepFromApi(serviceScheduleStep: any): import("../../model/Step").Step | null {
    if (!serviceScheduleStep) return null
    return {
        id: serviceScheduleStep.serviceScheduleItemId,
        step: serviceScheduleStep.step,
        label: serviceScheduleStep.serviceStep ? serviceScheduleStep.serviceStep.name : serviceScheduleStep.step,
    }
}
function scheduleFromApi(objStep: any): ServiceSchedule | null {
    let serviceSchedule = objStep?.serviceSchedule
    if (!serviceSchedule) return null

    let arrSteps = serviceSchedule.serviceScheduleItem.map((x: any) => stepFromApi(x))
    arrSteps.push(stepFromApi(objStep))

    return {
        id: serviceSchedule.serviceScheduleID,
        steps: arrSteps,
    }
}

function attachmentsFromApi(obj: any): Attachment[] {
    let attachments: Attachment[] = []
    attachments = obj.value.map((attachmentUrl: any): Attachment => {
        const isImgLink = attachmentUrl.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !== null
        let attachmentName = decodeURIComponent(attachmentUrl.split("/").pop().split("#")[0].split("?")[0])
        return {
            name: attachmentName,
            url: attachmentUrl,
            type: isImgLink ? AttachmentType.Image : AttachmentType.File,
        }
    })
    return attachments
}
function WorkRequestTableColumnToWorkRequestListItemField(column: any): any {
    switch (column) {
        case WorkRequestTableColumn.Service:
            return "ServiceWorkRequestCount"
        case WorkRequestTableColumn.PreventativeMaintenance:
            return "PreventativeMaintenanceWorkRequestCount"
        case WorkRequestTableColumn.Inspection:
            return "InspectionWorkRequestCount"
        case WorkRequestTableColumn.Asset:
            return "AssetIdentifier.Length,AssetIdentifier"
        case WorkRequestTableColumn.Class:
            return "AssetClass"
        case WorkRequestTableColumn.DaysInactive:
            return "DaysInactive"
        case WorkRequestTableColumn.District:
            return "District1"
        case WorkRequestTableColumn.EstimatedLaborCost:
            return "EstimatedLaborCost"
        case WorkRequestTableColumn.EstimatedLaborHours:
            return "EstimatedLaborHours"
        case WorkRequestTableColumn.EstimatedPartsCost:
            return "EstimatedPartsCost"
        case WorkRequestTableColumn.EstimatedTotalCost:
            return "EstimatedTotalCost"
        case WorkRequestTableColumn.Group:
            return "Group"
        case WorkRequestTableColumn.HourMeter:
            return "Engine1Hours"
        case WorkRequestTableColumn.Location:
            return "LastKnownLocation"
        case WorkRequestTableColumn.Odometer:
            return "Odometer"
        case WorkRequestTableColumn.SubCompany:
            return "SubCompanyName"
        case WorkRequestTableColumn.SubDistrict:
            return "District2"
        case WorkRequestTableColumn.Tag:
            return "Tag.Type,Tag.DateTagged"
        case WorkRequestTableColumn.Unit:
            return "District3"
        case WorkRequestTableColumn.WorkOrder:
            return "WorkOrderNumbers"
        case WorkRequestTableColumn.Total:
            return "TotalWorkRequests"
        case WorkRequestTableColumn.City:
            return "City"
        case WorkRequestTableColumn.State:
            return "State"
    }
}
