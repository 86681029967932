import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useIsInIframe, useIsMobile, useLastNonNull } from "@lib/Hooks"
import { useRouteParams } from "@lib/router/hooks"
import { Strings } from "@lib/Strings"
import { arrayOfNotNull, safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Color } from "@model/Color"
import { labelForContact } from "@model/contacts/Contact"
import {
    labelForServiceCode,
    labelForServiceRequestType,
    ServiceCode,
    ServiceRequestType,
    serviceRequestTypeForValue,
} from "@model/serviceRequests/ServiceRequest"
import { TableColumnSize } from "@model/tables/Table"
import {
    CompletableTaskValue,
    completableTaskValueForValue,
    InspectionTaskValue,
    inspectionTaskValueForValue,
} from "@model/Task"
import { colorForUrgency, labelForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { labelForWorkOrderStatus, WorkOrderStatus, workOrderStatusForValue } from "@model/workOrders/WorkOrder"
import { labelForWorkOrderPrintOption, WorkOrderPrintOption } from "@model/workOrders/WorkOrderPrintOption"
import {
    labelForWorkRequest,
    labelForWorkRequestStatus,
    WorkRequest,
    WorkRequestStatus,
    WorkRequestType,
} from "@model/workRequests/WorkRequest"
import { WorkOrderEffect, WorkOrderWorkRequestForm } from "@state/workOrders/WorkOrderScreenBloc"
import { Button, ButtonCont, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { Popover, PopoverItem } from "@ui/common/buttons/Popover"
import { RemoveButton } from "@ui/common/buttons/RemoveButton"
import { DataField, EditableDataField } from "@ui/common/DataField"
import { FlagChip } from "@ui/common/FlagChip"
import { AttachmentList } from "@ui/common/form/AttachmentList"
import { CheckBox } from "@ui/common/form/CheckBox"
import {
    DatePicker,
    DatePickerTheme,
    DateTimePicker,
    TextInputDatePicker,
    TextInputDateTimePicker,
} from "@ui/common/form/DatePicker"
import {
    CollapsibleFormField,
    FormField,
    FormFieldDirection,
    FormFieldNote,
    FormFieldNoteType,
} from "@ui/common/form/FormField"
import { FormFieldGroup, FormFieldGroupTheme } from "@ui/common/form/FormFieldGroup"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select, SelectTheme } from "@ui/common/form/Select"
import { TagFormField } from "@ui/common/form/TagFormField"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputTheme, TextInputType } from "@ui/common/form/TextInput"
import { WorkRequestStatusSelect } from "@ui/common/form/WorkRequestStatusSelect"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import { ImageUrls } from "@ui/common/images/ImageUrls"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { Page, PageContent } from "@ui/common/page/Page"
import { PageInfoSection, PageInfoSectionDataFields } from "@ui/common/page/PageInfoSection"
import {
    PageToolbar,
    PageToolbarActionButtons,
    PageToolbarButtons,
    PageToolbarTitle,
} from "@ui/common/page/PageToolbar"
import { CollapsibleTable, CollapsibleTableRow } from "@ui/common/tables/CollapsibleTable"
import { HistoryTable } from "@ui/common/tables/HistoryTable"
import { Tag, TagTheme } from "@ui/common/Tag"
import { DI } from "@ui/DI"
import { ExternalRoutes } from "@lib/Routes"
import { DismissWorkRequestModal } from "@ui/workRequests/DismissWorkRequestModal"
import { WorkNotPerformedWorkRequestModal } from "@ui/workRequests/WorkNotPerformedWorkRequestModal"
import { RemoveWorkRequestModal } from "@ui/workRequests/RemoveWorkRequestModal"
import React, { useEffect, useRef } from "react"
import { DismissWorkOrderModal } from "./DismissWorkOrderModal"
import { WorkOrderScreenViewModel } from "./WorkOrderScreenViewModel"
import { PencilIcon } from "@ui/common/images/InlinedSvgs"
import { CircleButton } from "@ui/common/buttons/CircleButton"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { ServiceRequestCreationModal } from "@ui/workRequests/ServiceRequestCreationModal"
import { labelForAsset } from "@model/assets/Asset"
import { AssetLocation } from "@ui/workRequests/AssetLocation"
import { UpdateTagModal } from "@ui/workOrders/UpdateTagModal"
import { TagChange, tagChangeFromTagType, TagType } from "@model/Tag"
import { PrintWorkOrderModal } from "@ui/workOrders/PrintWorkOrderModal"
import { RollbackClosedWorkOrderModal } from "./RollbackClosedWorkOrderModal"
import { WorkRequestStatusFlag } from "@model/workRequests/WorkRequestStatusFlag"
import { WorkRequestStatusIndicator } from "@ui/workRequests/WorkRequestStatusIndicator"
import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart"
import { AssetPart } from "@model/assets/AssetPart"
import { PermissionObject, PermissionType } from "@model/user/User"
import { UserBloc } from "@state/user/UserBloc"
import { classNames } from "@lib/HtmlUtil"
import { AdjustServiceRequestsModal } from "./AdjustServiceRequestsModal"

export function WorkOrderScreen(): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const { workOrderId } = useRouteParams()
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrderScreenViewModel())

    const getStatusOptions = () => {
        let options = Object.values(WorkOrderStatus).filter((option) => option !== WorkOrderStatus.Deleted)

        if (!viewModel.hasWorkOrderCloseExecutePermission()) {
            options = options.filter((option) => option !== WorkOrderStatus.Closed)
        }
        return options.map((it) => ({
            label: labelForWorkOrderStatus(it),
            value: it,
        }))
    }

    const itsBeenMoreThan24HsSinceClosed = (closedDate: Date | null | undefined): boolean => {
        if (closedDate) {
            return Math.abs(new Date().valueOf() - new Date(closedDate).valueOf()) / 3600000 > 24
        } else {
            return false
        }
    }

    useEffect(() => {
        viewModel.onMounted(workOrderId)
    }, [])

    let formOdometerInvalid: boolean =
        ((state.asset?.displayOdometer && !state.form.completedOdometer) ||
            (state.asset?.label?.endsWith("+") && //paired
                safeParseInt(state.priorAssetUsage?.assetReading.odometer) != 0 &&
                viewModel.isOdometerOutsideExpectedRange(
                    state.form.completedOdometer,
                    state.priorAssetUsage?.assetReading.odometer,
                    state.asset?.displayOdometer
                ) &&
                state.form.completedOdometer !== "")) ??
        false

    let formHourMeterInvalid: boolean =
        ((state.asset?.displayHourMeter && !state.form.completedHourMeter) ||
            (state.asset?.label?.endsWith("+") && //paired
                safeParseFloat(state.priorAssetUsage?.assetReading.hourMeter) != 0 &&
                viewModel.isHourMeterOutsideExpectedRange(
                    state.form.completedHourMeter,
                    state.priorAssetUsage?.assetReading.hourMeter,
                    state.asset?.displayHourMeter
                ) &&
                state.asset?.displayHourMeter &&
                state.form.completedHourMeter !== "")) ??
        false

    let formWorkCompletedDateInvalid =
        !state.form.workCompletedDate || viewModel.isServiceDateTimeOtsideExpectedRange(state.form.workCompletedDate)

    return (
        <Page className={"page--work-order"}>
            <PageToolbar>
                <PageToolbarTitle>
                    <div style={{ display: "flex", gap: "0px 20px", alignItems: "center" }}>
                        <span>Work Order {state.workOrder?.number}</span>
                        {state.workOrder?.tag && <Tag tag={state.workOrder?.tag} theme={TagTheme.Light} />}
                        {!state.isLoading && state.form?.urgency === Urgency.Immediate && (
                            <FlagChip
                                flags={[
                                    {
                                        label: labelForUrgency(state.form?.urgency ?? Urgency.Medium),
                                        color: colorForUrgency(state.form?.urgency ?? Urgency.Medium),
                                    },
                                ]}
                            />
                        )}
                    </div>
                    {isMobile && <div className="work-order-asset-label--mobile">{labelForAsset(state.asset)}</div>}
                    {/* {Commented because of https://gaugetelematics.visualstudio.com/Gauge/_workitems/edit/14741 */
                    /* <ButtonCont className="page-toolbar-action-button-cont">
                        <Button
                            type={ButtonType.Contained}
                            onClick={() => viewModel.subscribeUser()}
                            label={state.isSubscribedInAllWorkRequests ? "Subscribed" : "Subscribe"}
                            isEnabled={!state.isSubscribedInAllWorkRequests}
                            
                        ></Button>
                    </ButtonCont> */}
                </PageToolbarTitle>
                {/* IMPORTANT!: Adding false to disable the Rollback button for PROD deployment} */}
                <PageToolbarActionButtons>
                    {false &&
                        !state.isLoading &&
                        viewModel.getUser().hasAccess(PermissionObject.RollbackWorkOrder, PermissionType.Execute) &&
                        state.workOrder?.status == WorkOrderStatus.Closed &&
                        viewModel.hasRollbackPermission() && (
                            <CircleButton
                                className="circle-button--caret-left"
                                bottomLabel="Rollback"
                                disabled={itsBeenMoreThan24HsSinceClosed(state.workOrder?.closedDate)}
                                color={Color.Transparent}
                                toolTip={"Rollback Work Order"}
                                onClick={() => {
                                    viewModel.showRollbackModal()
                                }}
                            />
                        )}
                    {!state.isLoading &&
                    viewModel.state.workOrder?.status != WorkOrderStatus.Closed &&
                    viewModel.hasWorkRequestAddPermission() ? (
                        <CircleButton
                            className="circle-button--add"
                            bottomLabel="Repair"
                            color={Color.Transparent}
                            toolTip={"Add Repair Request to Work Order"}
                            onClick={() => {
                                viewModel.createServiceButtonClicked(ServiceFormType.Request)
                            }}
                        />
                    ) : (
                        ""
                    )}
                    {/*<Button*/}
                    {/*    type={ButtonType.Icon}*/}
                    {/*    theme={ButtonTheme.Dark}*/}
                    {/*    iconUrl={ImageUrls.Gear}*/}
                    {/*    tooltip="Parts List"*/}
                    {/*/>*/}
                    <Button
                        type={ButtonType.Icon}
                        theme={ButtonTheme.Dark}
                        iconUrl={ImageUrls.WrenchSolid}
                        onClick={() => viewModel.partListViewClicked()}
                        tooltip="View Parts List"
                    />
                    {!isMobile && (
                        <Button
                            type={ButtonType.Icon}
                            theme={ButtonTheme.Dark}
                            iconUrl={ImageUrls.Print}
                            onClick={() => viewModel.workOrderToPrintChanged(state.workOrder!.id)}
                            tooltip="Print"
                        />
                    )}
                    {!Object.values(viewModel.state.workRequestForms).some(
                        (wr) => wr.status == WorkRequestStatus.Done && viewModel.state.workOrder?.serviceRecord !== true
                    ) &&
                        (viewModel.state.workOrder?.status != WorkOrderStatus.Closed || viewModel.state.workOrder?.serviceRecord === true) &&
                        viewModel.hasWorkOrderDeletePermission() &&
                        !isMobile && (
                            <Button
                                type={ButtonType.Icon}
                                theme={ButtonTheme.Dark}
                                iconUrl={ImageUrls.Trash}
                                onClick={() => viewModel.dismissWorkOrderClicked()}
                                tooltip="Delete Work Order"
                            />
                        )}
                </PageToolbarActionButtons>
                <PageToolbarButtons>
                    {state.formHasChanged && (
                        <Button
                            type={ButtonType.Text}
                            theme={ButtonTheme.Light}
                            label="Cancel"
                            onClick={() =>
                                state.workOrder?.status == WorkOrderStatus.Closed
                                    ? viewModel.ClosedWorkOrderCancelClicked()
                                    : viewModel.cancelClicked()
                            }
                        />
                    )}
                    <Button
                        type={ButtonType.Contained}
                        label={
                            state.workOrder?.status == WorkOrderStatus.Closed &&
                            !viewModel.state.form.closedWorkOrderEditMode
                                ? "Edit"
                                : "Save"
                        }
                        isEnabled={
                            viewModel.hasWorkOrderEditPermission() &&
                            ((state.workOrder?.status == WorkOrderStatus.Closed &&
                                !viewModel.state.form.closedWorkOrderEditMode) ||
                                (viewModel.state.form.closedWorkOrderEditMode && state.formHasChanged) ||
                                (state.formHasChanged && viewModel.isFormValid()))
                        }
                        onClick={() => {
                            if (
                                state.workOrder?.status == WorkOrderStatus.Closed &&
                                !viewModel.state.form.closedWorkOrderEditMode
                            ) {
                                viewModel.ClosedWorkOrderEditModeClicked()
                            } else {
                                viewModel.handleSaveClicked()
                                viewModel.state.form.closedWorkOrderEditMode = false
                            }
                        }}
                    />
                </PageToolbarButtons>
            </PageToolbar>
            <LoadingIndicatorContainer>
                <LoadingIndicator isLoading={state.isLoading} />
                <PageInfoSection>
                    <Grid columns={4}>
                        <FormField label={"Status"}>
                            <Select
                                theme={SelectTheme.Dark}
                                options={getStatusOptions()}
                                value={state.form?.status ?? WorkOrderStatus.Pending}
                                isEnabled={
                                    state.workOrder?.status != WorkOrderStatus.Closed &&
                                    viewModel.hasWorkOrderEditPermission()
                                }
                                id="status-dropdown"
                                onChange={(value) => {
                                    viewModel.statusChanged(workOrderStatusForValue(value))
                                    const statusDropdown =
                                        document.getElementById("status-dropdown")?.parentElement?.parentElement
                                    if (
                                        statusDropdown &&
                                        isMobile &&
                                        value == WorkOrderStatus.WorkCompleted &&
                                        state.workRequests.some((x) => x.status != WorkRequestStatus.Done)
                                    ) {
                                        statusDropdown.style.marginBottom = "10px"
                                    } else if (statusDropdown) {
                                        statusDropdown.style.marginBottom = "0px"
                                    }
                                }}
                            ></Select>
                            {isMobile &&
                                state.form.status == WorkOrderStatus.WorkCompleted &&
                                state.workRequests.some((x) => x.status != WorkRequestStatus.Done) && (
                                    <div style={{ position: "absolute", top: "44px" }}>
                                        <FormFieldNote type={FormFieldNoteType.Error}>
                                            All Service Requests must be marked as Done
                                        </FormFieldNote>
                                    </div>
                                )}
                        </FormField>

                        <FormField label={"Assigned To"} direction={FormFieldDirection.Column}>
                            <Select
                                theme={SelectTheme.Light}
                                options={[
                                    { label: "Unassigned", value: "-1" },
                                    ...(state.workOrder?.status !== WorkOrderStatus.Closed
                                        ? state.mechanics
                                              .filter((it) => !it.deleted) 
                                              .map((it) => ({
                                                  label: labelForContact(it),
                                                  value: it.id.toString(),
                                              }))
                                        : state.mechanics
                                              .filter((it) => !it.deleted || it.id.toString() === state.form.assignedTo.toString()) // Filter out deleted contacts unless they are assigned
                                              .map((it) => ({
                                                  label: labelForContact(it),
                                                  value: it.id.toString(),
                                              }))),
                                ]}
                                value={state.form.assignedTo.toString()}
                                isEnabled={
                                    (state.workOrder?.status != WorkOrderStatus.Closed ||
                                        viewModel.state.form.closedWorkOrderEditMode) &&
                                    viewModel.hasWorkOrderEditPermission()
                                }
                                onChange={(value) => viewModel.assignedToChanged(safeParseInt(value))}
                            ></Select>

                            {state.assignedToContact !== null && (
                                <FormFieldNote inset={true}>
                                    {state.assignedToContact?.cellPhone}&nbsp;&nbsp;&nbsp;&nbsp;
                                    {state.assignedToContact?.email}
                                </FormFieldNote>
                            )}
                        </FormField>
                        {isMobile && (
                            <>
                                {(state.form?.status == WorkOrderStatus.WorkCompleted ||
                                    state.form?.status == WorkOrderStatus.Closed) && (
                                    <FormField label={"Service Date and Time*"} direction={FormFieldDirection.Column}>
                                        <TextInputDateTimePicker
                                            theme={DatePickerTheme.Light}
                                            value={state.form.workCompletedDate}
                                            onChange={(date) => viewModel.workCompletedDateChanged(date)}
                                            disabled={
                                                state.form?.status == WorkOrderStatus.Closed ||
                                                !viewModel.hasWorkOrderEditPermission()
                                            }
                                            disableFuture={true}
                                            disablePast={false}
                                            outlineInvalid={formWorkCompletedDateInvalid}
                                        />
                                        {!state.form.workCompletedDate && (
                                            <FormFieldNote type={FormFieldNoteType.Error}>
                                                Service Date and Time required
                                            </FormFieldNote>
                                        )}
                                        {state.form.workCompletedDate &&
                                        viewModel.isServiceDateTimeOtsideExpectedRange(state.form.workCompletedDate) ? (
                                            <FormFieldNote type={FormFieldNoteType.Error}>
                                                Service time cannot be in the future
                                            </FormFieldNote>
                                        ) : (
                                            ""
                                        )}
                                    </FormField>
                                )}
                                {(state.form?.status == WorkOrderStatus.WorkCompleted ||
                                    state.form?.status == WorkOrderStatus.Closed) && (
                                    <FormField
                                        className="PhysicalMeter"
                                        label={"Physical Hour Meter" + (state.asset?.displayHourMeter ? "*" : "")}
                                        direction={FormFieldDirection.Column}
                                    >
                                        <TextInput
                                            theme={TextInputTheme.Light}
                                            type={TextInputType.Decimal}
                                            min={0}
                                            isEnabled={
                                                state.form?.status != WorkOrderStatus.Closed &&
                                                viewModel.hasWorkOrderEditPermission()
                                            }
                                            value={state.form.completedHourMeter ?? ""}
                                            onChange={(value) => viewModel.completedHourMeterChanged(value)}
                                            outlineInvalid={
                                                !viewModel.state.form.closedWorkOrderEditMode
                                                    ? formHourMeterInvalid
                                                    : false
                                            }
                                        />
                                        <FormFieldNote>
                                            Smart Hub Hour Meter:{" "}
                                            {safeParseFloat(state.priorAssetUsage?.assetReading.hourMeter).toFixed(1)}
                                        </FormFieldNote>
                                      
                                        {viewModel.displayPhysicalHourmeterRequiredLegend() && !state.form.closedWorkOrderEditMode && (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Physical Hour Meter required
                                                </FormFieldNote>
                                            )}
                                        {state.asset?.label?.endsWith("+") && //paired
                                        safeParseFloat(state.priorAssetUsage?.assetReading.hourMeter) != 0 &&
                                        viewModel.isHourMeterOutsideExpectedRange(
                                            state.form.completedHourMeter,
                                            state.priorAssetUsage?.assetReading.hourMeter,
                                            state.asset?.displayHourMeter
                                        ) &&
                                        state.asset?.displayHourMeter &&
                                        state.form.completedHourMeter !== "" ? (
                                            <FormFieldNote type={FormFieldNoteType.Error}>
                                                Outside of expected range
                                            </FormFieldNote>
                                        ) : (
                                            ""
                                        )}
                                    </FormField>
                                )}
                                {(state.form?.status == WorkOrderStatus.WorkCompleted ||
                                    state.form?.status == WorkOrderStatus.Closed) && (
                                    <FormField
                                        className="PhysicalMeter"
                                        label={"Physical Odometer" + (state.asset?.displayOdometer ? "*" : "")}
                                        direction={FormFieldDirection.Column}
                                    >
                                        <TextInput
                                            theme={TextInputTheme.Light}
                                            type={TextInputType.Integer}
                                            min={0}
                                            isEnabled={
                                                state.form?.status != WorkOrderStatus.Closed &&
                                                viewModel.hasWorkOrderEditPermission()
                                            }
                                            value={state.form.completedOdometer ?? ""}
                                            onChange={(value) => viewModel.completedOdometerChanged(value)}
                                            outlineInvalid={
                                                !viewModel.state.form.closedWorkOrderEditMode
                                                    ? formOdometerInvalid
                                                    : false
                                            }
                                        />
                                        <FormFieldNote>
                                            Smart Hub Odometer:{" "}
                                            {safeParseInt(state.priorAssetUsage?.assetReading.odometer)}
                                        </FormFieldNote>

                                        {viewModel.displayPhysicalOdometerRequiredLegend() && !state.form.closedWorkOrderEditMode && (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Physical Odometer required
                                                </FormFieldNote>
                                            )}

                                        {state.asset?.label?.endsWith("+") && //paired
                                        safeParseInt(state.priorAssetUsage?.assetReading.odometer) != 0 &&
                                        viewModel.isOdometerOutsideExpectedRange(
                                            state.form.completedOdometer,
                                            state.priorAssetUsage?.assetReading.odometer,
                                            state.asset?.displayOdometer
                                        ) &&
                                        state.form.completedOdometer !== "" ? (
                                            <FormFieldNote type={FormFieldNoteType.Error}>
                                                Outside of expected range
                                            </FormFieldNote>
                                        ) : (
                                            ""
                                        )}
                                    </FormField>
                                )}
                            </>
                        )}
                        <FormField label={"Urgency"}>
                            <Select
                                theme={SelectTheme.Light}
                                options={Object.values(Urgency).map((it) => ({
                                    label: labelForUrgency(it),
                                    value: it,
                                }))}
                                value={state.form?.urgency ?? Urgency.Medium}
                                isEnabled={                                    
                                    (state.workOrder?.status != WorkOrderStatus.Closed ||
                                        viewModel.state.form.closedWorkOrderEditMode) &&
                                    viewModel.hasWorkOrderEditPermission()
                                }
                                onChange={(value) => viewModel.urgencyChanged(urgencyForValue(value))}
                            ></Select>
                        </FormField>
                        <FormField label={"Due Date"}>
                            <TextInputDatePicker
                                theme={DatePickerTheme.Light}
                                value={state.form?.dueDate ?? null}
                                disabled={
                                    (state.workOrder?.status == WorkOrderStatus.Closed &&
                                        !viewModel.state.form.closedWorkOrderEditMode) ||
                                    !viewModel.hasWorkOrderEditPermission()
                                }
                                onChange={(date) => viewModel.dueDateChanged(date)}
                            />
                        </FormField>
                        {isMobile && (
                            <>
                                {(state.asset?.hourMeter ?? null) !== null && (
                                    <FormField label="Due Hour Meter" direction={FormFieldDirection.Column}>
                                        <TextInput
                                            theme={TextInputTheme.Light}
                                            type={TextInputType.Decimal}
                                            value={Strings.formatDecimal(state.form.dueHourMeter ?? "")}
                                            onChange={(value) => viewModel.dueHourMeterChanged(value)}
                                            isEnabled={true}
                                        />
                                        {state.asset?.hourMeter != null && state.asset?.hourMeter != 0 ? (
                                            <FormFieldNote>
                                                Current: {Strings.formatDecimal(state.asset?.hourMeter ?? 0)}
                                            </FormFieldNote>
                                        ) : (
                                            ""
                                        )}
                                    </FormField>
                                )}
                                {(state.asset?.odometer ?? null) !== null && (
                                    <FormField label="Due Odometer" direction={FormFieldDirection.Column}>
                                        <TextInput
                                            theme={TextInputTheme.Light}
                                            type={TextInputType.Integer}
                                            min={0}
                                            value={Strings.formatInteger(state.form.dueOdometer ?? "")}
                                            onChange={(value) => viewModel.dueOdometerChanged(value)}
                                            isEnabled={true}
                                        />
                                        {state.asset?.odometer != null && state.asset?.odometer != 0 ? (
                                            <FormFieldNote>
                                                Current: {Strings.formatInteger(state.asset?.odometer ?? 0)}
                                            </FormFieldNote>
                                        ) : (
                                            ""
                                        )}
                                    </FormField>
                                )}
                            </>
                        )}
                    </Grid>
                    {!isMobile && (
                        <PageInfoSectionDataFields>
                            <DataField
                                label="Identifier/Stock #"
                                value={
                                    <>
                                        <a href={ExternalRoutes.SmartHubAsset(state.asset?.id ?? 0)} target="_blank">
                                            {state.asset?.identifier}
                                        </a>
                                        {state.asset?.tag && <Tag tag={state.asset?.tag} />}
                                    </>
                                }
                            />
                            <DataField label="Make" value={state.asset?.make.name} />
                            <DataField label="Model" value={state.asset?.model.name} />
                            <DataField label="Year" value={state.asset?.modelYear} />
                            <DataField label="VIN" value={state.asset?.serialNumber} />
                            <DataField label="Category" value={state.asset?.category.name} />
                            <DataField label="Class" value={state.asset?.class?.name ?? ""} />
                            <DataField
                                label="Billing Code"
                                value={state.asset?.billingCodeCode + " " + state.asset?.billingCodeName}
                            />
                            <DataField
                                label="Last Known Location"
                                value={
                                    <AssetLocation
                                        location={state.asset?.location ?? null}
                                        site={state.asset?.site ?? null}
                                        hasLinks={!isMobile}
                                    />
                                }
                            />
                            <DataField label="" value="" color={Color.Transparent} />
                            <DataField
                                label="Hour Meter / Lifetime Hour Meter"
                                value={
                                    <>
                                        {safeParseFloat(state.asset?.hourMeter).toFixed(1) +
                                            " / " +
                                            safeParseFloat(state.asset?.lifetimeHourMeter).toFixed(1)}
                                    </>
                                }
                                buttons={
                                    state.workOrder?.status != WorkOrderStatus.Closed &&
                                    viewModel.hasWorkOrderEditPermission() ? (
                                        <Button
                                            type={ButtonType.Icon}
                                            label="Edit"
                                            onClick={() => viewModel.hourMeterLifetimeHourMeterEditClicked()}
                                        >
                                            <PencilIcon />
                                        </Button>
                                    ) : (
                                        ""
                                    )
                                }
                            />
                            <EditableDataField
                                label="Due Hour Meter"
                                value={state.form.dueHourMeter}
                                textInput={{
                                    type: TextInputType.Decimal,
                                    min: 0,
                                    onChange: (value) => viewModel.dueHourMeterChanged(value),
                                }}
                                disabled={
                                    state.workOrder?.status == WorkOrderStatus.Closed ||
                                    !viewModel.hasWorkOrderEditPermission()
                                }
                            />
                            <DataField
                                label="Odometer / Lifetime Odometer"
                                value={
                                    <>
                                        {safeParseInt(state.asset?.odometer) +
                                            " / " +
                                            safeParseInt(state.asset?.lifetimeOdometer)}
                                    </>
                                }
                                buttons={
                                    state.workOrder?.status != WorkOrderStatus.Closed ||
                                    viewModel.hasWorkOrderEditPermission() ? (
                                        <Button
                                            type={ButtonType.Icon}
                                            label="Edit"
                                            onClick={() => viewModel.odometerLifetimeOdometerEditClicked()}
                                        >
                                            <PencilIcon />
                                        </Button>
                                    ) : (
                                        ""
                                    )
                                }
                            />
                            <EditableDataField
                                label="Due Odometer"
                                value={state.form.dueOdometer}
                                textInput={{
                                    type: TextInputType.Integer,
                                    min: 0,
                                    onChange: (value) => viewModel.dueOdometerChanged(value),
                                }}
                                disabled={
                                    state.workOrder?.status == WorkOrderStatus.Closed ||
                                    !viewModel.hasWorkOrderEditPermission()
                                }
                            />
                        </PageInfoSectionDataFields>
                    )}
                </PageInfoSection>
                <PageContent>
                    <GridCont gap={GridGap.Medium}>
                        {(state.form?.status == WorkOrderStatus.InProgress ||
                            state.form?.status == WorkOrderStatus.WorkCompleted ||
                            state.form?.status == WorkOrderStatus.Closed) && (
                            <Grid columns={5}>
                                <FormField label={"Work Performed By"} direction={FormFieldDirection.Column}>
                                    <MultiSelect
                                        options={state.workPerformedByContacts
                                            .filter((it) => !it.deleted|| it.id.toString() === state.form.workPerformedById?.toString())
                                            .map((contact) => ({
                                            key: contact.id,
                                            value: contact.id.toString(),
                                            label: labelForContact(contact, viewModel.getUser()),
                                            isChecked:
                                                (state.form.workPerformedById != null
                                                    ? state.form.workPerformedById.includes(contact.id)
                                                    : false) ||
                                                (state.form.workPerformedById == null &&
                                                state.assignedToContact !== null
                                                    ? state.assignedToContact.id == contact.id
                                                    : false),
                                        }))}
                                        disabled={
                                            (state.workOrder?.status == WorkOrderStatus.Closed &&
                                                !viewModel.state.form.closedWorkOrderEditMode) ||
                                            !viewModel.hasWorkOrderEditPermission()
                                        }
                                        onSelectionChanged={(value) =>
                                            viewModel.workPerformedByChanged(value.map((i) => safeParseInt(i)))
                                        }
                                    />
                                    {state.workPerformedByContactsSelected
                                        ?.filter((item, i) => item.cellPhone != null || item.email != null)
                                        .splice(0, 3) // we only show the first 3 items...
                                        .map((workPerformedByContact, i) => (
                                            <FormFieldNote inset={true} key={i}>
                                                {workPerformedByContact?.cellPhone != null ? (
                                                    <span>
                                                        {workPerformedByContact.cellPhone}&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                                {workPerformedByContact?.email}
                                            </FormFieldNote>
                                        ))}
                                </FormField>
                                <FormField label={"Site"}>
                                    <Select
                                        theme={SelectTheme.Normal}
                                        options={[
                                            { label: "Select", value: "" },
                                            ...state.sites.map((it) => ({
                                                label: it.name,
                                                value: it.id.toString(),
                                            })),
                                        ]}
                                        value={state.form.siteId ?? ""}
                                        isEnabled={
                                            (state.workOrder?.status != WorkOrderStatus.Closed ||
                                                viewModel.state.form.closedWorkOrderEditMode) &&
                                            viewModel.hasWorkOrderEditPermission()
                                        }
                                        onChange={(value) => viewModel.siteIdChanged(safeParseInt(value))}
                                    ></Select>
                                </FormField>
                                {!isMobile &&
                                    (state.form?.status == WorkOrderStatus.WorkCompleted ||
                                        state.form?.status == WorkOrderStatus.Closed) && (
                                        <FormField
                                            label={"Service Date and Time*"}
                                            direction={FormFieldDirection.Column}
                                        >
                                            <TextInputDateTimePicker
                                                value={state.form.workCompletedDate}
                                                onChange={(date) => viewModel.workCompletedDateChanged(date)}
                                                disabled={
                                                    state.workOrder?.status == WorkOrderStatus.Closed ||
                                                    !viewModel.hasWorkOrderEditPermission()
                                                }
                                                disableFuture={true}
                                                disablePast={false}
                                                outlineInvalid={formWorkCompletedDateInvalid}
                                            />
                                            {!state.form.workCompletedDate && (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Service Date and Time required
                                                </FormFieldNote>
                                            )}
                                            {state.form.workCompletedDate &&
                                            viewModel.isServiceDateTimeOtsideExpectedRange(
                                                state.form.workCompletedDate
                                            ) ? (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Service time cannot be in the future
                                                </FormFieldNote>
                                            ) : (
                                                ""
                                            )}
                                        </FormField>
                                    )}
                                {!isMobile &&
                                    (state.form?.status == WorkOrderStatus.WorkCompleted ||
                                        state.form?.status == WorkOrderStatus.Closed) && (
                                        <FormField
                                            className="PhysicalMeter"
                                            label={"Physical Hour Meter" + (state.asset?.displayHourMeter ? "*" : "")}
                                            direction={FormFieldDirection.Column}
                                        >
                                            <TextInput
                                                type={TextInputType.Decimal}
                                                min={0}
                                                isEnabled={
                                                    state.workOrder?.status != WorkOrderStatus.Closed &&
                                                    viewModel.hasWorkOrderEditPermission()
                                                }
                                                value={state.form.completedHourMeter ?? ""}
                                                onChange={(value) => viewModel.completedHourMeterChanged(value)}
                                                outlineInvalid={formHourMeterInvalid}
                                            />
                                            <FormFieldNote>
                                                Smart Hub Hour Meter:{" "}
                                                {safeParseFloat(state.priorAssetUsage?.assetReading.hourMeter).toFixed(
                                                    1
                                                )}
                                            </FormFieldNote>
                                            {state.asset?.label?.endsWith("+") &&(
                                            <Button
                                            type={ButtonType.Icon}
                                            theme={ButtonTheme.Light}
                                            iconUrl={ImageUrls.Info}
                                            onClick={() => viewModel.meterSyncInfoClicked()}
                                            tooltip="Automatic Meter Syncs"
                                            className="MeterSyncInfo"
                                            />
                                                )}

                                            {viewModel.displayPhysicalHourmeterRequiredLegend() && (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Physical Hour Meter required
                                                </FormFieldNote>
                                            )}
                                            {state.asset?.label?.endsWith("+") && //paired
                                            safeParseFloat(state.priorAssetUsage?.assetReading.hourMeter) != 0 &&
                                            viewModel.isHourMeterOutsideExpectedRange(
                                                state.form.completedHourMeter,
                                                state.priorAssetUsage?.assetReading.hourMeter,
                                                state.asset?.displayHourMeter
                                            ) &&
                                            state.asset?.displayHourMeter &&
                                            state.form.completedHourMeter !== "" ? (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Outside of expected range
                                                </FormFieldNote>
                                            ) : (
                                                ""
                                            )}
                                        </FormField>
                                    )}
                                {!isMobile &&
                                    (state.form?.status == WorkOrderStatus.WorkCompleted ||
                                        state.form?.status == WorkOrderStatus.Closed) && (
                                        <FormField
                                            className="PhysicalMeter"
                                            label={"Physical Odometer" + (state.asset?.displayOdometer ? "*" : "")}
                                            direction={FormFieldDirection.Column}
                                        >
                                            <TextInput
                                                type={TextInputType.Integer}
                                                min={0}
                                                isEnabled={
                                                    state.workOrder?.status != WorkOrderStatus.Closed &&
                                                    viewModel.hasWorkOrderEditPermission()
                                                }
                                                value={state.form.completedOdometer ?? ""}
                                                onChange={(value) => viewModel.completedOdometerChanged(value)}
                                                outlineInvalid={formOdometerInvalid}
                                            />
                                            <FormFieldNote>
                                                Smart Hub Odometer:{" "}
                                                {safeParseInt(state.priorAssetUsage?.assetReading.odometer)}
                                            </FormFieldNote>

                                            {state.asset?.label?.endsWith("+") &&(
                                            <Button
                                            type={ButtonType.Icon}
                                            theme={ButtonTheme.Light}
                                            iconUrl={ImageUrls.Info}
                                            onClick={() => viewModel.meterSyncInfoClicked()}
                                            tooltip="Automatic Meter Syncs"
                                            className="MeterSyncInfo"
                                            />
                                                )}

                                            {viewModel.displayPhysicalOdometerRequiredLegend() && (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Physical Odometer required
                                                </FormFieldNote>
                                            )}

                                            {state.asset?.label?.endsWith("+") && //paired
                                            safeParseInt(state.priorAssetUsage?.assetReading.odometer) != 0 &&
                                            viewModel.isOdometerOutsideExpectedRange(
                                                state.form.completedOdometer,
                                                state.priorAssetUsage?.assetReading.odometer,
                                                state.asset?.displayOdometer
                                            ) &&
                                            state.form.completedOdometer !== "" ? (
                                                <FormFieldNote type={FormFieldNoteType.Error}>
                                                    Outside of expected range
                                                </FormFieldNote>
                                            ) : (
                                                ""
                                            )}
                                        </FormField>
                                    )}
                            </Grid>
                        )}

                        <Grid columns={1}>
                            <FormField label="Special Instructions">
                                <TextArea
                                    maxLength={1000}
                                    value={state.form.specialInstructions}
                                    disabled={
                                        (state.workOrder?.status == WorkOrderStatus.Closed &&
                                            !viewModel.state.form.closedWorkOrderEditMode) ||
                                        !viewModel.hasWorkOrderEditPermission()
                                    }
                                    onChange={(value) => viewModel.specialInstructionsChanged(value)}
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={1}>
                            <FormField
                                label={
                                    "Service Requests" + (state.form.status == WorkOrderStatus.WorkCompleted ? "*" : "")
                                }
                            >
                                {state.form.status == WorkOrderStatus.WorkCompleted &&
                                    state.workRequests.some((x) => x.status != WorkRequestStatus.Done) && (
                                        <div style={{ position: "absolute", top: "-4px" }}>
                                            <FormFieldNote type={FormFieldNoteType.Error}>
                                                All Service Requests must be marked as Done
                                            </FormFieldNote>
                                        </div>
                                    )}
                                <div style={{ position: "relative", top: "20px", width: "100%" }}>
                                    <WorkOrderWorkRequestsTable
                                        viewModel={viewModel}
                                        workRequests={state.workRequests}
                                        workRequestForms={state.workRequestForms}
                                    />
                                </div>
                            </FormField>
                        </Grid>

                        <Grid columns={1}>
                            <CollapsibleFormField
                                label="Work Order History"
                                isLoading={viewModel.state.effectStatus[WorkOrderEffect.FetchWorkOrderHistory].isBusy()}
                                onClick={() => {
                                    if (state.workOrder) viewModel.loadWorkOrderHistory(state.workOrder?.id)
                                }}
                                isCollapsed={true}
                            >
                                <HistoryTable history={state.workOrder?.history ?? []} statusLabelCreator={(x) => x} />
                            </CollapsibleFormField>
                        </Grid>
                        {isMobile && (
                            <Grid columns={1}>
                                <FormField className="modal-save-buttons-top">
                                    {state.formHasChanged && (
                                        <Button
                                            type={ButtonType.Text}
                                            theme={ButtonTheme.Light}
                                            label="Cancel"
                                            onClick={() =>
                                                state.workOrder?.status == WorkOrderStatus.Closed
                                                    ? viewModel.ClosedWorkOrderCancelClicked()
                                                    : viewModel.cancelClicked()
                                            }
                                        />
                                    )}
                                    <Button
                                        type={ButtonType.Contained}
                                        label={state.workOrder?.status == WorkOrderStatus.Closed ? "Edit" : "Save"}
                                        isEnabled={
                                            viewModel.hasWorkOrderEditPermission() &&
                                            (state.workOrder?.status == WorkOrderStatus.Closed
                                                ? !viewModel.state.form.closedWorkOrderEditMode
                                                : state.formHasChanged && viewModel.isFormValid())
                                        }
                                        onClick={() =>
                                            state.workOrder?.status == WorkOrderStatus.Closed
                                                ? viewModel.ClosedWorkOrderEditModeClicked()
                                                : viewModel.handleSaveClicked()
                                        }
                                    />
                                </FormField>
                            </Grid>
                        )}
                    </GridCont>
                </PageContent>
            </LoadingIndicatorContainer>
            <PrintWorkOrderModal
                isVisible={state.workOrderToPrint !== null}
                isLoading={state.workOrderToPrintIsLoading}
                onClose={() => viewModel.workOrderToPrintChanged(null)}
                onOptionSelected={(option) => viewModel.workOrderPrintOptionSelected(option)}
                companyName={state.pdfCompanyName}
                workOrder={state.pdfWorkOrder}
                assetParts={state.pdfAssetParts}
                asset={state.pdfAsset}
                selectedPrintOption={state.selectedPrintOption}
                companyLogoUrl={state.companyLogoUrl}
            />
            <FetchDataErrorModal workOrderId={workOrderId} isVisible={state.hasDataFetchError} viewModel={viewModel} />
            <UpdateTagModal
                isVisible={state.showTagUpdate}
                data={{
                    tagChange: tagChangeFromTagType(state.form.tagStatus),
                    reason: state.form.tagReason,
                }}
                assetTag={
                    state.asset?.tag ?? {
                        type: TagType.None,
                        reason: "",
                        dateTagged: new Date(),
                        daysTagged: 0,
                    }
                }
                onConfirm={() => viewModel.onUpdateTagModalConfirmClicked()}
                onCancel={() => viewModel.onUpdateTagModalCancelClicked()}
                onNoTagChangeClicked={() => viewModel.onUpdateTagModalNoTagChangeClicked()}
                onTagChanged={(tagChange: TagChange) => viewModel.tagStatusChanged(tagChange)}
                onReasonChanged={(reason) => viewModel.tagReasonChanged(reason)}
                disableSelection={!viewModel.hasRedYellowTagAddPermission()}
                disableNoneOption={!viewModel.hasRedYellowTagDeletePermission()}
            />
            <MeterSyncModal
                isVisible={state.showSyncMeter}
                viewModel={viewModel}
                workOrderId={state.workOrder?.id ?? 0}
            />
            <MeterSyncDoneAfterServiceDateModal
                isVisible={state.showSyncDoneAfterServiceDateModal}
                viewModel={viewModel}
                workOrderId={state.workOrder?.id ?? 0}
            />
            <PartsListModal
                isLoading={state.partsListIsLoading}
                isVisible={state.showPartsList}
                viewModel={viewModel}
                parts={state.AssetParts}
            />
            <MeterSyncInfoModal
                isVisible={state.showMeterSyncInfo}
                viewModel={viewModel}
            />
            <RemoveWorkRequestModal
                workRequest={state.workRequestToRemove}
                onCancel={() => viewModel.removeWorkRequestCancelClicked()}
                onConfirm={(request) => viewModel.removeWorkRequestConfirmClicked(request)}
            />
            <WorkNotPerformedWorkRequestModal
                data={state.workRequestWorkNotPerformed}
                title={
                    state.workRequestWorkNotPerformed?.newStatus == WorkRequestStatus.Done ? "Done" : "Work Declined"
                }
                onCancel={() => viewModel.workNotPerformedReasonModalCancelClicked()}
                onReasonChanged={(workRequest, reason) => viewModel.workNotPerformedReasonChanged(workRequest, reason)}
                onConfirm={() => viewModel.workNotPerformedReasonModalSaveClicked()}
            />
            <DismissWorkRequestModal
                assetTag={state.asset?.tag ?? null}
                data={state.workRequestToDismiss}
                onCancel={() => viewModel.dismissWorkRequestCancelClicked()}
                onReasonChanged={(workRequest, reason) =>
                    viewModel.dismissWorkRequestReasonChanged(workRequest, reason)
                }
                onConfirm={() => viewModel.dismissWorkRequestConfirmClicked()}
                onTagChanged={(request, change) => viewModel.dismissWorkRequestTagChanged(request, change)}
                onTagReasonChanged={(workRequest, tagReason) =>
                    viewModel.dismissWorkRequestTagReasonChanged(workRequest, tagReason)
                }
            />

            <RollbackClosedWorkOrderModal
                data={{
                    workOrder: state.workOrder!,
                    workRequests: state.workRequestToRemoveWhenRollback,
                    meterSyncRecord: state.meterSyncRecordForRollback,
                }}
                isLoading={state.rollbackModalIsLoading}
                onCancel={() => viewModel.rollbacksWorkOrderCancelClicked()}
                onConfirm={() => viewModel.rollbackWorkOrderConfirmClicked()}
                hasRollbackWorkOrderPermission={true}
                isVisible={state.rollbackModalVisible}
                canPerformRollBack={state.rollbackModalCanPerformRollback}
            />
            <DismissWorkOrderModal
                data={
                    state.workOrderDismissal === null
                        ? null
                        : {
                              workRequests: state.workRequests,
                              reason: state.workOrderDismissal.reason,
                              workRequestsToDelete: state.workOrderDismissal.workRequestsToDelete,
                          }
                }
                onCancel={() => viewModel.dismissWorkOrderCancelClicked()}
                onConfirm={(reason, requestToDismiss) =>
                    viewModel.dismissWorkOrderConfirmClicked(reason, requestToDismiss)
                }
                onReasonChanged={(value) => viewModel.dismissWorkOrderReasonChanged(value)}
                onWorkRequestsToDismissChanged={(requestId, shouldDismiss) =>
                    viewModel.dismissWorkOrderWorkRequestsToDismissChanged(requestId, shouldDismiss)
                }
                hasDismissWorkRequestPermission={viewModel.hasWorkRequestDeletePermission()}
            />
            <WorkRequestDetailsModal workRequestId={state.workRequestDetailsModal} viewModel={viewModel} />
            {state.renderCreateServiceRequest && (
                <ServiceRequestCreationModal
                    onClose={() => viewModel.workOrderBloc.renderCreateServiceRequest(false)}
                    parentWorkOrderId={state.workOrder?.id}
                    asset={state.asset ?? undefined}
                />
            )}            
            <AdjustServiceRequestsModal isVisible={state.adjustServiceRequestModalVisible}  workPerformed={state.workRequests.map(x => labelForWorkRequest(x))} scheduleWorkRequests={state.workRequestToBeAdjustedBySchedule} onClose={() =>{ viewModel.closeAdjustServiceRequestModal() }} onAdjustServiceRequestModal={viewModel.adjustServiceRequests} />
        </Page>
    )
}

function MeterSyncModal({
    workOrderId,
    isVisible,
    viewModel,
}: {
    workOrderId: number
    isVisible: boolean
    viewModel: WorkOrderScreenViewModel
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Meter Sync</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    The variance between the Smart Hub meter and the physical meter is outside the expected range. A
                    meter sync is recommended.
                </ModalBody>
                <ModalButtons>
                    <Button label={"Cancel"} onClick={() => viewModel.onMeterSyncModalCloseClicked()} />
                    <Button
                        label={"Close Work Order Without Syncing"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.onMeterSyncModalCloseWithoutSyncClicked()}
                    />
                    <Button
                        label={"Perform Meter Sync Now"}
                        onClick={() => viewModel.onMeterSyncModalPerformSyncClicked()}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function MeterSyncDoneAfterServiceDateModal({
    workOrderId,
    isVisible,
    viewModel,
}: {
    workOrderId: number
    isVisible: boolean
    viewModel: WorkOrderScreenViewModel
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Meter Sync</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    A meter sync with a more recent Service Date has already been performed. No meter sync will be done
                    as part of closing this work order.
                </ModalBody>
                <ModalButtons>
                    <Button label={"Close"} onClick={() => viewModel.onCloseMeterSyncDoneAfterServiceDateModal()} />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function PartsListModal({
    isLoading,
    isVisible,
    viewModel,
    parts,
}: {
    isLoading: boolean
    isVisible: boolean
    viewModel: WorkOrderScreenViewModel
    parts: AssetPart[] | undefined
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} onClose={() => viewModel.partListViewCloseButtonClicked()}>
            <ModalContent isLoading={isLoading}>
                <ModalHeader>
                    <ModalHeaderTitle>Parts List</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1} gap={GridGap.Small}>
                        <ul className="parts-list-ul">
                            {parts?.map((el, i) => (
                                <li>{el.description + " (" + el.partNumber + ")"}</li>
                            ))}
                        </ul>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button
                        label={"Close"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.partListViewCloseButtonClicked()}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function MeterSyncInfoModal({
    isVisible,
    viewModel,
}: {
    isVisible: boolean
    viewModel: WorkOrderScreenViewModel
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} onClose={() => viewModel.meterSyncInfoCloseButtonClicked()}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Automatic Meter Syncs</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1} gap={GridGap.Small}>
                    Meter variances will automatically be adjusted when the work order is closed.
                    <br/>
                    <br/>
                    If there is a large variance ({'>'} 25 hours or 500 miles), the meter will not be adjusted automatically.  Large variances should be verified and manual synced, if necessary.
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button
                        label={"Close"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.meterSyncInfoCloseButtonClicked()}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function FetchDataErrorModal({
    workOrderId,
    isVisible,
    viewModel,
}: {
    workOrderId: string
    isVisible: boolean
    viewModel: WorkOrderScreenViewModel
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Error Loading Work Order</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    There was an error loading the work order. Please retry or return to the work orders list.
                </ModalBody>
                <ModalButtons>
                    <Button
                        label={"View Work Orders"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.viewWorkOrdersClicked()}
                    />
                    <Button label={"Retry"} onClick={() => viewModel.onMounted(workOrderId)} />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function WorkOrderWorkRequestsTable({
    workRequests,
    workRequestForms,
    viewModel,
}: {
    workRequests: Record<number, WorkRequest>
    workRequestForms: Record<number, WorkOrderWorkRequestForm>
    viewModel: WorkOrderScreenViewModel
}): JSX.Element {
    const isMobile = useIsMobile()
    const head = [
        { size: isMobile ? TableColumnSize.FitContentGrow : TableColumnSize.Medium, content: "Work to be Performed" },
        { size: TableColumnSize.MinContent, content: "Status" },
        ...(!isMobile
            ? [
                  { size: TableColumnSize.FitContent, content: "Service Code" },
                  { size: TableColumnSize.FitContent, content: "Act. Labor Hours" },
                  { size: TableColumnSize.FitContent, content: "Act. Labor Cost" },
                  { size: TableColumnSize.FitContent, content: "Act. Parts Cost" },
                  { size: TableColumnSize.FitContent, content: "Total Act. Cost" },
                  { size: TableColumnSize.FitContent },
              ]
            : [{ size: TableColumnSize.FitContent }]),
    ]

    const totals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        totalCost: 0,
    }

    const data: CollapsibleTableRow[] = [
        ...Object.values(workRequests).map((it, i) => {
            const form = workRequestForms[it.id]
            if (form === undefined) return { entryId: it.id, columns: [], isCollapsed: true, collapsibleContent: null }

            totals.laborHours += safeParseFloat(form.actualLaborHours)
            totals.laborCost += safeParseFloat(form.actualLaborCost)
            totals.partsCost += safeParseFloat(form.actualPartsCost)
            totals.totalCost += safeParseFloat(form.actualTotalCost)

            return {
                entryId: it.id,
                columns: [
                    {
                        onClick: () => viewModel.showWorkRequestDetails(it.id),
                        content: (
                            <div style={{ color: Color.Action, cursor: "pointer" }}>{labelForWorkRequest(it)}</div>
                        ),
                    },
                    {
                        onClick: () => viewModel.showWorkRequestDetails(it.id),
                        content: (
                            <>
                                <div
                                    className={
                                        form.workRequestStatusFlags.length > 0
                                            ? "work-request-status-with-flags-col"
                                            : "work-request-status-without-flags"
                                    }
                                >
                                    <>
                                        <span className="work-request-status-container-read-only">
                                            <WorkRequestStatusIndicator
                                                status={form.status}
                                                flagged={
                                                    form.workRequestStatusFlags &&
                                                    form.workRequestStatusFlags.length > 0
                                                }
                                            />
                                        </span>
                                        <span className="work-request-status-text-with-flags">
                                            {labelForWorkRequestStatus(form.status)}
                                        </span>
                                    </>
                                    {form.workRequestStatusFlags.map((flag: WorkRequestStatusFlag, i) => (
                                        <span key={i} className="work-request-flags-item">
                                            {flag.description}
                                        </span>
                                    ))}
                                </div>
                            </>
                        ),
                        className: "work-request-status-link",
                    },
                    ...(!isMobile
                        ? [
                              {
                                  content: it.serviceCodeId ? (
                                      <>
                                          <div className="service-code-read-container">
                                              {labelForServiceCode(
                                                  viewModel.state.serviceCodes.find(
                                                      (x: ServiceCode) => x.id == it.serviceCodeId
                                                  ) ?? viewModel.state.serviceCodes[0]
                                              )}
                                          </div>
                                      </>
                                  ) : (
                                      <>
                                          <label></label>
                                      </>
                                  ),
                              },
                              {
                                  content: (
                                      <TextInput
                                          type={TextInputType.Float}
                                          value={form.actualLaborHours}
                                          min={0}
                                          isEnabled={
                                              (viewModel.state.workOrder?.status != WorkOrderStatus.Closed
                                                  ? true
                                                  : viewModel.state.form.closedWorkOrderEditMode) &&
                                              viewModel.hasWorkOrderEditPermission()
                                          }
                                          onChange={(value) => viewModel.requestActualLaborHoursChanged(it.id, value)}
                                          onBlur={() => viewModel.saveRequestForm(it.id)}
                                      />
                                  ),
                              },
                              {
                                  content: (
                                      <TextInput
                                          type={TextInputType.Money}
                                          value={form.actualLaborCost}
                                          isEnabled={
                                              (viewModel.state.workOrder?.status != WorkOrderStatus.Closed
                                                  ? true
                                                  : viewModel.state.form.closedWorkOrderEditMode) &&
                                              viewModel.hasWorkOrderEditPermission()
                                          }
                                          onChange={(value) => viewModel.requestActualLaborCostChanged(it.id, value)}
                                          onBlur={() => viewModel.saveRequestForm(it.id)}
                                      />
                                  ),
                              },
                              {
                                  content: (
                                      <TextInput
                                          type={TextInputType.Money}
                                          value={form.actualPartsCost}
                                          isEnabled={
                                              (viewModel.state.workOrder?.status != WorkOrderStatus.Closed
                                                  ? true
                                                  : viewModel.state.form.closedWorkOrderEditMode) &&
                                              viewModel.hasWorkOrderEditPermission()
                                          }
                                          onChange={(value) => viewModel.requestActualPartsCostChanged(it.id, value)}
                                          onBlur={() => viewModel.saveRequestForm(it.id)}
                                      />
                                  ),
                              },
                              { content: `$ ${form.actualTotalCost}` },
                              {
                                  className: "work-order-requests-table-row-actions",
                                  content:
                                      Object.keys(workRequests).length > 0 &&
                                      viewModel.state.workOrder?.status != WorkOrderStatus.Closed ? (
                                          <ButtonCont>
                                              {Object.keys(workRequests).length > 1 &&
                                                  viewModel.hasWorkRequestDeletePermission() && (
                                                      <RemoveButton
                                                          onClick={() => viewModel.removeWorkRequestClicked(it)}
                                                          tooltip="Remove Service Request"
                                                      />
                                                  )}
                                              {it.followUpWorkRequestId == null &&
                                                  (viewModel.state.workRequestForms[it.id].status ==
                                                      WorkRequestStatus.WorkNotPerformed ||
                                                      (viewModel.state.workRequestForms[it.id].status ==
                                                          WorkRequestStatus.Done &&
                                                          it.tasks! &&
                                                          Object.values(it.tasks!).some(
                                                              (wr) =>
                                                                  wr.value == InspectionTaskValue.Fail ||
                                                                  wr.value == InspectionTaskValue.NotPerformed ||
                                                                  wr.value == CompletableTaskValue.NotPerformed
                                                          ))) &&
                                                  viewModel.hasWorkRequestAddPermission() && (
                                                      <CircleButton
                                                          toolTip={
                                                              "Create Follow-Up Service Request for Incomplete/Failed Tasks"
                                                          }
                                                          className="circle-button--add"
                                                          bottomLabel="Follow Up"
                                                          color={Color.Primary}
                                                          onClick={() => {
                                                              viewModel.addFollowUpWorkRequestClicked(it.id)
                                                          }}
                                                      />
                                                  )}
                                              {it.followUpWorkRequestId! &&
                                                  viewModel.hasWorkRequestViewPermission() && (
                                                      <Button
                                                          type={ButtonType.Icon}
                                                          iconUrl={ImageUrls.Note}
                                                          theme={ButtonTheme.Light}
                                                          onClick={() =>
                                                              viewModel.navigateToFollowUpWorkRequest(
                                                                  it.assetId.toString(),
                                                                  it.followUpWorkRequestId!.toString(),
                                                                  !isMobile
                                                              )
                                                          }
                                                          tooltip="View Follow Up Service Request"
                                                      />
                                                  )}
                                              {Object.keys(workRequests).length > 1 &&
                                                  viewModel.state.workRequestForms[it.id].workRequestType ==
                                                      WorkRequestType.Service &&
                                                  viewModel.hasWorkRequestDeletePermission() &&
                                                  viewModel.state.workRequestForms[it.id].status !=
                                                      WorkRequestStatus.Done && (
                                                      <Button
                                                          type={ButtonType.Icon}
                                                          iconUrl={ImageUrls.Trash}
                                                          theme={ButtonTheme.Light}
                                                          onClick={() => viewModel.dismissWorkRequestClicked(it)}
                                                          tooltip="Dismiss Service Request"
                                                      />
                                                  )}
                                          </ButtonCont>
                                      ) : (
                                          <span className="button-cont">
                                              <span className="button" />
                                              <span className="button" />
                                          </span>
                                      ),
                              },
                          ]
                        : [
                              {
                                  className: "work-order-requests-table-row-actions",
                                  content:
                                      Object.keys(workRequests).length > 0 &&
                                      viewModel.state.workOrder?.status != WorkOrderStatus.Closed ? (
                                          <ButtonCont>
                                              {it.followUpWorkRequestId == null &&
                                                  (viewModel.state.workRequestForms[it.id].status ==
                                                      WorkRequestStatus.WorkNotPerformed ||
                                                      (viewModel.state.workRequestForms[it.id].status ==
                                                          WorkRequestStatus.Done &&
                                                          it.tasks! &&
                                                          Object.values(it.tasks!).some(
                                                              (wr) =>
                                                                  wr.value == InspectionTaskValue.Fail ||
                                                                  wr.value == InspectionTaskValue.NotPerformed ||
                                                                  wr.value == CompletableTaskValue.NotPerformed
                                                          ))) &&
                                                  viewModel.hasWorkRequestAddPermission() && (
                                                      <CircleButton
                                                          toolTip={
                                                              "Create Follow-Up Service Request for Incomplete/Failed Tasks"
                                                          }
                                                          className="circle-button--add"
                                                          bottomLabel="Follow Up"
                                                          color={Color.Primary}
                                                          onClick={() => {
                                                              viewModel.addFollowUpWorkRequestClicked(it.id)
                                                          }}
                                                      />
                                                  )}
                                          </ButtonCont>
                                      ) : (
                                          <span className="button-cont">
                                              <span className="button" />
                                              <span className="button" />
                                          </span>
                                      ),
                              },
                          ]),
                ],
                isCollapsed: true,
            }
        }),
        ...(!isMobile && Object.keys(workRequests).length > 1
            ? [
                  {
                      entryId: "totals-row",
                      columns: [
                          {},
                          {},
                          {},
                          { content: Strings.formatFloat(totals.laborHours) },
                          { content: `$\u00A0\u00A0${Strings.formatMoney(totals.laborCost)}` },
                          { content: `$\u00A0\u00A0${Strings.formatMoney(totals.partsCost)}` },
                          { content: `$ ${Strings.formatMoney(totals.totalCost)}`, className: "total-row-label" },
                          {},
                      ],
                      isCollapsed: true,
                  },
              ]
            : []),
    ]

    return <CollapsibleTable className="work-order-requests-table" head={head} data={data} />
}

function WorkRequestDetailsModal({
    workRequestId,
    viewModel,
}: {
    workRequestId: number | null
    viewModel: WorkOrderScreenViewModel
}): JSX.Element {
    const memoWorkRequestId = useLastNonNull(workRequestId)
    const refModal = useRef(null)

    if (memoWorkRequestId === null) return <Modal isVisible={false} />

    const workRequest = viewModel.state.workRequests.find((it) => it.id === memoWorkRequestId)
    const form = viewModel.state.workRequestForms[memoWorkRequestId]
    if (form === undefined || workRequest === undefined) return <Modal isVisible={false} />

    let workRequestTypeString = workRequest.workRequestType.toString()
    return (
        <Modal
            isVisible={workRequestId !== null}
            useContentTag={true}
            onClose={() => viewModel.workRequestDetailsCancelClicked(memoWorkRequestId)}
            size={ModalSize.Large}
            modalRef={refModal}
        >
            <ModalContent>
                <ModalHeader>
                    <h5>{Strings.forceFirstLetterUpperCase(workRequestTypeString)}</h5>
                    <ModalHeaderTitle>{labelForWorkRequest(workRequest)}</ModalHeaderTitle>
                    <ModalHeaderSubTitle>{labelForAsset(viewModel.state.asset)}</ModalHeaderSubTitle>
                </ModalHeader>
                <ModalBody>
                    <WorkRequestDetails
                        workRequest={workRequest}
                        form={form}
                        viewModel={viewModel}
                        refModal={refModal}
                    />
                </ModalBody>
                <ModalButtons>
                    <Button
                        label="Cancel"
                        type={ButtonType.Text}
                        onClick={() => viewModel.workRequestDetailsCancelClicked(memoWorkRequestId)}
                    />
                    <Button
                        label="Save"
                        isEnabled={viewModel.isRequestFormValid(memoWorkRequestId)}
                        onClick={() => viewModel.workRequestDetailsSaveClicked(memoWorkRequestId)}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function WorkRequestDetails({
    workRequest,
    form,
    viewModel,
    refModal,
}: {
    workRequest: WorkRequest
    form: WorkOrderWorkRequestForm
    viewModel: WorkOrderScreenViewModel
    refModal?: React.RefObject<any>
}): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    return (
        <GridCont gap={GridGap.Medium}>
            {isMobile ? (
                <Grid columns={2} className="status-grid">
                    <FormField label="Status">
                        <WorkRequestStatusSelect
                            status={form.status}
                            flags={workRequest.workRequestStatusFlags}
                            onChange={(status) => viewModel.requestStatusChanged(workRequest.id, status)}
                            disabled={
                                viewModel.state.workOrder?.status == WorkOrderStatus.Closed ||
                                !viewModel.hasWorkOrderEditPermission()
                            }
                        />
                    </FormField>
                    <FormField className="modal-save-buttons-top">
                        <Button
                            label="Cancel"
                            type={ButtonType.Text}
                            onClick={() => viewModel.workRequestDetailsCancelClicked(workRequest.id)}
                        />
                        <Button
                            label="Save"
                            isEnabled={viewModel.isRequestFormValid(workRequest.id)}
                            onClick={() => viewModel.workRequestDetailsSaveClicked(workRequest.id)}
                        />
                    </FormField>
                </Grid>
            ) : (
                <Grid columns={3}>
                    <FormField label="Status">
                        <WorkRequestStatusSelect
                            status={form.status}
                            flags={workRequest.workRequestStatusFlags}
                            onChange={(status) => viewModel.requestStatusChanged(workRequest.id, status)}
                            disabled={
                                viewModel.state.workOrder?.status == WorkOrderStatus.Closed ||
                                !viewModel.hasWorkOrderEditPermission()
                            }
                        />
                    </FormField>
                    {workRequest.workRequestType === WorkRequestType.Service ? (
                         <FormField>
                       
                         { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ? 
                         <CheckBox
                             label="Unplanned Repair"
                             tooltip={"Planned repairs are typically significant repairs that are planned for in the asset's life and budgeted for in the annual budget."}
                             onCheckChanged={() => {}}
                             isChecked={workRequest.isUnplanned}
                             isEnabled={false}
                             style={{
                                 fontWeight: "bold",
                                 marginTop: "25px"
                             }}
                         /> : null }
                     </FormField>
                    ) : (
                        <FormField></FormField>
                    )}
                   
                    <FormField className="modal-save-buttons-top">
                        <Button
                            label="Cancel"
                            type={ButtonType.Text}
                            onClick={() => viewModel.workRequestDetailsCancelClicked(workRequest.id)}
                        />
                        <Button
                            label="Save"
                            isEnabled={viewModel.isRequestFormValid(workRequest.id)}
                            onClick={() => viewModel.workRequestDetailsSaveClicked(workRequest.id)}
                        />
                    </FormField>
                </Grid>
            )}
            {viewModel.isStatusFlagReasonRequired(form) && (
                <Grid columns={1}>
                    <FormField
                        label="Reason*"
                        hasRedStroke={
                            viewModel.isStatusFlagReasonRequired(form) && form.statusFlagReason == "" ? true : false
                        }
                    >
                        <TextArea
                            disabled={
                                (viewModel.state.workOrder?.status == WorkOrderStatus.Closed &&
                                    !viewModel.state.form.closedWorkOrderEditMode) ||
                                !viewModel.hasWorkOrderEditPermission()
                            }
                            maxLength={8000}
                            value={form.statusFlagReason}
                            onChange={(reason) => viewModel.workNotPerformedReasonChanged(workRequest, reason)}
                        />
                    </FormField>
                </Grid>
            )}
            <Grid columns={4}>
                {workRequest.workRequestType === WorkRequestType.Service ? (
                    <></>
                ) : (
                    <>
                        <FormField label="Step">
                            <Select
                                theme={SelectTheme.Dark}
                                isEnabled={
                                    (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                        viewModel.state.form.closedWorkOrderEditMode) &&
                                    viewModel.hasWorkOrderEditPermission()
                                }
                                options={
                                    !form.schedule
                                        ? []
                                        : form.schedule.steps
                                              .sort((a, b) => (a.step > b.step ? 1 : b.step > a.step ? -1 : 0))
                                              .map((it) => ({
                                                  label: `${it.label}`,
                                                  value: it.id.toString(),
                                              }))
                                }
                                value={form.step?.id}
                                onChange={(value) => viewModel.requestStepChanged(workRequest.id, value)}
                            />
                        </FormField>
                    </>
                )}
                <FormField label="Service Code">
                    <Select
                        options={[
                            ...[{ id: -1, value: "-1", label: "Select Service Code" }].map((s) => ({
                                key: s.id,
                                value: `${s.value}`,
                                label: s.label,
                            })),
                            ...viewModel.state.serviceCodes.map((it) => ({
                                key: it.id,
                                value: `${it.id}`,
                                label: labelForServiceCode(it),
                            })),
                        ]}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        disabled={
                            (viewModel.state.workOrder?.status == WorkOrderStatus.Closed &&
                                !viewModel.state.form.closedWorkOrderEditMode) ||
                            !viewModel.hasWorkOrderEditPermission()
                        }
                        value={form.serviceCodeId ?? ""}
                        onChange={(value) => viewModel.requestServiceCodeChanged(workRequest.id, value)}
                    />
                </FormField>
            </Grid>
            <Grid columns={4}>
                <FormField label="Urgency">
                    <Select
                        theme={SelectTheme.Normal}
                        options={Object.values(Urgency).map((it) => ({
                            label: labelForUrgency(it),
                            value: it,
                        }))}
                        value={form.urgency ?? Urgency.Medium}
                        isEnabled={
                            !isMobile &&
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onChange={(value) => viewModel.requestUrgencyChanged(workRequest.id, urgencyForValue(value))}
                    ></Select>
                </FormField>
                <FormField label="Due Date">
                    <TextInputDatePicker
                        value={form.dueDate}
                        disabled={
                            (viewModel.state.workOrder?.status == WorkOrderStatus.Closed &&
                                !viewModel.state.form.closedWorkOrderEditMode) ||
                            !viewModel.hasWorkOrderEditPermission()
                        }
                        onChange={(value) => viewModel.requestDueDateChanged(workRequest.id, value)}
                    />
                </FormField>
                <FormField label="Due Hour Meter" direction={FormFieldDirection.Column}>
                    <TextInput
                        type={TextInputType.Decimal}
                        min={0}
                        value={form.dueHourMeter != null ? Strings.formatDecimal(form.dueHourMeter) : ""}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onChange={(value) => viewModel.requestDueHourMeterChanged(workRequest.id, value)}
                    />
                    {viewModel.state.asset?.hourMeter != null && viewModel.state.asset?.hourMeter != 0 ? (
                        <FormFieldNote>
                            Current: {Strings.formatDecimal(viewModel.state.asset?.hourMeter ?? 0)}
                        </FormFieldNote>
                    ) : (
                        ""
                    )}
                </FormField>
                <FormField label="Due Odometer" direction={FormFieldDirection.Column}>
                    <TextInput
                        type={TextInputType.Integer}
                        min={0}
                        value={form.dueOdometer != null ? Strings.formatInteger(form.dueOdometer) : ""}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onChange={(value) => viewModel.requestDueOdometerChanged(workRequest.id, value)}
                    />
                    {viewModel.state.asset?.odometer != null && viewModel.state.asset?.odometer != 0 ? (
                        <FormFieldNote>
                            Current: {Strings.formatInteger(viewModel.state.asset?.odometer ?? 0)}
                        </FormFieldNote>
                    ) : (
                        ""
                    )}
                </FormField>
            </Grid>
            <Grid columns={4}>
                <FormField label="Estimated Labor Hours">
                    <TextInput
                        type={TextInputType.Float}
                        value={form.estimatedLaborHours}
                        min={0}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onChange={(value) => viewModel.requestEstimatedLaborHoursChanged(workRequest.id, value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Estimated Parts Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={form.estimatedPartsCost}
                        min={0}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onChange={(value) => viewModel.requestEstimatedPartsCostChanged(workRequest.id, value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Actual Labor Hours">
                    <TextInput
                        type={TextInputType.Float}
                        value={form.actualLaborHours}
                        onChange={(value) => viewModel.requestActualLaborHoursChanged(workRequest.id, value)}
                        min={0}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Actual Labor Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={form.actualLaborCost}
                        onChange={(value) => viewModel.requestActualLaborCostChanged(workRequest.id, value)}
                        min={0}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Actual Parts Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={form.actualPartsCost}
                        onChange={(value) => viewModel.requestActualPartsCostChanged(workRequest.id, value)}
                        min={0}
                        isEnabled={
                            (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                viewModel.state.form.closedWorkOrderEditMode) &&
                            viewModel.hasWorkOrderEditPermission()
                        }
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Total Actual Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={Strings.formatMoney(form.actualTotalCost)}
                        isEnabled={false}
                    />
                </FormField>
            </Grid>
            {workRequest.workRequestType === WorkRequestType.Service && !isMobile && (
                <Grid columns={1}>
                    <FormField label="Work to be Performed">
                        <TextArea
                            maxLength={250}
                            value={form.workToBePerformed}
                            onChange={(value) => viewModel.requestWorkToBePerformedChanged(workRequest.id, value)}
                            disabled={!viewModel.hasWorkOrderEditPermission()}
                        />
                    </FormField>
                </Grid>
            )}
            <Grid columns={1}>
                <TagFormField
                    tag={form.tag}
                    onTagTypeChanged={(type) => viewModel.requestTagTypeChanged(workRequest.id, type)}
                    onReasonChanged={(reason) => viewModel.requestTagReasonChanged(workRequest.id, reason)}
                    formFieldLabel="Current Tag Status"
                    disabled={form.tag?.type === viewModel.state.asset?.tag?.type}
                    disableSelection={
                        (viewModel.state.workOrder?.status == WorkOrderStatus.Closed &&
                            !viewModel.state.form.closedWorkOrderEditMode) ||
                        !viewModel.hasWorkOrderEditPermission() ||
                        !viewModel.hasRedYellowTagAddPermission()
                    }
                    disableNoneOption={
                        (viewModel.state.asset?.tag! && viewModel.state.asset?.tag?.type !== TagType.None) ||
                        !viewModel.hasWorkOrderEditPermission() ||
                        !viewModel.hasRedYellowTagDeletePermission()
                    }
                />
            </Grid>
            {workRequest.workRequestType !== WorkRequestType.Service && (
                <FormField
                    label={
                        <>
                            <span>Tasks to be Performed</span>
                            <Button
                                label={
                                    viewModel.state.showTaskDescriptions
                                        ? "Hide Task Descriptions"
                                        : "Show Task Descriptions"
                                }
                                type={ButtonType.Text}
                                onClick={() => viewModel.toggleShowTaskDescriptions()}
                            />
                        </>
                    }
                    direction={FormFieldDirection.Column}
                >
                    <Grid columns={1} gap={GridGap.Normal}>
                        <CheckBox
                            label={
                                workRequest.workRequestType === WorkRequestType.Inspection ? "Pass All" : "Select All"
                            }
                            isChecked={
                                form.tasks?.every(
                                    (it) =>
                                        it.value === CompletableTaskValue.Performed ||
                                        it.value === InspectionTaskValue.Pass
                                ) ?? false
                            }
                            isEnabled={
                                (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                    viewModel.state.form.closedWorkOrderEditMode) &&
                                viewModel.hasWorkOrderEditPermission()
                            }
                            onCheckChanged={() => viewModel.requestSelectAllTasksToggled(workRequest.id)}
                        />
                        <Grid columns={3} columnsMd={2} gap={GridGap.Normal}>
                            {form.tasks?.sort((a,b)=> a.label.localeCompare(b.label )).map((task) => (
                                <FormFieldGroup
                                    key={task.id}
                                    theme={FormFieldGroupTheme.Light}
                                    className="inspection-task"
                                >
                                    <Grid className="inspection-task-label" columns={1} gap={GridGap.Small}>
                                        <div>{task.label}</div>
                                        {viewModel.state.showTaskDescriptions && (
                                            <div className="task-description">{task.description}</div>
                                        )}
                                    </Grid>
                                    <Grid columns={1} gap={GridGap.Small} className="inspection-task-values">
                                        {workRequest.workRequestType === WorkRequestType.Inspection ? (
                                            <Select
                                                options={Object.values(InspectionTaskValue).map((it) => ({
                                                    label: Strings.forceFirstLetterUpperCase(it),
                                                    value: it,
                                                }))}
                                                onChange={(value) =>
                                                    viewModel.requestTasksChanged(
                                                        workRequest.id,
                                                        task.id,
                                                        inspectionTaskValueForValue(value),
                                                        task.measurement
                                                    )
                                                }
                                                isEnabled={
                                                    (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                                        viewModel.state.form.closedWorkOrderEditMode) &&
                                                    viewModel.hasWorkOrderEditPermission()
                                                }
                                                value={task.value}
                                            />
                                        ) : (
                                            // Preventative
                                            <Select
                                                options={Object.values(CompletableTaskValue).map((it) => ({
                                                    label: Strings.forceFirstLetterUpperCase(it),
                                                    value: it,
                                                }))}
                                                onChange={(value) =>
                                                    viewModel.requestTasksChanged(
                                                        workRequest.id,
                                                        task.id,
                                                        completableTaskValueForValue(value),
                                                        task.measurement
                                                    )
                                                }
                                                isEnabled={
                                                    (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                                        viewModel.state.form.closedWorkOrderEditMode) &&
                                                    viewModel.hasWorkOrderEditPermission()
                                                }
                                                value={task.value}
                                            />
                                        )}

                                        <TextInput
                                            value={task.measurement ?? ""}
                                            placeholder="Measurement"
                                            onChange={(value) =>
                                                viewModel.requestTasksChanged(
                                                    workRequest.id,
                                                    task.id,
                                                    task.value,
                                                    value
                                                )
                                            }
                                            isEnabled={
                                                (viewModel.state.workOrder?.status != WorkOrderStatus.Closed ||
                                                    viewModel.state.form.closedWorkOrderEditMode) &&
                                                viewModel.hasWorkOrderEditPermission()
                                            }
                                        />
                                    </Grid>
                                </FormFieldGroup>
                            ))}
                        </Grid>
                    </Grid>
                </FormField>
            )}
            <Grid columns={1}>
                <FormField label="Mechanic's Notes">
                    <TextArea
                        maxLength={1000}
                        value={form.notes}
                        onChange={(value) => viewModel.requestNotesChanged(workRequest.id, value)}
                        disabled={
                            (viewModel.state.workOrder?.status == WorkOrderStatus.Closed &&
                                !viewModel.state.form.closedWorkOrderEditMode) ||
                            !viewModel.hasWorkOrderEditPermission()
                        }
                    />
                </FormField>
            </Grid>
            {workRequest.workRequestType === WorkRequestType.Service && !isMobile && (
                <>
                    <Grid columns={1}>
                        <FormField label="Notify Contacts">
                            <MultiSelect
                                options={viewModel.state.contacts
                                    .filter((it) => !it.deleted || it.id.toString() === viewModel.state.form.workPerformedById?.toString() || it.id.toString() === viewModel.state.form.assignedTo?.toString())
                                    .map((contact) => ({
                                    key: contact.id,
                                    value: `${contact.id}`,
                                    label: labelForContact(contact),
                                    isChecked: form.notifyContacts.includes(contact.id),
                                    checkAll: false,
                                }))}
                                onSelectionChanged={(selection) => {
                                    viewModel.requestNotifyContactChanged(
                                        workRequest.id,
                                        selection.map((contactId) => safeParseInt(contactId))
                                    )
                                }}
                            />
                        </FormField>
                    </Grid>
                    <Grid columns={4}>
                        <FormField label="Created By">
                            <TextInput value={workRequest.createdByName} isEnabled={false} />
                        </FormField>
                    </Grid>
                </>
            )}
            <Grid columns={1}>
                <AttachmentList
                    attachments={form.attachments ?? []}
                    onAttachmentsChanged={(files, formData) => viewModel.workRequestAttachmentsChanged(files, formData)}
                    onAttachmentDeleteConfirm={(attachment) => viewModel.workRequestAttachmentDeleted(attachment)}
                    onImageRotate90={(attachment) => viewModel.rotateImage(attachment, 90)}
                    onError={(errors) => viewModel.attachmentError(errors)}
                    disabled={!viewModel.hasWorkOrderEditPermission()}
                    onCloseRef={refModal}
                />
            </Grid>
            <Grid columns={1}>
                <CollapsibleFormField
                    label="Repair Request History"
                    isLoading={viewModel.state.effectStatus[WorkOrderEffect.FetchWorkRequestHistory].isBusy()}
                    onClick={() => {
                        if (workRequest) viewModel.loadWorkRequestHistory(workRequest?.id)
                    }}
                    isCollapsed={true}
                >
                    <HistoryTable history={workRequest.history ?? []} statusLabelCreator={(status: string) => status} />
                </CollapsibleFormField>
            </Grid>
        </GridCont>
    )
}
