import { useBlocCoordinator } from "@lib/bloc/hooks"
import { Link } from "@lib/router/components"
import { useRoute } from "@lib/router/hooks"
import { Strings } from "@lib/Strings"
import { safeParseInt } from "@lib/TypeUtil"
import { Asset } from "@model/assets/Asset"
import { Color } from "@model/Color"
import { TableColumn, TableColumnAlignment, TableColumnSize, TableRow } from "@model/tables/Table"
import { colorForUrgency, labelForUrgency } from "@model/Urgency"
import { PermissionObject, PermissionType } from "@model/user/User"
import { labelForWorkOrderStatus, WorkOrder, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { AssetWorkRequestsTableColumnDefinitions } from "@model/workRequests/AssetWorkRequestsTableColumnDefinitions"
import {
    AssetWorkRequestsTableColumn,
    labelForWorkRequest,
    labelForWorkRequestType,
    WorkRequest,
    WorkRequestType,
} from "@model/workRequests/WorkRequest"
import { Button, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { CircleButton } from "@ui/common/buttons/CircleButton"
import { DataField } from "@ui/common/DataField"
import { FlagChip } from "@ui/common/FlagChip"
import { CheckBox } from "@ui/common/form/CheckBox"
import { ImageUrls } from "@ui/common/images/ImageUrls"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import { Modal, ModalContent, ModalHeader, ModalHeaderTitle, ModalBody, ModalButtons } from "@ui/common/Modal"
import { Page } from "@ui/common/page/Page"
import { PageInfoSection, PageInfoSectionDataFields } from "@ui/common/page/PageInfoSection"
import { PageToolbar, PageToolbarButtons } from "@ui/common/page/PageToolbar"
import { Table } from "@ui/common/tables/Table"
import { Tag } from "@ui/common/Tag"
import { DI } from "@ui/DI"
import { ExternalRoutes, Routes } from "@lib/Routes"
import { CreateWorkOrderModal } from "@ui/workOrders/CreateWorkOrderModal"
import React, { useEffect } from "react"
import { AddWorkRequestToWorkOrderModal } from "./AddWorkRequestToWorkOrderModal"
import { AssetLocation } from "./AssetLocation"
import { AssetWorkRequestsViewModel } from "./AssetWorkRequestsViewModel"
import { DismissWorkRequestModal } from "./DismissWorkRequestModal"
import { RollbackWorkRequestModal } from "./RollbackWorkRequestModal"
import { WorkRequestDetailsModal } from "./WorkRequestDetailsModal"
import { ServiceRequestCreationModal } from "@ui/workRequests/ServiceRequestCreationModal"

export function AssetWorkRequestsScreen(): JSX.Element {
    const route = useRoute()
    const assetId = route.data.params.assetId
    const [state, viewModel] = useBlocCoordinator(() => DI.assetWorkRequestsViewModel())

    useEffect(() => {
        viewModel.onMounted(safeParseInt(assetId))
    }, [])

    const selectedWorkRequests = state.workRequests.filter((it) => state.selectedWorkRequests.includes(it.id))

    return (
        <Page className={"page--asset-work-requests"}>
            <PageToolbar>
                <PageToolbarButtons>
                    {!state.isLoading && (
                        <CircleButton
                            className="circle-button--add"
                            bottomLabel="Repair"
                            color={Color.Transparent}
                            toolTip={"Add Repair Request"}
                            onClick={() => {
                                viewModel.showCreateWorkRequestModal()
                            }}
                        />
                    )}
                    {!state.isLoading && state.workOrders.length > 0 && viewModel.hasWorkOrderEditPermission() && (
                        <Button
                            type={ButtonType.Text}
                            theme={ButtonTheme.Light}
                            label="+ Add to Work Order"
                            isEnabled={state.selectedWorkRequests.length > 0}
                            onClick={() => viewModel.showAddToWorkOrderModal()}
                        />
                    )}
                    {!state.isLoading && viewModel.hasWorkOrderAddPermission() && (
                        <Button
                            type={ButtonType.Contained}
                            label="Create Work Order"
                            isEnabled={state.selectedWorkRequests.length > 0}
                            onClick={() => viewModel.showCreateWorkOrderModal()}
                        />
                    )}
                </PageToolbarButtons>
            </PageToolbar>
            <LoadingIndicatorContainer>
                <LoadingIndicator isLoading={state.isLoading} />
                <PageInfoSection
                    title={
                        <>
                            <a href={ExternalRoutes.SmartHubAsset(state.asset?.id!)} target="_blank">
                                {state.asset?.identifier}
                            </a>
                            {state.asset?.tag && <Tag tag={state.asset.tag} />}
                        </>
                    }
                >
                    <PageInfoSectionDataFields>
                        <DataField label="Make" value={state.asset?.make.name} />
                        <DataField label="Model" value={state.asset?.model.name} />
                        <DataField label="Year" value={state.asset?.modelYear} />
                        <DataField label="VIN" value={state.asset?.serialNumber} />
                        <DataField label="Category" value={state.asset?.category.name} />
                        <DataField label="Class" value={state.asset?.class?.name ?? ""} />
                        <DataField
                            label="Billing Code"
                            value={state.asset?.billingCodeCode + " " + state.asset?.billingCodeName}
                        />
                        <DataField
                            label="Existing Work Orders"
                            value={WorkOrderColumn(
                                state.workOrders,
                                state.asset,
                                viewModel.hasWorkOrderViewPermission()
                            )}
                        />
                        <DataField
                            label="Last Known Location"
                            value={
                                <AssetLocation
                                    location={state.asset?.location ?? null}
                                    site={state.asset?.site ?? null}
                                    hasLinks={true} 
                                />
                            }
                        />
                        {(state.asset?.hourMeter ?? null) !== null && (
                            <DataField
                                label={`Hour Meter${
                                    state.asset?.lifetimeHourMeter ?? null ? " / Lifetime Hour Meter" : ""
                                }`}
                                value={`${Strings.formatDecimal(state.asset?.hourMeter ?? 0)}${
                                    state.asset?.lifetimeHourMeter
                                        ? ` / ${Strings.formatDecimal(state.asset.lifetimeHourMeter ?? 0)}`
                                        : ""
                                }`}
                            />
                        )}
                        {(state.asset?.odometer ?? null) !== null && (
                            <DataField
                                label={`Odometer${state.asset?.lifetimeOdometer ?? null ? " / Lifetime Odometer" : ""}`}
                                value={`${Strings.formatInteger(state.asset?.odometer ?? 0)}${
                                    state.asset?.lifetimeOdometer
                                        ? ` / ${Strings.formatInteger(state.asset.lifetimeOdometer ?? 0)}`
                                        : ""
                                }`}
                            />
                        )}
                    </PageInfoSectionDataFields>
                </PageInfoSection>
                <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 515px)" }}>
                    <Table
                        className={"page--asset-work-requests--multi-table"}
                        head={createTableHead(viewModel)}
                        data={createTableBody(
                            state.workRequests.filter((x) => !x.workOrderId),
                            viewModel,
                            viewModel.hasWorkRequestDeletePermission(),
                            state.isRollbackMode
                        )}
                    />
                    {state.workRequests.filter((x) => x.workOrderId).length > 0 ? 
                    <Table
                        className={"page--asset-work-requests--multi-table"}
                        head={createTableHeadForAssignedWR(viewModel)}
                        data={createTableBody(
                            state.workRequests.filter((x) => x.workOrderId),
                            viewModel,
                            viewModel.hasWorkRequestDeletePermission(),
                            state.isRollbackMode
                        )}
                    />
                    : <></> } 
                </div>
                <WorkRequestDetailsModal />
                <DismissWorkRequestModal
                    data={state.workRequestToDismiss}
                    assetTag={state.asset?.tag ?? null}
                    onCancel={() => viewModel.dismissWorkRequestCancelClicked()}
                    onConfirm={() => viewModel.dismissWorkRequestConfirmClicked()}
                    onReasonChanged={(request, reason) => viewModel.dismissWorkRequestReasonChanged(request, reason)}
                    onTagChanged={(request, change) => viewModel.dismissWorkRequestTagChanged(request, change)}
                    onTagReasonChanged={(request, tagReason) =>
                        viewModel.dismissWorkRequestTagReasonChanged(request, tagReason)
                    }
                />
            </LoadingIndicatorContainer>

            <FetchDataErrorModal isVisible={state.hasDataFetchError} assetId={assetId} viewModel={viewModel} />
            {state.createWorkRequestModalVisible && (
                <ServiceRequestCreationModal
                    onClose={() => viewModel.hideCreateWorkRequestModal()}
                    asset={state.asset ?? undefined}
                />
            )}
            <AddWorkRequestToWorkOrderModal
                isVisible={state.addToWorkOrderModalVisible}
                workOrders={state.workOrders}
                isSaving={viewModel.isAddingWorkRequestsToWorkOrder()}
                workRequests={selectedWorkRequests}
                workOrderRequests={state.workOrderRequests}
                onAddClicked={(workOrder, form) => viewModel.addWorkRequestsToWorkOrder(workOrder, form)}
                onCancelClicked={() => viewModel.hideAddToWorkOrderModal()}
            />
            <CreateWorkOrderModal
                isVisible={state.createWorkOrderModalVisible}
                workRequests={selectedWorkRequests}
                onCancelClicked={() => viewModel.hideCreateWorkOrderModal()}
                onCreateClicked={(workOrder) => viewModel.createWorkOrder(workOrder)}
                user={state.user}
            />
            <RollbackWorkRequestModal
                isVisible={state.rollbackModalVisible}
                onCancel={() => viewModel.cancelRollBackModal()}
                onConfirm={() => viewModel.confirmRollBackModal()}
            />
        </Page>
    )
}

function FetchDataErrorModal({
    assetId,
    isVisible: hasFetchError,
    viewModel,
}: {
    assetId: string
    isVisible: boolean
    viewModel: AssetWorkRequestsViewModel
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={hasFetchError} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Error Loading Service Requests</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    There was an error loading service requests. Please retry or return to the service requests list.
                </ModalBody>
                <ModalButtons>
                    <Button
                        label={"View Service Requests"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.viewWorkRequestsClicked()}
                    />
                    <Button label={"Retry"} onClick={() => viewModel.onMounted(safeParseInt(assetId))} />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function createTableHeadForAssignedWR(viewModel: AssetWorkRequestsViewModel): TableColumn[] {
    return Object.values(AssetWorkRequestsTableColumnDefinitions)
        .map<TableColumn>((def) => ({
            label: def.label,
            alignment: def.alignment,
            size: def.size,
            content:
                def.type === AssetWorkRequestsTableColumn.Name ? (
                    <div className="table-cell--work-requests-name">
                        <CheckBox isChecked={false} style={{ visibility: "hidden" }} onCheckChanged={() => null} />
                        Assigned Service Requests
                    </div>
                ) : (
                    def.label
                ),
        }))
        .concat({ size: TableColumnSize.FitContent, alignment: TableColumnAlignment.Start })
}

function createTableHead(viewModel: AssetWorkRequestsViewModel): TableColumn[] {
    return Object.values(AssetWorkRequestsTableColumnDefinitions)
        .map<TableColumn>((def) => ({
            label: def.label,
            alignment: def.alignment,
            size: def.size,
            content:
                def.type === AssetWorkRequestsTableColumn.Name ? (
                    <div className="table-cell--work-requests-name">
                        <CheckBox
                            isChecked={viewModel.state.isCheckAllChecked}
                            onCheckChanged={() => viewModel.checkAllCheckboxClicked()}
                        />
                        Unassigned Service Requests
                    </div>
                ) : (
                    def.label
                ),
        }))
        .concat({ size: TableColumnSize.FitContent, alignment: TableColumnAlignment.Start })
}

function createTableBody(
    workRequests: WorkRequest[],
    viewModel: AssetWorkRequestsViewModel,
    hasDeletePermission: boolean,
    hasRollbackPermission: boolean
): TableRow[] {
    return workRequests.map((workRequest) => ({
        entryId: workRequest.id,
        columns: Object.values(AssetWorkRequestsTableColumn)
            .map(
                (column): TableColumn => ({
                    content: createColumnContent(column, workRequest, viewModel),
                    alignment: AssetWorkRequestsTableColumnDefinitions[column].alignment,
                })
            )
            .concat({
                content: (
                    <ActionsColumn
                        workRequest={workRequest}
                        onClick={() => viewModel.dismissWorkRequestClicked(workRequest)}
                        hasDeletePermission={hasDeletePermission}
                        hasRollbackPermission={hasRollbackPermission}
                        isAssetNotLinkedToSchedule={viewModel.isAssetNotLinkedToSchedule(workRequest.step?.id)}
                    />
                ),
                noHoverBackground: true,
            }),
    }))
}



function createColumnContent(
    type: AssetWorkRequestsTableColumn,
    workRequest: WorkRequest,
    viewModel: AssetWorkRequestsViewModel
): JSX.Element | string | null {
    switch (type) {
        case AssetWorkRequestsTableColumn.DaysUntil:
            return (
                <div
                    style={workRequest.daysUntil && workRequest.daysUntil < 0 ? { color: "var(--color-invalid)" } : {}}
                >
                    {workRequest.dueDate != null && workRequest.daysUntil != null
                        ? Strings.formatInteger(workRequest.daysUntil)
                        : "-"}
                </div>
            )
        case AssetWorkRequestsTableColumn.HoursUntil:
            return (
                <div
                    style={
                        workRequest.hoursUntil && workRequest.hoursUntil < 0 ? { color: "var(--color-invalid)" } : {}
                    }
                >
                    {workRequest.dueHourMeter != null && workRequest.hoursUntil != null
                        ? Strings.formatInteger(workRequest.hoursUntil)
                        : "-"}
                </div>
            )
        case AssetWorkRequestsTableColumn.MilesUntil:
            return (
                <div
                    style={
                        workRequest.milesUntil && workRequest.milesUntil < 0 ? { color: "var(--color-invalid)" } : {}
                    }
                >
                    {workRequest.dueOdometer != null && workRequest.milesUntil != null
                        ? Strings.formatInteger(workRequest.milesUntil)
                        : "-"}
                </div>
            )
        case AssetWorkRequestsTableColumn.Name:
            return (
                <div className="table-cell--work-requests-name">
                    {
                        <CheckBox
                            style={workRequest.workOrderId ? { visibility: "hidden" } : {}}
                            isChecked={viewModel.state.selectedWorkRequests.includes(workRequest.id)}
                            onCheckChanged={() => viewModel.workRequestCheckboxClicked(workRequest.id)}
                        />
                    }
                    {!workRequest.workOrderId && (
                        <Link
                            className="table-cell--work-requests-name-link"
                            to={Routes.WorkRequest(workRequest.assetId.toString(), workRequest.id.toString())}
                        >
                            {"Service " + workRequest.id + " - " + labelForWorkRequest(workRequest)}
                        </Link>
                    )}
                    {workRequest.workOrderId && (
                        <a
                            className="table-cell--work-requests-name-link"
                            target="_blank"
                            href={window.location.origin + "/work-orders/" + workRequest.workOrderId.toString()}
                        >
                            {"Service " + workRequest.id + " - " + labelForWorkRequest(workRequest)}
                        </a>
                    )}
                </div>
            )
        case AssetWorkRequestsTableColumn.Tag:
            if (!workRequest.tag) return null
            return <Tag tag={workRequest.tag} />
        case AssetWorkRequestsTableColumn.Status:
            if (workRequest.workOrderId) {
                return (
                    <div className="table-cell--work-requests-name">
                        <a
                            className="table-cell--work-requests-name-link"
                            target="_blank"
                            href={window.location.origin + "/work-orders/" + workRequest.workOrderId.toString()}
                        >
                            {viewModel.state.workOrders
                                .find((x) => x.id === workRequest.workOrderId)
                                ?.number?.toString()}
                        </a>
                    </div>
                )
            } else {
                return (
                    <div className="table-cell--work-requests-name">
                        {workRequest.isOverdue ? "Overdue" : workRequest.isUpcoming ? "Upcoming" : "Unassigned"}
                    </div>
                )
            }
        case AssetWorkRequestsTableColumn.Type:
            return labelForWorkRequestType(workRequest.workRequestType)
        case AssetWorkRequestsTableColumn.Urgency:
            return (
                <FlagChip
                    flags={[
                        {
                            label: labelForUrgency(workRequest.urgency),
                            color: colorForUrgency(workRequest.urgency),
                        },
                    ]}
                />
            )
    }
}

function ActionsColumn({
    onClick,
    workRequest,
    hasDeletePermission,
    hasRollbackPermission,
    isAssetNotLinkedToSchedule
}: {
    workRequest: WorkRequest
    onClick: () => void
    hasDeletePermission: boolean
    hasRollbackPermission: boolean
    isAssetNotLinkedToSchedule: boolean
}): JSX.Element {
    let showTrashIcon =
        ((workRequest.workRequestType === WorkRequestType.Service && hasDeletePermission) ||
            ((workRequest.workRequestType === WorkRequestType.Inspection ||
                workRequest.workRequestType === WorkRequestType.Preventative) &&
                (hasRollbackPermission || isAssetNotLinkedToSchedule))) &&
        !workRequest.workOrderId
    return (
        <>
            {
                <div style={showTrashIcon ? {} : { visibility: "hidden" }}>
                    <Button
                        type={ButtonType.Icon}
                        iconUrl={ImageUrls.Trash}
                        theme={ButtonTheme.Light}
                        onClick={onClick}
                        tooltip="Dismiss Service Request"
                    >
                        Dismiss Service Request
                    </Button>
                </div>
            }
        </>
    )
}

const WorkOrderColumn = (workOrders: WorkOrder[], asset: Asset | null, hasViewPermission: boolean) =>
    !workOrders ? null : (
        <div className="table-cell--work-request-work-order__value">
            {workOrders
                .filter((x) => ![WorkOrderStatus.Closed, WorkOrderStatus.Deleted].includes(x.status))
                .slice(0, 4)
                .map((it) => (
                    <div className="work-request-work-order" key={it.id}>
                        {hasViewPermission ? (
                            <Link to={Routes.WorkOrder(it.id.toString())}>{it.number}</Link>
                        ) : (
                            it.number
                        )}
                        <div className="work-order-status-label">{labelForWorkOrderStatus(it.status)}</div>
                    </div>
                ))}
            {workOrders.filter((x) => ![WorkOrderStatus.Closed, WorkOrderStatus.Deleted].includes(x.status)).length >
                4 && asset
                ? plusIconLink(asset)
                : ""}
        </div>
    )

const getAssetFilter = (asset: Asset) => {
    return JSON.stringify({ assetName: asset.label })
}
const plusIconLink = (asset: Asset) => (
    <Link title="Show all Work Orders" to={Routes.WorkOrders(getAssetFilter(asset))}>
        +
    </Link>
)
