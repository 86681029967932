import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { withRouter } from "@lib/router/router"
import { Routes, smartHubHost } from "@lib/Routes"
import { Strings } from "@lib/Strings"
import { Asset } from "@model/assets/Asset"
import { Attachment } from "@model/Attachment"
import { Contact } from "@model/contacts/Contact"
import { RedirectData } from "@model/RedirectData"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { ServiceCode, ServiceRequestType, ServiceSubType } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { TagType } from "@model/Tag"
import { Urgency } from "@model/Urgency"
import { PermissionObject, PermissionType } from "@model/user/User"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { WorkOrderScreenBloc } from "@state/workOrders/WorkOrderScreenBloc"
import { AssetWorkRequestsBloc } from "@state/workRequests/AssetWorkRequestsBloc"
import { ServiceRequestCreationBloc, ServiceRequestCreationState } from "@state/workRequests/ServiceRequestCreationBloc"
import { WorkRequestsTableBloc } from "@state/workRequests/WorkRequestsTableBloc"

type Dependencies = [ServiceRequestCreationState, CompanyState, UserState]
type StateSelection = ServiceRequestCreationState & {
    contacts: Contact[]
    mechanics: Contact[]
    serviceCodes: ServiceCode[]
    allSites: Site[]
    serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    afterSaveDelay: number
}

export class ServiceRequestCreationViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private serviceRequestCreationBloc: ServiceRequestCreationBloc,
        private workRequestsTableBloc: WorkRequestsTableBloc,
        private workOrderWorkRequestsTableBloc: WorkOrderScreenBloc,
        private assetWorkRequestsBloc: AssetWorkRequestsBloc,
        private companyBloc: CompanyBloc,
        private userBloc: UserBloc,
        private statusBloc: StatusBloc
    ) {
        super([serviceRequestCreationBloc, companyBloc, userBloc])
    }

    protected transform = ([serviceRequestCreationState, companyState, userState]: Dependencies): StateSelection => ({
        ...serviceRequestCreationState,
        contacts: companyState.contacts,
        serviceCodes: Object.values(companyState.serviceCodes)
        .sort((a, b) => a.code.localeCompare(b.code)),
        mechanics: companyState.settings.mechanics,
        allSites: companyState.allSites,
        serviceSubTypes: companyState.settings.serviceSubTypes,
        afterSaveDelay: 5000,
    })

    cancel = (navigateToAssetWorkRequests: boolean, redirectData: RedirectData | null) => {
        if (this.state.callerFromMobileApp) {
            window?.parent?.postMessage(
                {
                    eventName: "serviceRequestCreateCancelIframeMessage",
                    caller: this.state.callerFromMobileApp,
                    data: null,
                },
                smartHubHost
            )
        } else {
            let hideModalAndClearForm = true
            if (redirectData) {
                this.navigateTo(redirectData)
                if (redirectData.isExternal) {
                    hideModalAndClearForm = false
                }
            } else if (navigateToAssetWorkRequests) {
                this.navigateToAssetWorkRequests()
            }
            if (hideModalAndClearForm) {
                this.serviceRequestCreationBloc.clearAttachmentsOnCancel()
                this.serviceRequestCreationBloc.hideModalAndResetForm()
            }
        }
    }

    navigateToAssetWorkRequests = () => {
        withRouter((router) => {
            if (this.state.selectedAsset) {
                //this needs to be called before hideModalAndResetForm() to get the this.state.selectedAsset.id
                router.navigate(Routes.AssetWorkRequests(this.state.selectedAsset.id.toString()), true)
            }
        })
    }

    navigateTo = (redirectData: RedirectData) => {
        const { url, isExternal } = redirectData
        if (isExternal || url.startsWith("http://") || url.startsWith("https://")) {
            window.location.href = url
        } else {
            const internalUrl = url.startsWith("/") ? url : `/${url}`
            withRouter((router) => {
                router.navigate(internalUrl, true)
            })
        }
    }

    createServiceRequestButtonClicked = async (
        navigateToAssetWorkRequests: boolean,
        redirectData: RedirectData | null,
        alwaysOpen?: boolean
    ) => {
        const message = this.statusBloc.enqueueInfoMessage("Saving...")
        this.serviceRequestCreationBloc.updateIsSaving(true)

        const response = await this.serviceRequestCreationBloc.createServiceRequest(this.userBloc.state.user.id)

        if (response.isError()) {
            let errorMessage = "Error Saving Service Request"
            if (this.state.callerFromMobileApp) {
                window?.parent?.postMessage(
                    {
                        eventName: "serviceRequestCreateErrorIframeCallback",
                        caller: this.state.callerFromMobileApp,
                        data: { errorMessage: errorMessage },
                    },
                    smartHubHost
                )
                return
            } else {
                this.statusBloc.enqueueErrorMessage(errorMessage)
                return
            }
        }

        this.statusBloc.hideInfoMessage(message)
        this.workRequestsTableBloc.resetViewAll()
        this.companyBloc.fetchServiceCodes()
        if (this.workOrderWorkRequestsTableBloc.state.workOrder) {
            this.workOrderWorkRequestsTableBloc.fetchData(this.workOrderWorkRequestsTableBloc.state.workOrder?.id)
            this.workOrderWorkRequestsTableBloc.resetForm()
        }

        if (this.assetWorkRequestsBloc.state.createWorkRequestModalVisible) {
            this.assetWorkRequestsBloc.fetchData(this.state.selectedAsset!.id)
        }

        if (this.state.callerFromMobileApp) {
            window?.parent?.postMessage(
                {
                    eventName: "serviceRequestCreateSucceededIframeCallback",
                    caller: this.state.callerFromMobileApp,
                    data: { selectedAssetId: this.state.selectedAsset!.id },
                },
                smartHubHost
            )
            return
        }

        let hideModalAndClearForm = true

        if (redirectData) {
            this.navigateTo(redirectData)
            if (redirectData.isExternal) {
                hideModalAndClearForm = false
            }
        } else if (navigateToAssetWorkRequests) {
            //this needs to be called before hideModalAndResetForm() to get the this.state.selectedAsset.id value
            this.navigateToAssetWorkRequests()
        }

        if (hideModalAndClearForm) {
            this.serviceRequestCreationBloc.hideModalAndResetForm()
            if (alwaysOpen) {
                this.showModal(ServiceFormType.Request)
            }
            this.serviceRequestCreationBloc.updateIsSaving(false)
        }
    }

    createServiceRecordButtonClicked = async (
        navigateToAssetWorkRequests: boolean,
        redirectData: RedirectData | null
    ) => {
        this.serviceRequestCreationBloc.updateIsSaving(true)
        const message = this.statusBloc.enqueueInfoMessage("Saving...")

        const response = await this.serviceRequestCreationBloc.createServiceRecord(this.userBloc.state.user.id)

        if (response.isError()) {
            let errorMessage = "Error Saving Service Record"
            if (this.state.callerFromMobileApp) {
                window?.parent?.postMessage(
                    {
                        eventName: "serviceRecordCreateErrorIframeCallback",
                        caller: this.state.callerFromMobileApp,
                        data: { errorMessage: errorMessage },
                    },
                    smartHubHost
                )
                return
            } else {
                this.statusBloc.enqueueErrorMessage(errorMessage)
                return
            }
        }

        this.statusBloc.hideInfoMessage(message)
        this.workRequestsTableBloc.resetViewAll()

        if (this.workOrderWorkRequestsTableBloc.state.workOrder) {
            this.workOrderWorkRequestsTableBloc.fetchData(this.workOrderWorkRequestsTableBloc.state.workOrder?.id)
            this.workOrderWorkRequestsTableBloc.resetForm()
        }

        if (this.state.callerFromMobileApp) {
            window?.parent?.postMessage(
                {
                    eventName: "serviceRecordCreateSucceededIframeCallback",
                    caller: this.state.callerFromMobileApp,
                    data: { selectedAssetId: this.state.selectedAsset!.id },
                },
                smartHubHost
            )
            return
        }

        let hideModalAndClearForm = true
        if (redirectData) {
            this.navigateTo(redirectData)
            if (redirectData.isExternal) {
                hideModalAndClearForm = false
            }
        } else if (navigateToAssetWorkRequests) {
            //this needs to be called before hideModalAndResetForm() to get the this.state.selectedAsset.id value
            this.navigateToAssetWorkRequests()
        }

        if (hideModalAndClearForm) {
            this.serviceRequestCreationBloc.hideModalAndResetForm()
            this.serviceRequestCreationBloc.updateIsSaving(false)
        }
    }

    getUser = () => this.userBloc.state.user

    showModal = (formType: ServiceFormType) => this.serviceRequestCreationBloc.showModal(formType)

    searchForAssets = (search: string) => this.serviceRequestCreationBloc.searchAssets(search)
    loadDefaultSelectedAsset = (assetId: number) => this.serviceRequestCreationBloc.loadDefaultSelectedAsset(assetId)
    selectedAssetChanged = (asset: Asset | null) => this.serviceRequestCreationBloc.selectedAssetChanged(asset)
    callerFromMobileAppChanged = (callerFromMobileApp: string) =>
        this.serviceRequestCreationBloc.callerFromMobileAppChanged(callerFromMobileApp)
    requestServiceTypeChanged = (value: ServiceRequestType) =>
        this.serviceRequestCreationBloc.requestServiceTypeChanged(value)
    requestServiceCodeChanged = (value: ServiceCode | null) =>
        this.serviceRequestCreationBloc.requestServiceCodeChanged(value)
    requestUrgencyChanged = (value: Urgency) => this.serviceRequestCreationBloc.requestUrgencyChanged(value)
    requestDueDateChanged = (value: Date | null) => this.serviceRequestCreationBloc.requestDueDateChanged(value)

    requestDueHourChanged = (value: string) => this.serviceRequestCreationBloc.requestDueHourChanged(value)
    requestDueOdometerChanged = (value: string) => this.serviceRequestCreationBloc.requestDueOdometerChanged(value)
    requestEstimatedLaborHoursChanged = (value: string) =>
        this.serviceRequestCreationBloc.requestEstimatedLaborHoursChanged(value)
    requestEstimatedLaborCostChanged = (value: string) =>
        this.serviceRequestCreationBloc.requestEstimatedLaborCostChanged(value)
    requestEstimatedPartsCostChanged = (value: string) =>
        this.serviceRequestCreationBloc.requestEstimatedPartsCostChanged(value)
    requestWorkToBePerformedChanged = (value: string) =>
        this.serviceRequestCreationBloc.requestWorkToBePerformedChanged(value)
    requestSpecialInstructionsChanged = (value: string) =>
        this.serviceRequestCreationBloc.requestSpecialInstructionsChanged(value)
    requestTagTypeChanged = (value: TagType | null) => this.serviceRequestCreationBloc.requestTagChanged(value)
    requestTagReasonChanged = (value: string) => this.serviceRequestCreationBloc.requestTagReasonChanged(value)
    requestNotifyContactChanged = (contactIds: number[]) =>
        this.serviceRequestCreationBloc.requestNotifyContactChanged(contactIds)
    requestCreateWorkOrderChanged = (value: boolean) =>
        this.serviceRequestCreationBloc.requestCreateWorkOrderChanged(value)
    requestIsUnplannedChanged = (value: boolean) => this.serviceRequestCreationBloc.requestIsUnplannedChanged(value)
    requestWorkOrderStatusChanged = (value: WorkOrderStatus) =>
        this.serviceRequestCreationBloc.requestWorkOrderStatusChanged(value)
    requestWorkOrderAssignedToChanged = (value: number | null) =>
        this.serviceRequestCreationBloc.requestWorkOrderAssignedToChanged(value)
    requestWorkOrderSpecialInstructionsChanged = (value: string) =>
        this.serviceRequestCreationBloc.requestWorkOrderSpecialInstructionsChanged(value)
    requestMechanicsNotesChanged = (value: string) => this.serviceRequestCreationBloc.requestMechanicsNotesChanged(value)

    recordServiceCodeChanged = (value: ServiceCode | null) =>
        this.serviceRequestCreationBloc.recordServiceCodeChanged(value)
    recordServiceDateChanged = async (value: Date | null) => {
        if (value && this.state.selectedAsset?.id) {
            var response = await this.serviceRequestCreationBloc.fetchAssetUsageData(
                this.state.selectedAsset?.id,
                value
            )

            if (response.isOk()) {
                var assetReading = response.value.assetReading
                this.serviceRequestCreationBloc.recordOdometerChanged(
                    assetReading.odometer ? Strings.formatInteger(assetReading.odometer) : ""
                )
                this.serviceRequestCreationBloc.recordHourMeterChanged(
                    assetReading.hourMeter ? Strings.formatDecimal(assetReading.hourMeter) : ""
                )
            }
        }

        this.serviceRequestCreationBloc.recordServiceDateChanged(value)
    }
    recordSiteChanged = (value: Site | null) => this.serviceRequestCreationBloc.recordSiteChanged(value)
    recordWorkPerformedByChanged = (contacts: number[]) =>
        this.serviceRequestCreationBloc.recordWorkPerformedByChanged(contacts)

    recordHourMeterChanged = (value: string) => this.serviceRequestCreationBloc.recordHourMeterChanged(value)
    recordOdometerChanged = (value: string) => this.serviceRequestCreationBloc.recordOdometerChanged(value)
    recordLaborHoursChanged = (value: string) => this.serviceRequestCreationBloc.recordLaborHoursChanged(value)
    recordLaborCostChanged = (value: string) => this.serviceRequestCreationBloc.recordLaborCostChanged(value)
    recordIsUnplannedChanged = (value: boolean) => this.serviceRequestCreationBloc.recordIsUnplannedChanged(value)
    recordPartsCostChanged = (value: string) => this.serviceRequestCreationBloc.recordPartsCostChanged(value)
    recordWorkPerformedChanged = (value: string) => this.serviceRequestCreationBloc.recordWorkPerformedChanged(value)
    recordMechanicsNotesChanged = (value: string) => this.serviceRequestCreationBloc.recordMechanicsNotesChanged(value)
    recordExternalWorkOrderChanged = (value: string) =>
        this.serviceRequestCreationBloc.recordExternalWorkOrderChanged(value)
    recordExternalVendorChanged = (value: string) => this.serviceRequestCreationBloc.recordExternalVendorChanged(value)
    recordExternalInvoiceNumberChanged = (value: string) =>
        this.serviceRequestCreationBloc.recordExternalInvoiceNumberChanged(value)
    recordExternalNoteChanged = (value: string) => this.serviceRequestCreationBloc.recordExternalNoteChanged(value)
    attachmentsChanged = async (files: File[], formData: FormData) => {
        const message = this.statusBloc.enqueueInfoMessage("Attaching File...")
        const outcome = await this.serviceRequestCreationBloc.attachmentsChanged(
            files,
            formData,
            this.userBloc.state.user.id,
            this.userBloc.state.user.companyId
        )

        this.statusBloc.hideInfoMessage(message)
    }
    attachmentDeleted = (attachment: Attachment) =>
        this.serviceRequestCreationBloc.attachmentsDelete(
            attachment.name,
            this.userBloc.state.user.id,
            this.userBloc.state.user.companyId
        )
    attachmentError = (errors: string[]) => this.statusBloc.enqueueErrorMessage(errors.join("<br>"))

    hasRedYellowTagAddPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, PermissionType.Add)

    rotateImage = (attachment: Attachment, angle: number) =>
        this.serviceRequestCreationBloc.rotateImage(
            attachment.name,
            angle,
            this.userBloc.state.user.id,
            this.userBloc.state.user.companyId
        )

    hasWorkOrderAddPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.Add)
}
