import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"

export class Strings {
    private static numberFormatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 10,
    })
    private static decimalFormatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
    })
    private static dateFormatter = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    })
    private static timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
    })
    private static moneyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    })

    static formatMoney(value: string | number): string {
        return this.moneyFormatter.format(safeParseFloat(value)).replace("$", "")
    }

    static formatInteger(value: string | number): string {
        return this.numberFormatter.format(safeParseInt(value))
    }

    static formatFloat(value: string | number): string {
        return this.numberFormatter.format(safeParseFloat(value))
    }

    static formatDecimal(value: string | number): string {
        return this.decimalFormatter.format(safeParseFloat(value))
    }

    static formatDateTime(date: Date): string {
        return `${this.formatDate(date)} ${this.formatTime(date)}`
    }

    static formatDate(date: Date): string {
        return this.dateFormatter.format(date)
    }

    static formatTime(date: Date): string {
        return this.timeFormatter.format(date)
    }
    static forceFirstLetterUpperCase(str: string): string {
        return str[0].toUpperCase() + str.slice(1)
    }
}
