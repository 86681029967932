import { Outcome } from "@ethossoftworks/outcome"
import { compare, SortOrder } from "@lib/Comparable"
import {
    arrayToObject,
    boolOrDefault,
    nullNumOrDefault,
    nullStringOrDefault,
    numOrDefault,
    stringOrDefault,
} from "@lib/TypeUtil"
import { AssetCategory } from "@model/assets/AssetCategory"
import { AssetClass } from "@model/assets/AssetClass"
import { AssetMake } from "@model/assets/AssetMake"
import { AssetModel } from "@model/assets/AssetModel"
import { AssetType } from "@model/assets/AssetType"
import { Company } from "@model/company/Company"
import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { ThresholdSettings, WorkOrderAutomationSettings } from "@model/company/CompanySettings"
import { Contact, labelForContact } from "@model/contacts/Contact"
import { District } from "@model/district/District"
import { Group } from "@model/group/Group"
import { ApiServiceQuote, ApiServiceQuoteVendor, ServiceQuoteVendor } from "@model/serviceQuotes/ServiceQuote"
import {
    newServiceSubType,
    ServiceCode,
    ServiceRequestType,
    ServiceSchedule,
    ServiceScheduleType,
    ServiceSubType,
} from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { Step } from "@model/Step"
import { newThresholdGroup, Threshold, ThresholdGroup, ThresholdType, ThresholdUnit } from "@model/Threshold"
import { CompanyUrgency, Urgency } from "@model/Urgency"
import { CBACode } from "@model/workOrders/CbaCode"
import { WorkRequestType } from "@model/workRequests/WorkRequest"
import { ApiService, HttpMethod, nullableDateFromApi } from "@service/ApiService"
import { CompanyService } from "./CompanyService"

export class MainCompanyService implements CompanyService {
    constructor(private apiService: ApiService) {}

    async fetchCbaCodes(): Promise<Outcome<CBACode[]>> {
        let response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/CBACode",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map((x) => CBACodeFromApi(x)))
    }

    async fetchContacts(): Promise<Outcome<Contact[]>> {
        const response = await this.apiService.makeRequest({ method: HttpMethod.Get, path: "/Company/Person" })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(contactFromApi)
                .sort((a, b) => compare(labelForContact(a), labelForContact(b), SortOrder.Asc))
        )
    }

    async fetchMechanics(): Promise<Outcome<Contact[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/Mechanic/GetMechanics",
            body: {},
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(contactFromApi)
                .sort((a, b) => compare(labelForContact(a), labelForContact(b), SortOrder.Asc))
        )
    }

    async updateContact(contact: Contact): Promise<Outcome<Contact>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Patch,
            path: `/Company/Person/${contact.id}`,
            body: contactToApi(contact),
        })

        if (response.isError()) return response
        return Outcome.ok(contactFromApi(response.value))
    }

    async fetchCompanyLogo(companyId: number): Promise<Outcome<string>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/Company/GetCompanyLogo",
            query: { companyId: [companyId] },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value[0])
    }

    async fetchSubCompanies(): Promise<Outcome<Company[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/Company/SubCompaniesInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(
                    (it): Company => ({
                        id: numOrDefault(it.companyId, 0),
                        name: stringOrDefault(it.companyName, ""),
                        parentId: numOrDefault(it.parentCompanyID, 0),
                        logoUrl: it.logoUrl,
                    })
                )
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchAssetMakes(): Promise<Outcome<AssetMake[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/AssetMake/AssetMakesInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(
                    (it): AssetMake => ({
                        id: numOrDefault(it.assetMakeID, 0),
                        name: stringOrDefault(it.name, "").trim(),
                        description: stringOrDefault(it.description, ""),
                    })
                )
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchAssetModels(): Promise<Outcome<AssetModel[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/AssetModel/AssetModelsInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(
                    (it): AssetModel => ({
                        id: numOrDefault(it.assetModelID, 0),
                        name: stringOrDefault(it.name, "").trim(),
                        makeId: numOrDefault(it.assetMakeID, 0),
                    })
                )
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchAssetTypes(): Promise<Outcome<AssetType[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/AssetType/AssetTypesInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(
                    (it): AssetType => ({
                        id: numOrDefault(it.assetTypeID, 0),
                        name: stringOrDefault(it.name, "").trim(),
                        description: stringOrDefault(it.description, ""),
                    })
                )
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchAssetCategories(): Promise<Outcome<AssetCategory[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/AssetCategory/AssetCategoriesInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(
                    (it): AssetCategory => ({
                        id: numOrDefault(it.assetCategoryID, 0),
                        typeId: numOrDefault(it.assetTypeID, 0),
                        name: stringOrDefault(it.name, "").trim(),
                        description: stringOrDefault(it.description, ""),
                    })
                )
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchAssetClasses(): Promise<Outcome<AssetClass[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/AssetClass/AssetClassesInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value
                .map(
                    (it): AssetClass => ({
                        id: numOrDefault(it.assetClassID, 0),
                        categoryId: numOrDefault(it.assetCategoryID, 0),
                        name: stringOrDefault(it.name, "").trim(),
                        description: stringOrDefault(it.description, ""),
                    })
                )
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchGroups(): Promise<Outcome<Group[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/Group/GroupsInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(groupFromObj).sort((a, b) => compare(a.name, b.name, SortOrder.Asc)))
    }

    async fetchDistricts(): Promise<Outcome<District[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/District/DistrictsInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(districtFromObj).sort((a, b) => compare(a.name, b.name, SortOrder.Asc)))
    }

    async fetchSubDistricts(): Promise<Outcome<District[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/District/SubDistrictsInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(districtFromObj).sort((a, b) => compare(a.name, b.name, SortOrder.Asc)))
    }

    async fetchUnits(): Promise<Outcome<District[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/District/UnitsInUse",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(districtFromObj).sort((a, b) => compare(a.name, b.name, SortOrder.Asc)))
    }

    async fetchSitesInUse(): Promise<Outcome<Site[]>> {
        const response = await this.apiService.makeRequest({ method: HttpMethod.Get, path: "/Company/Site/SiteInUse" })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(siteFromObj).sort((a, b) => compare(a.name, b.name, SortOrder.Asc)))
    }
    async fetchAllSites(): Promise<Outcome<Site[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/Company/Site?Active=true&PageSize=10000",
        })
        if (!ApiService.isValidPagedResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            response.value.results.map(siteFromObj).sort((a, b) => compare(a.name, b.name, SortOrder.Asc))
        )
    }

    async fetchAliases(companyId: number): Promise<Outcome<Record<CompanyAlias, string>>> {
        const response = await this.apiService.makeRequest({ method: HttpMethod.Get, path: "/Company/CompanyAlias" })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        const aliases = response.value.find((x) => (x.companyID = companyId))
        return Outcome.ok(
            arrayToObject(Object.values(CompanyAlias), (key) => [key, aliases[key] ?? defaultCompanyAlias(key)])
        )
    }

    async fetchServiceSubTypes(): Promise<Outcome<Record<ServiceRequestType, ServiceSubType>>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceSubType",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(
            arrayToObject(Object.values(ServiceRequestType), (it) => [
                it,
                serviceSubTypeFromResponse(it, response.value),
            ])
        )
    }

    async updateServiceSubTypes(
        serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    ): Promise<Outcome<Record<ServiceRequestType, ServiceSubType>>> {
        const responses = await Promise.all(
            Object.values(serviceSubTypes).map(async (type) => {
                const response = await this.apiService.makeRequest({
                    method: HttpMethod.Put,
                    path: `/WorkOrder/ServiceSubType`,
                    query: {
                        id: type.id,
                    },
                    body: {
                        alias: type.alias,
                    },
                })
                if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
                return response
            })
        )

        if (responses.some((it) => it.isError())) return Outcome.error(undefined)

        return Outcome.ok(
            arrayToObject(Object.values(ServiceRequestType), (type) => [
                type,
                serviceSubTypeFromResponse(
                    type,
                    responses.map((it) => (it.isOk() ? it.value : newServiceSubType(type)))
                ),
            ])
        )
    }

    async fetchWorkOrderAutomationSettings(): Promise<Outcome<WorkOrderAutomationSettings>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/WorkOrderAutomation",
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(workOrderAutomationFromApi(response.value))
    }

    async updateWorkOrderAutomationSettings(
        settings: WorkOrderAutomationSettings
    ): Promise<Outcome<WorkOrderAutomationSettings>> {
        const method = settings.id === null ? HttpMethod.Post : HttpMethod.Put
        const path =
            settings.id === null ? "/WorkOrder/WorkOrderAutomation" : `/WorkOrder/WorkOrderAutomation/${settings.id}`
        const response = await this.apiService.makeRequest({ method, path, body: workOrderAutomationToApi(settings) })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(workOrderAutomationFromApi(response.value))
    }

    async fetchThresholdSettings(companyId: number): Promise<Outcome<ThresholdSettings>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/UpcomingThreshold",
            query: { companyId: [companyId] },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok({
            [WorkRequestType.Inspection]: thresholdGroupFromObj(response.value.find((it) => it.serviceTypeID === 2)),
            [WorkRequestType.Preventative]: thresholdGroupFromObj(response.value.find((it) => it.serviceTypeID === 1)),
            [WorkRequestType.Service]: thresholdGroupFromObj(response.value.find((it) => it.serviceTypeID === 3)),
        })
    }

    async updateThresholdSettings(thresholds: ThresholdSettings): Promise<Outcome<ThresholdSettings>> {
        const valueForThresholdType = (threshold: Threshold, type: ThresholdUnit): number | null =>
            threshold.unit === type ? threshold.value : null

        const serviceIdForGroupType = (
            type: WorkRequestType.Preventative | WorkRequestType.Inspection | WorkRequestType.Service
        ) => (type === WorkRequestType.Service ? 3 : type === WorkRequestType.Preventative ? 1 : 2)

        const updateThreshold = async (
            type: WorkRequestType.Inspection | WorkRequestType.Preventative | WorkRequestType.Service,
            group: ThresholdGroup
        ): Promise<Outcome<ThresholdGroup>> => {
            const method = group.id === null ? HttpMethod.Post : HttpMethod.Put
            const path = group.id === null ? "/WorkOrder/UpcomingThreshold" : `/WorkOrder/UpcomingThreshold/${group.id}`

            const body = {
                ...(method === HttpMethod.Put ? { upcomingThresholdId: group.id } : {}),
                serviceTypeID: serviceIdForGroupType(type),
                hoursUntil: valueForThresholdType(group[ThresholdType.Hours], ThresholdUnit.Unit),
                hoursUntilPercentage: valueForThresholdType(group[ThresholdType.Hours], ThresholdUnit.Percent),
                milesUntil: valueForThresholdType(group[ThresholdType.Miles], ThresholdUnit.Unit),
                milesUntilPercentage: valueForThresholdType(group[ThresholdType.Miles], ThresholdUnit.Percent),
                daysUntil: valueForThresholdType(group[ThresholdType.Days], ThresholdUnit.Unit),
                daysUntilPercentage: valueForThresholdType(group[ThresholdType.Days], ThresholdUnit.Percent),
                applyToSubCompanies: group.applyToSubCompanies,
            }

            const response = await this.apiService.makeRequest({ method, path, body })
            if (response.isError()) return Outcome.error(response)
            return Outcome.ok(thresholdGroupFromObj(response.value))
        }

        const [inspectionResponse, preventativeResponse, serviceResponse] = await Promise.all([
            updateThreshold(WorkRequestType.Inspection, thresholds[WorkRequestType.Inspection]),
            updateThreshold(WorkRequestType.Preventative, thresholds[WorkRequestType.Preventative]),
            updateThreshold(WorkRequestType.Service, thresholds[WorkRequestType.Service]),
        ])

        if (inspectionResponse.isError()) return inspectionResponse
        if (preventativeResponse.isError()) return preventativeResponse
        if (serviceResponse.isError()) return serviceResponse

        return Outcome.ok({
            [WorkRequestType.Inspection]: inspectionResponse.value,
            [WorkRequestType.Preventative]: preventativeResponse.value,
            [WorkRequestType.Service]: serviceResponse.value,
        })
    }

    async fetchServiceCodes(): Promise<Outcome<Record<number, ServiceCode>>> {
        const allResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceCode",
        })
        if (!ApiService.isValidArrayResponse(allResponse)) return Outcome.error(allResponse)
    
        const allServiceCodes = arrayToObject(
            allResponse.value
                .sort((a, b) => a.code.localeCompare(b.code)),
            (it) => [numOrDefault(it.serviceCodeID, 0), serviceCodeFromApi(it)]
        )
    
        const notAssignedToWOResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceCode/GetServiceCodesInUse?IsAssignToWorkOrder=false",
        })
        const assignedToWOResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceCode/GetServiceCodesInUse?IsAssignToWorkOrder=true",
        })
    
        if (!ApiService.isValidArrayResponse(notAssignedToWOResponse)) return Outcome.error(notAssignedToWOResponse)
        notAssignedToWOResponse.value.forEach((it) => {
            const id = numOrDefault(it.serviceCodeID, 0)
            if (allServiceCodes[id] !== undefined) {
                allServiceCodes[id].associatedWithWorkRequest = true
            }
        })
    
        if (!ApiService.isValidArrayResponse(assignedToWOResponse)) return Outcome.error(assignedToWOResponse)
        assignedToWOResponse.value.forEach((it) => {
            const id = numOrDefault(it.serviceCodeID, 0)
            if (allServiceCodes[id] !== undefined) {
                allServiceCodes[id].associatedWithWorkOrder = true
            }
        })
    
        return Outcome.ok(allServiceCodes)
    }
    
    
    async fetchServiceScheduleFilterOptions(): Promise<Outcome<Record<number, ServiceSchedule>>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            body: {},
            path: "/WorkOrder/ServiceSchedule/GetServiceScheduleFilterOptions",
        })

        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        const allServiceSchedulesInUse = arrayToObject(response.value, (it) => [
            numOrDefault(it.serviceScheduleID, 0),
            serviceScheduleFromApi(it, true),
        ])

        return Outcome.ok(allServiceSchedulesInUse)
    }

    async fetchServiceSchedules(): Promise<Outcome<Record<number, ServiceSchedule>>> {
        const allResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceSchedule",
        })
        if (!ApiService.isValidArrayResponse(allResponse)) return Outcome.error(allResponse)
        const allServiceSchedules = arrayToObject(allResponse.value, (it) => [
            numOrDefault(it.serviceScheduleID, 0),
            serviceScheduleFromApi(it),
        ])

        const inUseResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceSchedule/GetServiceSchedulesInUse",
        })
        if (!ApiService.isValidArrayResponse(inUseResponse)) return Outcome.error(inUseResponse)
        inUseResponse.value.forEach((it) => {
            const id = numOrDefault(it.serviceScheduleID, 0)
            if (allServiceSchedules[id] !== undefined) allServiceSchedules[id].associatedWithWorkRequest = true
        })

        return Outcome.ok(allServiceSchedules)
    }

    async fetchServiceSchedulesInUse(assetId: number): Promise<Outcome<ServiceSchedule[]>> {
        const inUseResponse = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/ServiceSchedule/GetServiceSchedulesInUse",
            body: { assetId: [assetId] },
        })
        if (!ApiService.isValidArrayResponse(inUseResponse)) return Outcome.error(inUseResponse)

        return Outcome.ok(inUseResponse.value.map((it) => serviceScheduleFromApi(it)))
    }

    async fetchServiceScheduleItemsInUse(assetId: number): Promise<Outcome<Step[]>> {
        const inUseResponse = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/ServiceSchedule/GetServiceItems",
            body: { assetId: [assetId] },
        })
        if (!ApiService.isValidArrayResponse(inUseResponse)) return Outcome.error(inUseResponse)

        let result = inUseResponse.value.map((it) => this.stepFromApi(it))

        return Outcome.ok(result)
    }

    stepFromApi(serviceScheduleStep: any): Step {
        return {
            id: serviceScheduleStep.serviceScheduleItemId,
            step: serviceScheduleStep.step,
            label: serviceScheduleStep.serviceStep ? serviceScheduleStep.serviceStep.name : serviceScheduleStep.step,
        }
    }

    async fetchUrgencies(): Promise<Outcome<CompanyUrgency[]>> {
        const response = await this.apiService.makeRequest({ method: HttpMethod.Get, path: "/WorkOrder/Urgency" })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map((it) => companyUrgencyFromApi(it)))
    }

    async fetchVendors(companyId: number): Promise<Outcome<ServiceQuoteVendor[], unknown>> {
        const response = await this.apiService.makeRequest<ApiServiceQuote[]>({
            method: HttpMethod.Post,
            path: "/WorkOrder/ServiceQuote/GetServiceQuotes",
            body: { companyId: companyId },
        })

        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        const uniqueVendorsMap = new Map<number, ServiceQuoteVendor>()

        response.value.forEach((quote) => {
            const vendor = serviceQuoteVendorFromApi(quote.vendor)
            if (vendor && !uniqueVendorsMap.has(vendor.id)) {
                uniqueVendorsMap.set(vendor.id, vendor)
            }
        })

        // Convert the map values to an array
        const vendors = Array.from(uniqueVendorsMap.values())

        return Outcome.ok(vendors)
    }
}

function serviceSubTypeFromResponse(type: ServiceRequestType, obj: any[]): ServiceSubType {
    const nameToFind = ((): string => {
        switch (type) {
            case ServiceRequestType.FollowUp:
                return "Follow-Up"
            case ServiceRequestType.Repair:
                return "Repair"
            case ServiceRequestType.Inspection:
                return "Inspection"
            case ServiceRequestType.Warranty:
                return "Warranty"
        }
    })()

    const item = obj.find((it) => stringOrDefault(it.name, "") === nameToFind)

    return {
        id: numOrDefault(item?.serviceSubTypeID, -1),
        companyId: numOrDefault(item?.companyID, -1),
        alias: nullStringOrDefault(item?.alias, null),
        name: stringOrDefault(item?.name, ""),
        type: type,
    }
}

function groupFromObj(obj: any): Group {
    return {
        id: numOrDefault(obj.groupID, 0),
        name: stringOrDefault(obj.name, ""),
        companyId: numOrDefault(obj.companyID, 0),
    }
}

function districtFromObj(obj: any): District {
    return {
        id: numOrDefault(obj.districtID, 0),
        parentId: nullNumOrDefault(obj.parentDistrictID, null),
        name: stringOrDefault(obj.districtName, ""),
        companyId: numOrDefault(obj.companyID, 0),
    }
}

function thresholdGroupFromObj(obj: any): ThresholdGroup {
    if (obj === undefined || obj === null) return newThresholdGroup()
    const dayUnit = obj.daysUntilPercentage !== null ? ThresholdUnit.Percent : ThresholdUnit.Unit
    const mileUnit = obj.milesUntilPercentage !== null ? ThresholdUnit.Percent : ThresholdUnit.Unit
    const hourUnit = obj.hoursUntilPercentage !== null ? ThresholdUnit.Percent : ThresholdUnit.Unit
    return {
        id: numOrDefault(obj.upcomingThresholdId, 0),
        [ThresholdType.Days]: {
            unit: dayUnit,
            value: nullNumOrDefault(dayUnit === ThresholdUnit.Unit ? obj.daysUntil : obj.daysUntilPercentage, null),
        },
        [ThresholdType.Hours]: {
            unit: hourUnit,
            value: nullNumOrDefault(hourUnit === ThresholdUnit.Unit ? obj.hoursUntil : obj.hoursUntilPercentage, null),
        },
        [ThresholdType.Miles]: {
            unit: mileUnit,
            value: nullNumOrDefault(mileUnit === ThresholdUnit.Unit ? obj.milesUntil : obj.milesUntilPercentage, null),
        },
        applyToSubCompanies: boolOrDefault(obj.applyToSubCompanies, false),
        isReadOnly: boolOrDefault(obj.isReadOnly, false),
    }
}

function serviceCodeFromApi(obj: any): ServiceCode {
    return {
        id: numOrDefault(obj.serviceCodeID, 0),
        name: stringOrDefault(obj.name, ""),
        code: stringOrDefault(obj.code, ""),
        companyId: numOrDefault(obj.companyID, 0),
        associatedWithWorkRequest: false,
        associatedWithWorkOrder: false,
    }
}

function serviceScheduleFromApi(obj: any, associatedWithWorkRequest: boolean = false): ServiceSchedule {
    return {
        id: numOrDefault(obj.serviceScheduleID, 0),
        name: stringOrDefault(obj.name, ""),
        serviceScheduleType: serviceScheduleTypeFromApi(obj.serviceScheduleTypeId),
        days: numOrDefault(obj.days, 0),
        hourMeter: numOrDefault(obj.hourMeter, 0),
        odometer: numOrDefault(obj.odometer, 0),
        targetDate: nullableDateFromApi(obj.targetDate),
        companyId: numOrDefault(obj.companyID, 0),
        associatedWithWorkRequest: associatedWithWorkRequest,
    }
}

function serviceScheduleTypeFromApi(obj: any): ServiceScheduleType | null {
    switch (obj) {
        case null:
            return null
        case 1:
            return ServiceScheduleType.Preventative
        case 2:
            return ServiceScheduleType.Inspection
    }
    throw new Error(`Invalid schedule type ${obj}`)
}

function serviceScheduleTypeToApi(type: ServiceScheduleType | null): number | null {
    if (type === null) return null
    switch (type) {
        case ServiceScheduleType.Preventative:
            return 1
        case ServiceScheduleType.Inspection:
            return 2
    }
}

function siteFromObj(obj: any): Site {
    return {
        id: numOrDefault(obj.siteId, 0),
        name: stringOrDefault(obj.name, ""),
        description: stringOrDefault(obj.description, ""),
        companyId: numOrDefault(obj.companyId, -1),
    }
}

function contactFromApi(obj: any): Contact {
    return {
        id: numOrDefault(obj.personID, 0),
        firstName: nullStringOrDefault(obj.firstName, null),
        lastName: nullStringOrDefault(obj.lastName, null),
        displayName: nullStringOrDefault(obj.displayName, null),
        email: nullStringOrDefault(obj.email, null),
        isMechanic: obj.mechanic === true,
        companyName: nullStringOrDefault(obj.companyName, null),
        company: {
            id: numOrDefault(obj.company ? obj.company.companyId : obj.companyId, 0),
            name: nullStringOrDefault(obj.company ? obj.company.companyName : obj.companyName, null),
        },
        homePhone: nullStringOrDefault(obj.homePhone, null),
        officePhone: nullStringOrDefault(obj.officePhone, null),
        cellPhone: nullStringOrDefault(obj.cellPhone, null),
        title: nullStringOrDefault(obj.title, null),
        notes: nullStringOrDefault(obj.notes, null),
        address: nullStringOrDefault(obj.address, null),
        city: nullStringOrDefault(obj.city, null),
        state: nullNumOrDefault(obj.stateID, null),
        postalCode: nullStringOrDefault(obj.postalCode, null),
        personTypeID: nullNumOrDefault(obj.personTypeID, null),
        hasActiveWorkOrders: boolOrDefault(obj.hasActiveWorkOrders, false),
        deleted: boolOrDefault(obj.deleted, false),
    }
}

function CBACodeFromApi(obj: any): CBACode {
    return {
        code: obj.code,
        name: obj.name,
        cbaCodeId: obj.cbaCodeID,
    }
}

function contactToApi(contact: Contact): any {
    return {
        personID: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        displayName: contact.displayName,
        email: contact.email,
        mechanic: contact.isMechanic,
        companyID: contact.company.id,
        homePhone: contact.homePhone,
        officePhone: contact.officePhone,
        cellPhone: contact.cellPhone,
        companyName: contact.companyName,
        title: contact.title,
        notes: contact.notes,
        address: contact.address,
        city: contact.city,
        stateID: contact.state,
        postalCode: contact.postalCode,
    }
}

function companyUrgencyFromApi(obj: any): CompanyUrgency {
    return {
        type: (() => {
            switch (stringOrDefault(obj.name, "Medium")) {
                case "Immediate":
                    return Urgency.Immediate
                case "High":
                    return Urgency.High
                case "Medium":
                    return Urgency.Medium
                case "Low":
                    return Urgency.Low
                default:
                    return Urgency.Medium
            }
        })(),
        companyId: numOrDefault(obj.companyId, 0),
        id: numOrDefault(obj.urgencyID, 0),
    }
}

function workOrderAutomationFromApi(obj: any): WorkOrderAutomationSettings {
    return {
        id: numOrDefault(obj.workOrderAutomationSettingsID, 0),
        companyId: numOrDefault(obj.companyId, 0),
        [WorkRequestType.Inspection]: boolOrDefault(obj.inspection, false),
        [WorkRequestType.Preventative]: boolOrDefault(obj.preventativeMaintenance, false),
        [WorkRequestType.Service]: boolOrDefault(obj.serviceRequest, false),
        applyToSubCompanies: boolOrDefault(obj.applyToSubCompanies, false),
        isReadOnly: boolOrDefault(obj.isReadOnly, false),
    }
}

function workOrderAutomationToApi(settings: WorkOrderAutomationSettings): any {
    return {
        ...(settings.id === null ? { workOrderAutomationSettingsID: settings.id } : {}),
        ...(settings.id === null ? { companyId: settings.companyId } : {}),
        preventativeMaintenance: settings[WorkRequestType.Preventative],
        inspection: settings[WorkRequestType.Inspection],
        serviceRequest: settings[WorkRequestType.Service],
        applyToSubCompanies: settings.applyToSubCompanies,
    }
}

function serviceQuoteVendorFromApi(obj: ApiServiceQuoteVendor | null): ServiceQuoteVendor | null {
    if (obj == null) {
        return null
    }
    return {
        id: numOrDefault(obj.vendorId, 0),
        VendorName: obj.vendorName,
        Description: obj.description,
        PrimaryContactEmail: obj.primaryContactEmail,
        PrimaryContactName: obj.primaryContactName,
        PrimaryContactPhoneNumber: obj.primaryContactPhoneNumber,
        PrimaryResponseEmailAddress: obj.primaryResponseEmailAddress,
    }
}
