import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useIsInIframe, useIsMobile, useLastNonNull } from "@lib/Hooks"
import { useRouteParams } from "@lib/router/hooks"
import { Strings } from "@lib/Strings"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Color } from "@model/Color"
import { Contact, labelForContact } from "@model/contacts/Contact"
import {
    labelForServiceCode,
} from "@model/serviceRequests/ServiceRequest"
import { TableColumnSize } from "@model/tables/Table"

import { Button, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { DataField } from "@ui/common/DataField"

import {
    CollapsibleFormField,
    FormField,
    FormFieldDirection,
    FormFieldLabel,
    FormFieldNote,
} from "@ui/common/form/FormField"
import { Select, SelectTheme } from "@ui/common/form/Select"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputTheme, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { Page, PageContent } from "@ui/common/page/Page"
import { PageInfoSection, PageInfoSectionDataFields } from "@ui/common/page/PageInfoSection"
import {
    PageToolbar,
    PageToolbarActionButtons,
    PageToolbarButtons,
    PageToolbarTitle,
} from "@ui/common/page/PageToolbar"
import { CollapsibleTable, CollapsibleTableRow } from "@ui/common/tables/CollapsibleTable"
import { HistoryTable } from "@ui/common/tables/HistoryTable"
import { Tag } from "@ui/common/Tag"
import { DI } from "@ui/DI"
import { ExternalRoutes } from "@lib/Routes"
import React, { useEffect, useRef } from "react"
import { ServiceQuoteScreenViewModel } from "./ServiceQuoteScreenViewModel"
import { PencilIcon } from "@ui/common/images/InlinedSvgs"
import { labelForAsset } from "@model/assets/Asset"
import { AssetLocation } from "@ui/workRequests/AssetLocation"
import { ServiceQuoteEffect, ServiceQuoteWorkRequestForm } from "@state/serviceQuotes/ServiceQuoteScreenBloc"
import {  labelForServiceQuoteStatus, labelForServiceRequest, labelForServiceRequestHeader, ServiceQuoteItem,  ServiceQuoteItemFormType,  ServiceQuoteStatus, serviceQuoteStatusForValue } from "@model/serviceQuotes/ServiceQuote"
import { TextField } from "@material-ui/core"
import { ServiceQuoteStatusSelect } from "@ui/common/form/ServiceQuoteStatusSelect"
import { ApproveServiceQuoteModal } from "./ApproveServiceQuoteModal"
import { RequestRevisionServiceQuoteModal } from "./RequestRevisionServiceQuoteModal"
import { AssociateServiceScheduleModal } from "./AssociateServiceScheduleModal"
import { AttachmentList } from "@ui/common/form/AttachmentList"

export function ServiceQuoteScreen(): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const { serviceQuoteId } = useRouteParams()
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuoteScreenViewModel())

    const getStatusOptions = () => {
        let options = Object.values(ServiceQuoteStatus)
        
        return options.map((it) => ({
            label: labelForServiceQuoteStatus(it),
            value: it,
        }))
    }    

    useEffect(() => {
        viewModel.onMounted(serviceQuoteId)
    }, [])  
    

    return (
        <Page className={"page--work-order"}>
            <PageToolbar>
                <PageToolbarTitle>
                    <div style={{ display: "flex", gap: "0px 20px", alignItems: "center" }}>
                        <span>Service Quote {state.serviceQuote?.number}</span>                                                
                    </div>
                    {isMobile && <div className="work-order-asset-label--mobile">{labelForAsset(state.asset)}</div>}
                    
                </PageToolbarTitle>
                {/* IMPORTANT!: Adding false to disable the Rollback button for PROD deployment} */}
                <PageToolbarActionButtons>
                </PageToolbarActionButtons>
                <PageToolbarButtons>
                    <Button
                        type={ButtonType.Contained}
                        isEnabled={viewModel.hasServiceQuoteEditPermission()}
                        label="Decline Quote"
                        onClick={() => {viewModel.showDeclineServiceQuoteModal()}}
                    />
                    <Button
                        type={ButtonType.Contained}
                        isEnabled={viewModel.hasServiceQuoteEditPermission()}
                        label="Request Revision"
                        onClick={() => viewModel.showRequestRevisionServiceQuoteModal()}
                    />
                    <Button
                        type={ButtonType.Contained}
                        isEnabled={viewModel.hasServiceQuoteEditPermission()}
                        label="Approve"
                        onClick={() => viewModel.showApproveServiceQuoteModal()}
                    />
                    <Button
                        type={ButtonType.Contained}
                        label="Save"
                        isEnabled={
                            viewModel.hasServiceQuoteEditPermission() &&
                            (state.formHasChanged && viewModel.isFormValid())
                        }
                        onClick={() => {
                            viewModel.handleSaveClicked()
                        }}
                    />
                </PageToolbarButtons>
            </PageToolbar>
            <LoadingIndicatorContainer>
                <LoadingIndicator isLoading={state.isLoading} />
                <PageInfoSection>
                    <Grid columns={4}>
                        <FormField label={"Status"}>
                            <Select
                                theme={SelectTheme.Dark}
                                options={getStatusOptions()}
                                value={state.form?.status ?? ServiceQuoteStatus.Pending}
                                isEnabled={false}
                                id="status-dropdown"                             
                            ></Select>                            
                        </FormField>
                        <FormField label={"Assigned To"} direction={FormFieldDirection.Column}>
                            <Select
                                theme={SelectTheme.Light}
                                options={[
                                    { label: "Unassigned", value: "-1" },
                                    ...state.mechanics.filter((it) => !it.deleted || it.id.toString() === state.form.assignedTo?.toString()).map((it: Contact | undefined) => ({
                                        label: labelForContact(it),
                                        value: it?.id.toString() ?? "-1",
                                    })),
                                ]}
                                value={state.form.assignedTo?.toString()}
                                isEnabled={viewModel.hasServiceQuoteEditPermission()}
                                onChange={(value) => viewModel.assignedToChanged(safeParseInt(value))}
                            ></Select>

                            {state.assignedToContact !== null && (
                                <FormFieldNote inset={true}>
                                    {state.assignedToContact?.cellPhone}&nbsp;&nbsp;&nbsp;&nbsp;
                                    {state.assignedToContact?.email}
                                </FormFieldNote>
                            )}
                        </FormField>
                        <FormField label={"Vendor"} direction={FormFieldDirection.Column}>
                            <TextInput value={state.form?.vendor?.VendorName ?? "Undefined"} isEnabled={false} theme={TextInputTheme.Light}></TextInput>
                        </FormField>
                        <FormField label={"Vendor Contact"} direction={FormFieldDirection.Column} >
                            <TextInput value={state.form?.vendor?.PrimaryContactName ?? ""} isEnabled={false} theme={TextInputTheme.Light} ></TextInput>
                            <FormFieldNote inset={false}>
                                {state.form?.vendor?.PrimaryContactPhoneNumber ?? ""}&nbsp;&nbsp;&nbsp;&nbsp;
                                {state.form?.vendor?.PrimaryContactEmail ?? ""}
                            </FormFieldNote>
                        </FormField>                                 
                    </Grid>
                    {!isMobile && (
                        <PageInfoSectionDataFields>
                            <DataField
                                label="Identifier/Stock #"
                                value={
                                    <>
                                        <a href={ExternalRoutes.SmartHubAsset(state.asset?.id ?? 0)} target="_blank">
                                            {state.asset?.identifier}
                                        </a>
                                        {state.asset?.tag && <Tag tag={state.asset?.tag} />}
                                    </>
                                }
                                buttons={
                                    viewModel.hasServiceQuoteEditPermission() ? (
                                        <Button
                                            type={ButtonType.Icon}
                                            label="Edit"
                                            onClick={() =>{if(state.asset?.id) viewModel.redirectToAssetGeneralPage(state.asset?.id)}}
                                        >
                                            <PencilIcon />
                                        </Button>
                                    ) : (
                                        ""
                                    )
                                }
                            />
                            <DataField label="Make" value={state.asset?.make.name} />
                            <DataField label="Model" value={state.asset?.model.name} />
                            <DataField label="Year" value={state.asset?.modelYear} />
                            <DataField label="VIN" value={state.asset?.serialNumber} />
                            <DataField label="Category" value={state.asset?.category.name} />
                            <DataField label="Class" value={state.asset?.class?.name ?? ""} />
                            <DataField
                                label="Billing Code"
                                value={state.asset?.billingCodeCode + " " + state.asset?.billingCodeName}
                            />
                            <DataField
                                label="Last Known Location"
                                value={
                                    <AssetLocation
                                        location={state.asset?.location ?? null}
                                        site={state.asset?.site ?? null}
                                        hasLinks={!isMobile}
                                    />
                                }
                            />
                            <DataField label="" value="" color={Color.Transparent} />
                            <DataField
                                label="Hour Meter / Lifetime Hour Meter"
                                value={
                                    <>
                                        {safeParseFloat(state.asset?.hourMeter).toFixed(1) +
                                            " / " +
                                            safeParseFloat(state.asset?.lifetimeHourMeter).toFixed(1)}
                                    </>
                                }
                                buttons={
                                    viewModel.hasServiceQuoteEditPermission() ? (
                                        <Button
                                            type={ButtonType.Icon}
                                            label="Edit"
                                            onClick={() => viewModel.hourMeterLifetimeHourMeterEditClicked()}
                                        >
                                            <PencilIcon />
                                        </Button>
                                    ) : (
                                        ""
                                    )
                                }
                            />
                            <DataField
                                label="Odometer / Lifetime Odometer"
                                value={
                                    <>
                                        {safeParseInt(state.asset?.odometer) +
                                            " / " +
                                            safeParseInt(state.asset?.lifetimeOdometer)}
                                    </>
                                }
                                buttons={
                                    viewModel.hasServiceQuoteEditPermission() ? (
                                        <Button
                                            type={ButtonType.Icon}
                                            label="Edit"
                                            onClick={() => viewModel.odometerLifetimeOdometerEditClicked()}
                                        >
                                            <PencilIcon />
                                        </Button>
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </PageInfoSectionDataFields>
                    )}
                </PageInfoSection>
                <PageContent>
                    <GridCont gap={GridGap.Medium}>
                    {(viewModel.state.serviceQuote?.serviceQuoteItems != undefined && viewModel.state.serviceQuote?.serviceQuoteItems[0].id !== undefined ) && (
                        <Grid columns={1}>
                            
                            <FormField>
                                <Button
                                    type={ButtonType.Text}
                                    isEnabled={viewModel.hasServiceQuoteEditPermission() && viewModel.state.serviceQuote?.Status !== ServiceQuoteStatus.Declined}
                                    label="Approve All"
                                    onClick={() => {viewModel.approveAllServiceRequests()}}
                                />
                                <Button
                                    type={ButtonType.Text}                                    
                                    isEnabled={viewModel.hasServiceQuoteEditPermission() && viewModel.state.serviceQuote?.Status !== ServiceQuoteStatus.Declined}
                                    label="Decline All"
                                    onClick={() => {viewModel.showServiceRequestDetails(viewModel.state.serviceQuote?.serviceQuoteItems[0].id!, ServiceQuoteItemFormType.DeclineAll)}}
                                />
                            </FormField>
                            <FormField>
                                <div style={{ position: "relative", width: "100%" }}>
                                    <ServiceQuoteServiceRequestsTable
                                        viewModel={viewModel}
                                        serviceRequests={state.serviceRequests}
                                        serviceRequestsForms={state.serviceRequestForms}
                                    />
                                </div>
                            </FormField>
                        </Grid>)}
                        <Grid columns={1}>
                            <AttachmentList
                                attachments={viewModel.state.form.attachments ?? []}
                                disabled={true}
                            />
                        </Grid>
                        <Grid columns={1}>
                            <CollapsibleFormField
                                label="Service Quote History"
                                isLoading={viewModel.state.effectStatus[ServiceQuoteEffect.FetchServiceQuoteHistory].isBusy()}
                                onClick={() => {
                                    if (state.serviceQuote) viewModel.loadServiceQuoteHistory(state.serviceQuote?.id)
                                }}
                                isCollapsed={true}>
                                <HistoryTable history={ state.serviceQuote?.quoteHistory ?? []} statusLabelCreator={(x) => x} />
                            </CollapsibleFormField>
                        </Grid>
                        {isMobile && (
                            <Grid columns={1}>
                                <FormField className="modal-save-buttons-top">
                                    {state.formHasChanged && (
                                        <Button
                                            type={ButtonType.Text}
                                            theme={ButtonTheme.Light}
                                            label="Cancel"
                                            onClick={() =>viewModel.cancelClicked()}
                                        />
                                    )}
                                    <Button
                                        type={ButtonType.Contained}
                                        label="Save"
                                        isEnabled={viewModel.hasServiceQuoteEditPermission()}
                                        onClick={() => viewModel.handleSaveClicked()}
                                    />
                                </FormField>
                            </Grid>
                        )}
                    </GridCont>
                </PageContent>
            </LoadingIndicatorContainer>
            <FetchDataErrorModal workOrderId={serviceQuoteId} isVisible={state.hasDataFetchError} viewModel={viewModel} />
            <ServiceRequestDetailsModal workRequestId={state.workRequestDetailsModal} viewModel={viewModel} formType={state.FormType} />
            <ApproveServiceQuoteModal 
                user={state.user} 
                isVisible={state.ApproveServiceQuoteModalVisible} 
                serviceRequests={state.serviceRequests} 
                serviceQuote={state.serviceQuote}
                onApproveClicked={viewModel.approveServiceQuoteClicked}  
                onCancelClicked={() => viewModel.hideApproveServiceQuoteModal()}  
                />
            <RequestRevisionServiceQuoteModal
                isVisible={state.RequestRevisionServiceQuoteModalVisible} 
                serviceQuote={state.serviceQuote}
                onRequestRevisionClicked={viewModel.requestRevisionServiceQuoteClicked}  
                onCancelClicked={viewModel.hideRequestRevisionServiceQuoteModal}  
                />
            <AssociateServiceScheduleModal 
                serviceQuote={state.serviceQuote} 
                assetSchedules={state.AssetSchedules} 
                isVisible={state.AsociateServiceScheduleModalVisibile} 
                onSaveClicked={viewModel.AssociateScheduleToServiceQuoteClicked} 
                />
        </Page>
    )
}

function FetchDataErrorModal({
    workOrderId,
    isVisible,
    viewModel,
}: {
    workOrderId: string
    isVisible: boolean
    viewModel: ServiceQuoteScreenViewModel
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Error Loading Service Quote</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    There was an error loading the service quote. Please retry or return to the service quotes list.
                </ModalBody>
                <ModalButtons>
                    <Button
                        label={"View Service Quotes"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.viewServiceQuotesClicked()}
                    />
                    <Button label={"Retry"} onClick={() => viewModel.onMounted(workOrderId)} />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function ServiceQuoteServiceRequestsTable({
    serviceRequests,
    serviceRequestsForms,
    viewModel,
}: {
    serviceRequests: Record<number, ServiceQuoteItem>
    serviceRequestsForms: Record<number, ServiceQuoteWorkRequestForm>
    viewModel: ServiceQuoteScreenViewModel
}): JSX.Element {
    const isMobile = useIsMobile()
    const head = [
        { size: isMobile ? TableColumnSize.FitContentGrow : TableColumnSize.Medium, content: "Work to be Performed" },
        { size: TableColumnSize.MinContent, content: "Status" },
        ...(!isMobile
            ? [
                  { size: TableColumnSize.FitContent, content: "Service Code" },
                  { size: TableColumnSize.FitContent, content: "Labor Hours" },
                  { size: TableColumnSize.FitContent, content: "Labor Cost" },
                  { size: TableColumnSize.FitContent, content: "Parts Cost" },
                  { size: TableColumnSize.FitContent, content: "Misc Cost" },
                  { size: TableColumnSize.FitContent, content: "Total Cost" },
                  { size: TableColumnSize.FitContent },
              ]
            : [{ size: TableColumnSize.FitContent }]),
    ]

    const serviceQuoteTotals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalCost: 0,
    }

    const approveTotalstotals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalCost: 0,
    }

    const data: CollapsibleTableRow[] = [
        ...Object.values(serviceRequests).map((it, i) => {
            const form : ServiceQuoteWorkRequestForm= serviceRequestsForms[it.id]
            if (form === undefined) return { entryId: it.id, columns: [], isCollapsed: true, collapsibleContent: null }

            serviceQuoteTotals.laborHours += safeParseFloat(form.LaborHours)
            serviceQuoteTotals.laborCost += safeParseFloat(form.LaborCost)
            serviceQuoteTotals.partsCost += safeParseFloat(form.PartsCost)
            serviceQuoteTotals.miscCost += safeParseFloat(form.MiscCost)
            serviceQuoteTotals.totalCost += safeParseFloat(form.TotalCost)
            

            if (form.status == ServiceQuoteStatus.Approved) {
                approveTotalstotals.laborHours += safeParseFloat(form.LaborHours)
                approveTotalstotals.laborCost += safeParseFloat(form.LaborCost)
                approveTotalstotals.partsCost += safeParseFloat(form.PartsCost)
                approveTotalstotals.miscCost += safeParseFloat(form.MiscCost)
                approveTotalstotals.totalCost += safeParseFloat(form.TotalCost)
            }
            

            return {
                entryId: it.id,
                columns: [
                    {
                        onClick: () => viewModel.showServiceRequestDetails(it.id, ServiceQuoteItemFormType.Standard),
                        content: (
                            <div style={{ color: Color.Action, cursor: "pointer" }}>{labelForServiceRequest(it)}</div>
                        ),
                    },
                    {
                        content: (
                            <>
                                <ServiceQuoteStatusSelect
                                    status={form.status}
                                    onChange={(status) => {
                                        if (status === ServiceQuoteStatus.Declined) {
                                            viewModel.showServiceRequestDetails(it.id, ServiceQuoteItemFormType.Declined);
                                        }
                                        else{
                                            viewModel.serviceQuoteStatusChanged(it.id, status, true);
                                        }
                                    }}
                                    disabled={!viewModel.hasServiceQuoteEditPermission() || viewModel.state.form?.status == ServiceQuoteStatus.Declined}
                                    filterPredicate={x=> x !== ServiceQuoteStatus.RevisionRequested}
                                />
                            </>
                        ),
                        className: "work-request-status-link",
                    },
                    ...( [
                              {
                                  content:
                                      <>
                                          <Select
                                            options={[
                                                ...[{ id: -1, value: "-1", label: "Select Service Code" }].map((s) => ({
                                                    key: s.id,
                                                    value: `${s.value}`,
                                                    label: s.label,
                                                })),
                                                ...viewModel.state.serviceCodes.map((it) => ({
                                                    key: it.id,
                                                    value: `${it.id}`,
                                                    label: labelForServiceCode(it),
                                                })),
                                            ]}
                                            isEnabled={viewModel.hasServiceQuoteEditPermission() && viewModel.state.form?.status !== ServiceQuoteStatus.Declined && viewModel.state.serviceQuote?.Status !== ServiceQuoteStatus.Declined}
                                            value={form.serviceCodeId ?? ""}
                                            onChange={(value) => viewModel.requestServiceCodeChanged(it.id, value, true)}
                                            />
                                      </>,
                              },
                              {
                                  content: Strings.formatFloat(form.LaborHours),
                              },
                              {
                                  content: `$ ${form.LaborCost}`,
                              },
                              {
                                  content: `$ ${form.PartsCost}`,
                              },
                              {
                                content: `$ ${form.MiscCost}`,
                              },
                              { content: `$ ${form.TotalCost}` },
                              { content: ""}                              
                          ]),
                ],
                isCollapsed: true,
            }
        }),
        ...(!isMobile && Object.keys(serviceRequests).length > 0
            ? [
                  {
                      entryId: "totals-row-first",
                      columns: [
                          {},
                          {},
                          { content: "Service Quote Total"},
                          { content: Strings.formatFloat(serviceQuoteTotals.laborHours) },
                          { content: `$\u00A0\u00A0${Strings.formatMoney(serviceQuoteTotals.laborCost)}` },
                          { content: `$\u00A0\u00A0${Strings.formatMoney(serviceQuoteTotals.partsCost)}` },
                          { content: `$\u00A0\u00A0${Strings.formatMoney(serviceQuoteTotals.miscCost)}` },
                          { content: `$ ${Strings.formatMoney(serviceQuoteTotals.totalCost)}`, className: "total-row-label" },
                          {},
                      ],
                      isCollapsed: true,
                  },
                  {
                    entryId: "totals-row-second",
                    columns: [
                        {},
                        {},
                        { content: "Approved Total"},
                        { content: Strings.formatFloat(approveTotalstotals.laborHours) },
                        { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.laborCost)}` },
                        { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.partsCost)}` },
                        { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.miscCost)}` },
                        { content: `$ ${Strings.formatMoney(approveTotalstotals.totalCost)}`, className: "total-row-label" },
                        {},
                    ],
                    isCollapsed: true,
                },
              ]
            : []),
    ]

    return <CollapsibleTable className="work-order-requests-table" head={head} data={data} />
}


function ServiceRequestDetailsModal({
    workRequestId,
    viewModel,
    formType,
}: {
    workRequestId: number | null
    viewModel: ServiceQuoteScreenViewModel
    formType: string
}): JSX.Element {
    const memoWorkRequestId = useLastNonNull(workRequestId)
    const refModal = useRef(null)
    const isDeclineAll = formType === ServiceQuoteItemFormType.DeclineAll

    if (memoWorkRequestId === null) return <Modal isVisible={false} />

    const workRequest = viewModel.state.serviceRequests.find((it) => it.id === memoWorkRequestId)
    const form = viewModel.state.serviceRequestForms[memoWorkRequestId]
    if (form === undefined || workRequest === undefined) return <Modal isVisible={false} />

    
    const declineAllTitle = "Decline All Service Quote Items"
    

    let workRequestTypeString = "service"
    return (
        <Modal
            isVisible={workRequestId !== null}
            useContentTag={true}
            onClose={() => viewModel.workRequestDetailsCancelClicked(memoWorkRequestId)}
            size={ModalSize.Large}
            modalRef={refModal}
        >
            <ModalContent>
                <ModalHeader>
                    <h5>{isDeclineAll ? "" : Strings.forceFirstLetterUpperCase(workRequestTypeString)}</h5>
                    <ModalHeaderTitle>{isDeclineAll ? declineAllTitle :labelForServiceRequestHeader(workRequest)}</ModalHeaderTitle>
                    <ModalHeaderSubTitle>{isDeclineAll ? "" : labelForAsset(viewModel.state.asset)}</ModalHeaderSubTitle>
                </ModalHeader>
                <ModalBody>
                    <ServiceRequestDetails
                        workRequest={workRequest}
                        form={form}
                        viewModel={viewModel}
                        refModal={refModal}
                        formType={formType}
                    />
                </ModalBody>
                <ModalButtons>
                    <Button
                        label="Cancel"
                        type={ButtonType.Text}
                        onClick={() => viewModel.workRequestDetailsCancelClicked(memoWorkRequestId)}
                    />
                    <Button
                        label="Save"
                        //This save needs to check the form status and rejection reason, because the form status is still its previous status when opened
                        isEnabled={viewModel.isRequestFormValid(memoWorkRequestId)}
                        onClick={() => {
                            if (isDeclineAll) {
                                viewModel.declineAllServiceRequests(form.rejectionReason ?? "");
                            } else {
                                viewModel.workRequestDetailsSaveClicked(memoWorkRequestId);
                            }
                        }}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}

function ServiceRequestDetails({
    workRequest,
    form,
    viewModel,
    refModal,
    formType,
}: {
    workRequest: ServiceQuoteItem
    form: ServiceQuoteWorkRequestForm
    viewModel: ServiceQuoteScreenViewModel
    refModal?: React.RefObject<any>
    formType: string
}): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const isDeclineAll = formType === ServiceQuoteItemFormType.DeclineAll
    const isDeclined = formType === ServiceQuoteItemFormType.Declined
    const isParentQuoteDeclined = viewModel.state.serviceQuote?.Status === ServiceQuoteStatus.Declined
    if(isDeclined){
        form.status = ServiceQuoteStatus.Declined
    }
    return (
        <GridCont gap={GridGap.Medium}>
            {isMobile ? (
                <Grid columns={2} className="status-grid">
                    <FormField label="Status">
                        <ServiceQuoteStatusSelect
                            status={form.status}
                            onChange={(status) => viewModel.serviceQuoteStatusChanged(workRequest.id, status)}
                            disabled={!viewModel.hasServiceQuoteEditPermission()}
                            filterPredicate={x=> x !== ServiceQuoteStatus.RevisionRequested}
                        />
                    </FormField>
                    <FormField className="modal-save-buttons-top">
                        <Button
                            label="Cancel"
                            type={ButtonType.Text}
                            onClick={() => viewModel.workRequestDetailsCancelClicked(workRequest.id)}
                        />
                        <Button
                            label="Save"
                            isEnabled={isDeclineAll ? form.rejectionReason !== "" : viewModel.isRequestFormValid(workRequest.id)}
                            onClick={() => {
                                if (isDeclineAll) {
                                    viewModel.declineAllServiceRequests(form.rejectionReason ?? "");
                                } else {
                                    viewModel.workRequestDetailsSaveClicked(workRequest.id);
                                }
                            }}
                        />
                    </FormField>
                </Grid>
            ) : (
                !isDeclineAll && (
                    <Grid columns={3}>
                        <FormField label="Status">
                            <ServiceQuoteStatusSelect
                                status={form.status}
                                onChange={(status) => viewModel.serviceQuoteStatusChanged(workRequest.id, status)}
                                disabled={!viewModel.hasServiceQuoteEditPermission() || isParentQuoteDeclined}
                                filterPredicate={x=> x !== ServiceQuoteStatus.RevisionRequested}
                            />
                        </FormField>
                        <FormField></FormField>
                        <FormField className="modal-save-buttons-top">
                            <Button
                                label="Cancel"
                                type={ButtonType.Text}
                                onClick={() => viewModel.workRequestDetailsCancelClicked(workRequest.id)}
                            />
                            <Button
                                label="Save"
                                isEnabled={viewModel.isRequestFormValid(workRequest.id) && !isParentQuoteDeclined}
                                onClick={() => { viewModel.workRequestDetailsSaveClicked(workRequest.id)}}
                            />
                        </FormField>
                    </Grid>
                )
            )}
            {(form.status == ServiceQuoteStatus.Declined  || isDeclineAll) && (
                <Grid columns={1}>
                    {(isDeclineAll &&
                        <text>This reason will be applied as the rejection reason for all service quote items associated with this service Quote</text>
                    )}
                    <FormField
                        label="Reason*"
                        hasRedStroke={true}
                    >
                        <TextArea
                            disabled={
                                !viewModel.hasServiceQuoteEditPermission() || isParentQuoteDeclined
                            }
                            maxLength={8000}
                            value={form.rejectionReason ?? ""}
                            onChange={(reason) => viewModel.rejectionReasonChanged(workRequest.id, reason)}
                        />
                    </FormField>
                </Grid>
            )}
            {!isDeclineAll && (
    <>
        <Grid columns={4}>
            <FormField label="Service Code">
                <Select
                    options={[
                        ...[{ id: -1, value: "-1", label: "Select Service Code" }].map((s) => ({
                            key: s.id,
                            value: `${s.value}`,
                            label: s.label,
                        })),
                        ...viewModel.state.serviceCodes.map((it) => ({
                            key: it.id,
                            value: `${it.id}`,
                            label: labelForServiceCode(it),
                        })),
                    ]}
                    isEnabled={viewModel.hasServiceQuoteEditPermission() && !isParentQuoteDeclined}
                    value={form.serviceCodeId ?? ""}
                    onChange={(value) => viewModel.requestServiceCodeChanged(workRequest.id, value)}
                />
            </FormField>
        </Grid>
        <Grid columns={5}>
            <FormField label="Labor Hours">
                <TextInput
                    type={TextInputType.Float}
                    value={form.LaborHours}
                    min={0}
                    isEnabled={false}
                    onFocus={(event) => event.target.select()}
                />
            </FormField>
            <FormField label="Labour Rate">
                <TextInput
                    type={TextInputType.Money}
                    value={form.LaborCost}
                    min={0}
                    isEnabled={false}
                    onFocus={(event) => event.target.select()}
                />
            </FormField>
            <FormField label="Parts Cost">
                <TextInput
                    type={TextInputType.Money}
                    value={form.PartsCost}
                    min={0}
                    isEnabled={false}
                    onFocus={(event) => event.target.select()}
                />
            </FormField>
            <FormField label="Misc. Cost">
                <TextInput
                    type={TextInputType.Money}
                    value={form.MiscCost}
                    min={0}
                    isEnabled={false}
                    onFocus={(event) => event.target.select()}
                />
            </FormField>
            <FormField label="Total Cost">
                <TextInput
                    type={TextInputType.Money}
                    value={Strings.formatMoney(form.TotalCost)}
                    isEnabled={false}
                />
            </FormField>
        </Grid>            
        <Grid columns={1}>
            <FormField label="Description / Work to be Performed">
                <TextArea
                    maxLength={250}
                    value={form.workToBePerformed}
                    disabled={true}
                />
            </FormField>
        </Grid>
        <Grid columns={1}>
            <FormField label="Mechanic's Notes">
                <TextArea
                    maxLength={1000}
                    value={form.notes}
                    onChange={(value) => viewModel.requestNotesChanged(workRequest.id, value)}
                    disabled={!viewModel.hasServiceQuoteEditPermission() || isParentQuoteDeclined}
                />
            </FormField>
        </Grid>
        <Grid columns={1}>
            <AttachmentList
                attachments={form.attachments ?? []}
                disabled={true}
                onCloseRef={refModal}
            />
        </Grid>
    </>
)}
            
        </GridCont>
    )
}